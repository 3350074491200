<template>
    <SmartModal :target="props.multiselectRef" @close="closeDropdown" @resize="isBottomVisible">
        <div
            class="multiselect__dropdown flex flex-col overflow-hidden"
            :data-id="props.timeComponentCreated"
            :style="{
                minWidth: props.dropdownWidthEqual ? props.multiselectFieldWidth + 'px' : propsSizeToCssSize(props.dropdownMinWidth),
                maxWidth: props.dropdownWidthEqual ? props.multiselectFieldWidth + 'px' : propsSizeToCssSize(props.dropdownMaxWidth),
                maxHeight: propsSizeToCssSize(props.dropdownMaxHeight),
                borderRadius: getBorderRadius + 'px'
            }"
            ref="dropdown">
            <BaseInputStyled
                :showLoadingSpinner="searchInProcess || inputDebounceInProcess"
                class="px-4 mt-1 mb-1"
                v-if="search || localOptionElementsSearch?.length > 5 || props.searchValueProp !== ''"
                :placeholder="$t('multiSelect.search')"
                :value="props.searchValueProp"
                @input="onSearch"
                :debounceTime="500"
                autofocus
                placeholderHideNotEmpty
                @debounceStarted="inputDebounceInProcess = true"
                @debounceFinished="inputDebounceInProcess = false"
                noBorder
                iconName="search-01"
                ref="searchInput"
                @mouseenter="toggleActiveIndex(-1)">
                <!-- :showResetButton="true" -->
            </BaseInputStyled>

            <div v-if="!props.groupBy && localOptionElementsSearch.length" class="multiselect__dropdown-list w-full scroll-wrap overscroll-contain py-1">
                <MultiselectDropdownElement
                    v-for="(element, index) in localOptionElementsSearch"
                    :key="props.object ? element[props.labelProp] + element[props.valueProp] : element"
                    :element="element"
                    :value="props.value"
                    :index="index"
                    :isActive="index === activeIndex"
                    :multiple="props.multiple"
                    :object="props.object"
                    :valueTypeObject="props.valueTypeObject"
                    :labelProp="props.labelProp"
                    :valueProp="props.valueProp">
                    <template v-if="$slots.prefix" #prefix="{ item }">
                        <slot name="prefix" :item="item"></slot>
                    </template>
                    <template v-if="$slots.content" #content="{ item }">
                        <slot name="content" :item="item"></slot>
                    </template>
                    <template v-if="$slots.suffix" #suffix="{ item }">
                        <slot name="suffix" :item="item"></slot>
                    </template>
                </MultiselectDropdownElement>
            </div>
            <div
                v-if="props.groupBy && localOptionElementsSearch.length"
                class="multiselect__dropdown-search-entities px-4 flex gap-1"
                style="box-shadow: 0px 10px 10px 0px #686c6f0f">
                <BaseButton
                    class="multiselect-group__append-icon"
                    :class="[{ 'multiselect-group__append-icon-collapsed': !isSomeGroupCollapsed }]"
                    variant="text"
                    color="third"
                    @click="collapseAllGroups()"
                    :rounded="false"
                    size="24">
                    <template #icon>
                        <BaseIcon name="arrow-up-01" class="h-[24px] w-[24px] pointer-events-none" />
                    </template>
                </BaseButton>
                <FilterGroups
                    :items="filterGroupsItems"
                    class="min-h-[30px]"
                    :modalWidth="realDropdownWidth - 28"
                    @change="
                        value => {
                            selectedFilterGroup = value;
                            toggleActiveIndex(-1);
                            scrollToTop();
                        }
                    " />
            </div>
            <div v-if="props.groupBy && localOptionElementsSearch.length" class="multiselect__dropdown-list w-full scroll-wrap py-1" ref="dropdownGroupedList">
                <div v-for="(value, groupIndex) in props.groups" :key="groupIndex">
                    <div
                        v-if="value.label !== undefined && (selectedFilterGroup === 'all' || (props.multiple && selectedFilterGroup === value.id))"
                        class="multiselect__dropdown-group flex gap-1 items-center h-[38px] px-4"
                        :class="props.multiple ? 'cursor-pointer hover:bg-[var(--sp-bg-text-field-blue-active)] ' : 'cursor-default	'"
                        @click="event => onToggleGroup(value.id, event)"
                        @mouseenter="toggleActiveIndex(-1)">
                        <BaseButton
                            data-action="collapse-group"
                            class="multiselect-group__append-icon"
                            :class="[{ 'multiselect-group__append-icon-collapsed': !collapsedGroups[value.id] }]"
                            variant="text"
                            color="third"
                            @click="collapseGroup(value.id)"
                            :rounded="false"
                            size="24">
                            <template #icon>
                                <BaseIcon name="arrow-up-01" class="h-[24px] w-[24px] pointer-events-none" />
                            </template>
                        </BaseButton>
                        <BaseCheckbox
                            v-if="props.multiple"
                            class="pointer-events-none mr-1"
                            :indeterminate="groupsCheckedPercent?.[value.id] < 1"
                            :value="groupsCheckedPercent?.[value.id] > 0" />
                        <span class="multiselect__dropdown-group-label flex grow font-medium text-[#131416] leading-4">{{ value.label }}</span>
                    </div>
                    <div v-if="!collapsedGroups[value.id]" class="contents">
                        <MultiselectDropdownElement
                            v-for="element in localOptionElementsSearch.filter(el => el[props.groupBy] === value.id)"
                            :style="{ paddingLeft: props.multiple ? '56px' : '' }"
                            :key="element.multiselectElementIndex"
                            :element="element"
                            :value="props.value"
                            :index="element.multiselectElementIndex"
                            :isActive="element.multiselectElementIndex === activeIndex"
                            :multiple="props.multiple"
                            :object="props.object"
                            :valueTypeObject="props.valueTypeObject"
                            :labelProp="props.labelProp"
                            :valueProp="props.valueProp"
                            :groupLabel="props.groupLabel">
                            <template v-if="$slots.prefix" #prefix="{ item }">
                                <slot name="prefix" :item="item"></slot>
                            </template>
                            <template v-if="$slots.content" #content="{ item }">
                                <slot name="content" :item="item"></slot>
                            </template>
                            <template v-if="$slots.suffix" #suffix="{ item }">
                                <slot name="suffix" :item="item"></slot>
                            </template>
                        </MultiselectDropdownElement>
                    </div>
                </div>
            </div>

            <span
                v-if="!localOptionElementsSearch.length && !props.searchInProcess && !(props.searchFunction && props.searchValueProp === '')"
                class="px-4 mb-auto text-[var(--sp-text-third)]"
                >{{ $t('multiSelect.noData') }}</span
            >
            <div v-if="props.addNew" class="border-t border-t-[#EAEBEE] mx-4 py-1">
                <BaseButton variant="text" @click="onAddNew" :label="$t('buttons.addNew')" :rounded="false" class="w-full" size="32">
                    <template #icon>
                        <BaseIcon name="Plas_24px" class="h-[24px] w-[24px]" />
                    </template>
                </BaseButton>
            </div>
        </div>
    </SmartModal>
</template>

<script setup>
import { onMounted, onUnmounted, ref, inject, provide, watch, computed, nextTick } from 'vue';
import { InjectionKeyToggleSelection, InjectionKeyToggleActiveIndex, InjectionKeyCloseDropdown, InjectionKeyToggleGroup, InjectionKeyAddNew } from '../keys.js';
import MultiselectDropdownElement from './MultiselectDropdownElement.vue';
import BaseInputStyled from '@/components/ui/BaseInputStyled.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import FilterGroups from '@/components/globalSearch/components/FilterGroups.vue';
import SmartModal from '@/components/ui/SmartModal.vue';
import { BaseCheckbox } from 'uikit';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { InjectionKeyHandleSearchChange } from '../keys.js';
import { calculateBorderRadius } from '@/components/ui/utils/styles.utils';
const handleSearchChange = inject(InjectionKeyHandleSearchChange);

const toggleSelection = inject(InjectionKeyToggleSelection);
const closeDropdown = inject(InjectionKeyCloseDropdown);
const toggleGroup = inject(InjectionKeyToggleGroup);
const onAddNew = inject(InjectionKeyAddNew);
const updateDropdownVisibility = inject('updateDropdownVisibility', () => {});

const props = defineProps({
    multiple: {
        type: Boolean,
        default: false
    },
    value: {
        type: [Object, Array, String, Number, null],
        default: () => []
    },
    optionElementsSearch: {
        type: [Object, Array, String, Number, null],
        default: () => []
    },
    search: {
        type: Boolean,
        default: false
    },
    searchValueProp: { type: String, default: '' },

    labelProp: {
        type: String,
        default: 'label'
    },
    valueProp: {
        type: String,
        default: 'id'
    },
    object: {
        type: Boolean,
        default: false
    },
    valueTypeObject: {
        type: Boolean,
        default: false
    },
    timeComponentCreated: {
        type: Number,
        default: null
    },
    dropdownDimensions: {
        type: Object
    },
    searchInProcess: {
        type: Boolean,
        default: false
    },
    dropdownPositionBottom: {
        type: Boolean,
        default: true
    },
    multiselectFieldHeight: Number,
    searchFunction: {
        type: Function
    },
    id: String,
    groups: {
        type: [Object, Array, null],
        default: null
    },
    groupBy: {
        type: String,
        default: null
    },
    creatable: {
        type: Boolean,
        default: false
    },
    creatableType: {
        type: String
    },
    creatableValidationFunction: {
        type: Function
    },
    dropdownWidthEqual: {
        type: Boolean,
        default: false
    },
    dropdownMaxWidth: {
        type: [String, Number]
    },
    dropdownMinWidth: {
        type: [String, Number],
        default: 60
    },
    dropdownMaxHeight: {
        type: [String, Number],
        default: '75dvh'
    },
    multiselectFieldWidth: {
        type: [null, Number],
        default: null
    },
    addNew: {
        type: Boolean,
        default: false
    },
    groupLabel: {
        type: String,
        default: null
    },
    multiselectRef: {
        type: [Object, null],
        default: null
    }
});

const emit = defineEmits(['bottomVisible']);

const dropdown = ref(null);
const realDropdownWidth = ref(0);
const activeIndex = ref(-1);
const inputDebounceInProcess = ref(false);
const searchInput = ref('searchInput');

const generateIndexedOptionElementsSearch = (arrayOfObjects, objectOfGroups) => {
    const res = objectOfGroups
        .reduce((acc, { id }) => [...acc, id], [])
        .filter(el => (selectedFilterGroup.value === 'all' ? true : el === selectedFilterGroup.value))
        .reduce((acc, el) => [...acc, ...arrayOfObjects.filter(item => item[props.groupBy] === el)], [])
        .map((el, index) => ({ ...el, multiselectElementIndex: index }));
    return res;
};

const localOptionElementsSearch = computed(() => {
    if (!props.groupBy) {
        return props.optionElementsSearch;
    } else {
        return generateIndexedOptionElementsSearch(props.optionElementsSearch, props.groups);
    }
});

const selectedFilterGroup = ref('all');

const filterGroupsItems = computed(() =>
    props.groupBy && props.groups.length
        ? [
              {
                  id: 'all',
                  label: t('labels.allResults'),
                  active: selectedFilterGroup.value === 'all',
                  count: props.optionElementsSearch?.length
              },
              ...props.groups.map(el => ({
                  ...el,
                  active: selectedFilterGroup.value === el.id
              }))
          ]
        : []
);
const onSearch = value => {
    handleSearchChange(value);
    if (value !== '') {
        collapsedGroups.value = {};
    }
    toggleActiveIndex(-1);
};

watch(
    () => localOptionElementsSearch.value,
    async (newValue, oldValue) => {
        if (activeIndex.value > newValue.length - 1) {
            activeIndex.value = newValue.length - 1;
        }
        await nextTick();
        realDropdownWidth.value = dropdown?.value?.getBoundingClientRect().width - 34;
        if (newValue.length === 0 && props.searchValueProp === '' && (!oldValue || newValue.length !== oldValue?.length)) {
            emit('bottomVisible');
        }
        if (!oldValue?.length && newValue.length > 0) {
            scrollableArea.value = document.querySelector(`.multiselect__dropdown[data-id="${props.timeComponentCreated}"] .multiselect__dropdown-list`);
            scrollableArea.value.addEventListener('scroll', isBottomVisible);
            isBottomVisible();
        }
        if (oldValue?.length > 0 && newValue.length === 0 && scrollableArea.value) {
            scrollableArea.value.removeEventListener('scroll', isBottomVisible);
        }
    },
    { immediate: true }
);

watch(
    () => props.searchValueProp,
    () => {
        if (props.groupBy) {
            selectedFilterGroup.value = 'all';
        }
    }
);

const scrollToShow = currentIndex => {
    const activeElement = document.querySelectorAll('.multiselect__dropdown-element')[currentIndex];
    if (activeElement) {
        const parentElement = !props.groupBy ? activeElement.parentNode : activeElement.parentNode.parentNode;
        if (parentElement) {
            const activeElementRect = activeElement.getBoundingClientRect();
            const parentRect = parentElement.getBoundingClientRect();
            let scrollOffset = 0;
            if (activeElementRect.top < parentRect.top) {
                scrollOffset = activeElementRect.top - parentRect.top;
            } else if (activeElementRect.bottom > parentRect.bottom) {
                scrollOffset = activeElementRect.bottom - parentRect.bottom;
            }
            parentElement.scrollTop = parentElement.scrollTop + scrollOffset;
        }
    }
};
const dropdownGroupedList = ref(null);
const scrollToTop = () => {
    dropdownGroupedList.value.scrollTop = 0;
};

const handleKeyPress = event => {
    switch (event.key) {
        case 'ArrowDown':
            event.preventDefault();
            if (activeIndex.value <= localOptionElementsSearch.value.length - 1) {
                activeIndex.value++;
            }
            if (activeIndex.value === localOptionElementsSearch.value.length) {
                activeIndex.value = 0;
            }
            scrollToShow(activeIndex.value);

            break;
        case 'ArrowUp':
            event.preventDefault();
            if (activeIndex.value >= 0) {
                activeIndex.value--;
            }
            if (activeIndex.value === -1) {
                activeIndex.value = localOptionElementsSearch.value.length - 1;
            }
            scrollToShow(activeIndex.value);
            break;
        case 'Enter':
            event.preventDefault();
            event.stopPropagation();
            if (activeIndex.value >= 0 && activeIndex.value < localOptionElementsSearch.value.length) {
                toggleSelection(localOptionElementsSearch.value[activeIndex.value]);
            }
            if (
                activeIndex.value === -1 &&
                props.creatable &&
                // !localOptionElementsSearch.value.length &&
                props.searchValueProp.length &&
                !inputDebounceInProcess.value &&
                (!props.creatableValidationFunction || props.creatableValidationFunction(props.searchValueProp, props.creatableType))
            ) {
                searchInput.value.setInputValue('');
                toggleSelection(
                    props.valueTypeObject
                        ? {
                              [props.labelProp]: props.searchValueProp,
                              [props.valueProp]: props.searchValueProp
                          }
                        : props.searchValueProp
                );
            }
            break;
        case 'Escape':
            event.stopPropagation();
            closeDropdown();
            break;
    }
};
function propsSizeToCssSize(value) {
    return typeof value === 'number' ? `${value}px` : value;
}

function isBottomVisible() {
    if (!localOptionElementsSearch.value?.length) return;
    if (scrollableArea.value) {
        const { scrollTop, clientHeight, scrollHeight } = scrollableArea.value;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            emit('bottomVisible');
        }
    }
}

const scrollableArea = ref(null);

function onToggleGroup(id, event) {
    if (event.target?.dataset?.action !== 'collapse-group' && props.multiple) {
        toggleGroup(id);
    }
}

const collapsedGroups = ref(props.groups?.length > 1 ? props.groups.reduce((acc, el) => ({ ...acc, [el.id]: true }), {}) : {});
function collapseGroup(groupId) {
    collapsedGroups.value[groupId] = !collapsedGroups.value[groupId];
}
const isSomeGroupCollapsed = computed(() =>
    selectedFilterGroup.value === 'all' ? Object.values(collapsedGroups.value)?.some(el => el === true) : collapsedGroups.value?.[selectedFilterGroup.value] === true
);
function collapseAllGroups() {
    isSomeGroupCollapsed.value ? (collapsedGroups.value = {}) : (collapsedGroups.value = props.groups.reduce((acc, el) => ({ ...acc, [el.id]: true }), {}));
}

const groupsCheckedPercent = computed(() => {
    if (!(props.multiple && Array.isArray(props.value) && props.object && props.valueTypeObject)) {
        return null;
    }
    let groupsList = {};
    if (props.groupBy && localOptionElementsSearch.value.length && props.groups) {
        groupsList = props.groups.reduce((acc, el) => {
            const groupElements = localOptionElementsSearch.value.filter(item => item[props.groupBy] === el.id);
            acc[el.id] = groupElements.filter(item => props.value.some(val => val[props.valueProp] === item[props.valueProp])).length / groupElements.length;
            return acc;
        }, {});
    }
    return groupsList;
});

const getBorderRadius = computed(() => {
    return calculateBorderRadius('md');
});

onMounted(() => {
    document.addEventListener('keydown', handleKeyPress, true);
    updateDropdownVisibility(true);
});
onUnmounted(() => {
    updateDropdownVisibility(false);
    document.removeEventListener('keydown', handleKeyPress, true);
});

const toggleActiveIndex = value => {
    activeIndex.value = value;
};
provide(InjectionKeyToggleActiveIndex, toggleActiveIndex);
</script>
<style scoped lang="scss">
.multiselect-group__append-icon {
    transition: transform 0.1s;
    transform: rotate(180deg);
}
.multiselect-group__append-icon-collapsed {
    transform: rotate(0deg);
}
</style>
