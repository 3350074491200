<template>
    <div class="context-menu__element px-4 flex items-center">
        <h6 class="context-menu__element-group-caption">
            {{ props.data.label }}
        </h6>
    </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    }
});
</script>
<style scoped>
h6 {
    /* margin: 17px 0 11px 20px; */
    color: #979bb2;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;
    cursor: default;
}
.context-menu__element {
    cursor: default !important;
}
</style>
