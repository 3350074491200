import { useUserStore } from '@/stores/userStore';
import axios from 'axios';
import { app } from '@/main.ts';
import { methods } from '@/components/platformAlert/notifications.js';
import { EngineModalComponent } from '@/globalComponents/engineModalComponent';
import { defineAsyncComponent, markRaw } from 'vue';

export const axiosInstanсe = axios.create();
const baseURL = process.env.VUE_APP_API_URL;
let prevReq;
let cancelToken;
async function refreshAccessToken() {
    let res = null;
    const userStore = useUserStore();
    const fingerprint = await userStore.getFingerprint();
    await axios
        .put(`${baseURL}/auth/refresh`, {}, { withCredentials: true, headers: { 'x-fingerprint': fingerprint } })
        .then(refreshResponse => {
            userStore.setToken(refreshResponse.data.accessToken);
            userStore.setWSToken(refreshResponse.data.socketToken);
            res = refreshResponse.data.accessToken;
        })
        .catch(function (error) {
            failedQueue = [];
            const userStore = useUserStore();
            userStore.logout();
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });
    return res;
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (token = null) => {
    if (!token) return;
    failedQueue.forEach(({ config, resolve, reject }) => {
        config.headers['x-access-token'] = token;
        axiosInstanсe(config)
            .then(response => resolve(response))
            .catch(err => reject(err));
    });
    failedQueue = [];
};

axiosInstanсe.interceptors.request.use(async function (config) {
    const userStore = useUserStore();
    if (userStore.token && userStore.token.length > 0) {
        config.headers.common['x-access-token'] = userStore.token;
    }
    if (userStore.socketId && userStore.socketId.length > 0) {
        config.headers.common['x-socket-id'] = userStore.socketId;
    }
    const fingerprint = await userStore.getFingerprint();
    if (fingerprint) {
        config.headers.common['x-fingerprint'] = fingerprint;
    }
    return config;
});
axiosInstanсe.interceptors.response.use(
    response => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ config: originalRequest, resolve, reject });
                });
            }
            isRefreshing = true;
            return new Promise(resolve => {
                refreshAccessToken().then(token => {
                    originalRequest.headers['x-access-token'] = token;
                    processQueue(token);
                    isRefreshing = false;
                    resolve(axiosInstanсe(originalRequest));
                });
            });
        }
        if (error.response?.status === 409 && !originalRequest._retry) {
            const modal = new EngineModalComponent();
            const responseText = await new Promise((resolve) => {
                modal.openComponent({
                    component: markRaw(defineAsyncComponent(() => import('@/helpers/AdditionalQuestionUser.vue'))),
                    props: {
                        title: error.response?.data?.message,
                    },
                    events: {
                        close: () => {
                            resolve('');
                        },
                        save: (message) => {
                            modal.closeOpenComponent();
                            resolve(message);
                        }
                    }
                })
            });
            if (responseText) {
                originalRequest._retry = true;
                originalRequest.headers['x-user-input'] = responseText;
                return axiosInstanсe(originalRequest)
            } else {
                return
            }
        }
        return Promise.reject(error);
    }
);
export async function sendRequest(url, metods, req, headers = null, showLoader = false, cancelPrevReq = false, onError = () => {}) {
    if (showLoader) {
        app.config.globalProperties.$loaderSpiner.show = true;
    }
    if (cancelPrevReq) {
        if (prevReq === cancelPrevReq) {
            cancelToken.cancel('Operation canceled due to new request.');
        }
        prevReq = cancelPrevReq;
    }
    cancelToken = axios.CancelToken.source();
    axiosInstanсe.defaults.baseURL = baseURL;
    axiosInstanсe.defaults.headers.common['Cache-Control'] = 'no-cache';
    axiosInstanсe.defaults.withCredentials = true;
    if (!headers) {
        axiosInstanсe.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
    } else {
        axiosInstanсe.defaults.headers.put['Content-Type'] = headers;
    }
    if (metods === 'delete') {
        return await axiosInstanсe[metods](
            url,
            { data: req },
            {
                interceptNetworkError: true,
                cancelToken: cancelToken.token
            }
        ).catch(error => {
            prevReq = null;
            if (app.config.globalProperties.$loaderSpiner.show) app.config.globalProperties.$loaderSpiner.show = false;
            console.error(error);
            onError(error);
            if (error.response?.status === 403) {
                methods.notify({
                    title: 'Error',
                    text: 'You do not have permission to perform this action',
                    type: 'error'
                });
            }
            return null;
        });
    } else {
        return await axiosInstanсe[metods](url, req, {
            interceptNetworkError: true,
            cancelToken: cancelToken.token
        })
            .then(resp => {
                prevReq = null;
                if (showLoader) {
                    app.config.globalProperties.$loaderSpiner.show = false;
                }
                return resp?.data || resp;
            })
            .catch(error => {
                prevReq = null;
                if (app.config.globalProperties.$loaderSpiner.show) app.config.globalProperties.$loaderSpiner.show = false;
                console.error(error);
                onError(error);
                if (error.response?.status === 403) {
                    methods.notify({
                        title: 'Error',
                        text: 'You do not have permission to perform this action',
                        type: 'error'
                    });
                }
                return null;
            });
    }
}
