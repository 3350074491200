const validTypes = ['search', 'divider', 'caption', 'toggle', 'checkSingle', 'check', 'button', 'url', 'accordion'];

export const validateItems = value => {
    let incommingItems = Array.isArray(value) ? [...value] : value;
    if (!Array.isArray(incommingItems)) {
        console.warn('ContextMenu WARNING. items in not array');
        incommingItems = [];
    } else if (incommingItems.length === 0) {
        // console.warn("ContextMenu WARNING. empty items array");
    } else {
        incommingItems = incommingItems.filter(item => {
            if (!item.type) {
                console.warn(`ContextMenu WARNING. item --- ${JSON.stringify(item)} --- is incorrect and removed from items`);
                return false;
            } else if (!validTypes.includes(item.type)) {
                console.warn(`ContextMenu WARNING. type --- ${item.type} --- is not acceprable`);
                return false;
            }
            switch (item.type) {
                case 'search':
                    if (!item.id) {
                        console.warn(`ContextMenu WARNING.${item.type} item --- ${JSON.stringify(item)} --- miss some mandatory fields`);
                        return false;
                    }
                    break;
                case 'caption':
                    if (!item.label) {
                        console.warn(`ContextMenu WARNING. ${item.type} item --- ${JSON.stringify(item)} --- miss some mandatory fields`);
                        return false;
                    }
                    break;
                case 'toggle':
                case 'check':
                case 'button':
                    if (!item.id) {
                        console.warn(`ContextMenu WARNING.${item.type} item --- ${JSON.stringify(item)} --- miss some mandatory fields`);
                        return false;
                    }
                    break;
                case 'url':
                    if (!item.id) {
                        console.warn(`ContextMenu WARNING.${item.type} item --- ${JSON.stringify(item)} --- miss some mandatory fields`);
                        return false;
                    }
                    break;
                case 'checkSingle':
                    if (!item.id || !Array.isArray(item.items)) {
                        console.warn(`ContextMenu WARNING. ${item.type} item --- ${JSON.stringify(item)} --- miss some mandatory fields`);
                        return false;
                    }
                    break;
            }
            return true;
        });
    }
    return incommingItems;
};
