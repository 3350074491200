export default {
    data() {
        return {
            currentActiveRow: null
        };
    },
    methods: {
        setRowActive(id) {
            if (!id) return;
            this.currentActiveRow = id;
            const el = document.querySelector(`[data-rowid="${id}"]`);
            if (!el) return;
            el.style.zIndex = '1';

        },
        clearActiveRowState() {
            if (!this.currentActiveRow) return;
            const el = document.querySelector(`[data-rowid="${this.currentActiveRow}"]`);
            if (!el) return;
            el.style.zIndex = 'initial';
            this.currentActiveRow = null;
        }
    }
};
