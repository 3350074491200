// import { useUserStore } from "@/stores/userStore";

import { sendRequest } from '@/helpers';

export default {
    async getNotificationsSettings() {
        return await sendRequest(`/api/notifications/settings/`, 'get', '');
    },
    async createNotificationsSettings(data) {
        return await sendRequest(`/api/notifications/settings/`, 'post', data);
    },
    async updateNotificationsSettings(id, data) {
        return await sendRequest(`/api/notifications/settings/${id}`, 'put', data);
    },

    async getUserNotifications() {
        return await sendRequest(`/api/notifications/notices/`, 'get', '', null, true);
    },
    async getNewUserNotifications() {
        return await sendRequest(`/api/notifications/notices/?read=false`, 'get', '');
    },
    async markNotificationsAsRead(action, data) {
        return await sendRequest(`/api/notifications/instance_notices/${action}`, 'put', data);
    },
    async off_notifications(params) {
        return await sendRequest(`/api/off_notifications/`, 'post', params);
    },
    async on_notifications(id) {
        return await sendRequest(`/api/off_notifications/${id}`, 'delete');
    },
    async getEntityNotifications(entity_id) {
        return await sendRequest(`/api/entity_notifications_grouped/${entity_id}`, 'get', '');
    },
    async performAction(action, data) {
        return await sendRequest(`/api/notifications/notices/${action}`, 'put', data);
    }
};
