// import { useUserStore } from "@/stores/userStore";

import { sendRequest } from '@/helpers';

// import tracking from "./devdata/tasks/tracking.json";

export default {
    async getInstanceTrackingData(entityId, instanceId) {
        return await sendRequest(`/api/time_trackings/${entityId}/${instanceId}`, 'get', '');
    },
    async getCurrentTrackingData() {
        return await sendRequest(`/api/user_time_tracking/`, 'get', '');
        // return tracking;
    },
    async createTrackTime(data) {
        return await sendRequest(`/api/time_tracking/`, 'post', data);
    },
    async updateTrackTime(trackingId, data) {
        return await sendRequest(`/api/time_tracking/${trackingId}`, 'put', data);
    },
    async deleteTrackTime(trackingId) {
        return await sendRequest(`/api/time_tracking/${trackingId}`, 'delete', '');
    },
    async startTrackTime(entityId, instanceId) {
        return await sendRequest(`/api/start_time_tracking/${entityId}/${instanceId}`, 'post', '');
    },
    async pauseTrackTime(entityId, instanceId) {
        return await sendRequest(`/api/pause_time_tracking/${entityId}/${instanceId}`, 'post', '');
    },
    async stopTrackTime(entityId, instanceId) {
        return await sendRequest(`/api/stop_time_tracking/${entityId}/${instanceId}`, 'post', '');
    }
};
