import { defineStore } from 'pinia';
import { hasProperty } from '@/helpers';
import { i18n } from '@/main';

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
    state: () => ({
        breadcrumbs: {},
        entityDictionary: null
    }),

    getters: {},
    actions: {
        generateBreadcrumbForCard(cardName, instanceId, instanceObg) {
            let breadcrumbs = [];
            if (cardName) {
                breadcrumbs = this.entitySystemBreadcrumb(cardName);
            }
            const instanceBreadcrumb = {
                parrent: '',
                path: '',
                name: instanceObg.title,
                disabled: true
            };
            breadcrumbs.push(instanceBreadcrumb);
            this.breadcrumbs[instanceId] = breadcrumbs;
            return breadcrumbs;
        },
        entitySystemBreadcrumb(cardName) {
            if (!this.entityDictionary) {
                this.initDictionary();
            }
            let breadcrumbs = [];
            const addBreadcrumb = (target, name) => {
                if (!target) return;
                let res = target[name];
                if (res) {
                    breadcrumbs.push(res);
                    if (res?.parrent) {
                        addBreadcrumb(target, res?.parrent);
                    }
                }
            };
            addBreadcrumb(this.entityDictionary, cardName);
            return breadcrumbs.reverse();
        },
        setBreadcrumb(instanceId, data) {
            if (this.findBreadcrumb(instanceId)) {
                this.updateBreadcrumb(instanceId, data);
            } else {
                this.insertBreadcrumb(instanceId, data);
            }
        },
        findBreadcrumb(instanceId) {
            if (hasProperty(this.breadcrumbs, instanceId)) {
                return this.breadcrumb[instanceId];
            } else return null;
        },
        insertBreadcrumb(instanceId, data) {
            this.breadcrumbs[instanceId] = data;
        },
        updateBreadcrumb(instanceId, data) {
            this.breadcrumbs[instanceId] = data;
        },
        deleteBreadcrumb(instanceId) {
            if (hasProperty(this.breadcrumbs, instanceId)) {
                delete this.breadcrumbs[instanceId];
                return true;
            } else return null;
        },
        initDictionary() {
            const t = i18n.global.t;
            this.entityDictionary = {
                crm: {
                    parrent: null,
                    path: '/crm',
                    name: t('pages.crm'),
                    disabled: true
                },
                LeadCard: {
                    parrent: 'crm',
                    path: '/crm/leads?filter=all_my_active',
                    name: t('pages.leads'),
                    disabled: false
                },
                DealCard: {
                    parrent: 'crm',
                    path: '/crm/opportunities?filter=all_my_active',
                    name: t('pages.deals'),
                    disabled: false
                },
                ContactCard: {
                    parrent: 'crm',
                    path: '/crm/contacts?filter=all_my_active',
                    name: t('pages.contacts'),
                    disabled: false
                },
                CompanyCard: {
                    parrent: 'crm',
                    path: '/crm/accounts?filter=all_my_active',
                    name: t('pages.companies'),
                    disabled: false
                },
                TaskCard: {
                    parrent: null,
                    path: '/crm/tasks?filter=all_active',
                    name: t('pages.tasks'),
                    disabled: false
                }
            };
        }
    }
});
