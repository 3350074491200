<template>
    <div 
        ref="elements"
        class="context-menu__element flex justify-between gap-1 items-center" 
        @click="handleButtonClick(props.data.id, $event, props.data)" 
    >
        <div
            v-if="props.data.icon && typeof props.data.icon === 'string'"
            class="context-menu__element__icon flex items-center justify-center rounded-full"
            :style="{
                height: `${props.iconWrapperHeight}px`,
                width: `${props.iconWrapperHeight}px`,
                background: props.data.iconBgColor ? props.data.iconBgColor : ''
            }"
        >
            <BaseIcon
                v-if="props.data.icon"
                :style="{
                    height: `${props.iconHeight}px`,
                    fill: props.data.iconColor ? props.data.iconColor : ''
                }"
                :name="props.data.icon"
                class="context-menu__element-icon"
                :class="props.data?.additionalClass"
            ></BaseIcon>
        </div>

        <span>{{ props.data.label }}</span>
        <BaseIcon
            :class="['append-icon h-[24px]', { 'append-icon-active': unfolded }]" 
            name="arrow_down" 
        />
    </div>

    <template v-if="props.data.items">
        <transition-group name="fade">
            <div v-show="unfolded" key="content" >
                <template v-for="item in props.data.items" :key="item.id">
                    <div class="relative accordeon-item">
                        <OptionButton 
                            class="!pl-10" 
                            :class="{'!pr-8': item.isSelected}" 
                            :data="item" 
                        ></OptionButton>
                        <div v-if="item.isSelected" class="absolute right-0 top-1/2 -translate-y-1/2 mr-4">
                            <BaseIcon name="tick-02" class="h-[24px] w-[24px] fill-[#519DF5]"></BaseIcon>
                        </div>
                    </div>
                </template>
            </div>
        </transition-group>
    </template>
</template>

<script setup>
import { ref, inject, onMounted, nextTick } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import OptionButton from '@/components/ui/ContextMenu/components/OptionButton.vue';
import { InjectionKeyButtonClick } from '../keys.js';

const props = defineProps({
    data: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    scroll: {
      type: Boolean,
      default: true
    },
    timeParentCreatedForSubMenu: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    isTop: Boolean
});

const buttonClick = inject(InjectionKeyButtonClick);
const unfolded = ref(false)
const elements = ref(null)

const handleButtonClick = (id, event, object) => {
    unfolded.value = !unfolded.value;

    buttonClick(id, event, object, true, props.data.isMenuClosingForbiddenAfterClick);

    nextTick(() => {
        elements.value?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        });
    });
}

onMounted(() => {
    if (props.scroll) {
        nextTick(() => {
            elements.value?.scrollIntoView({
                behavior: 'smooth',
            });
        });
    }
});
</script>

<style lang="scss" scoped>
.append-icon {
  transition: transform 0.2s;
}
.append-icon-active {
  transform: rotate(-180deg);
}

.accordeon-item {
    :deep() {
        .context-menu__element div span {
            display: inline-block;
            width: 65px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
