import { sendRequest } from '@/helpers';
export default {
    async getBadges() {
        return await sendRequest(`/api/notifications/badges/`, 'get', '');
    },
    async deleteBadge(entityId, instanceId) {
        return await sendRequest(`/api/notifications/badges/${entityId}/${instanceId}`, 'delete', '');
    },
    async clearBadgeForEntity(entityId, badgeType) {
        return await sendRequest(`/api/notifications/badges/${entityId}?type=${badgeType}`, 'delete', '');
    }
};
