export const InjectionKeyToggleSelection = Symbol();
export const InjectionKeyClearSelection = Symbol();
export const InjectionKeyToggleDropdown = Symbol();
export const InjectionKeyToggleActiveIndex = Symbol();
export const InjectionKeyToggleGroup = Symbol();
export const InjectionKeyHandleSearchChange = Symbol();
export const InjectionKeyCloseDropdown = Symbol();
export const InjectionKeySetVisibleChips = Symbol();
export const InjectionKeyClickOnTag = Symbol();
export const InjectionKeyAddNew = Symbol();
