<template>
    <div v-if="modelValue">
        <teleport to="#dialog-container">
            <div class="modal-mask" @click.stop="closeModal">
                <div class="modal-container" :class="dialogClass">
                    <slot></slot>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, defineEmits, defineProps, PropType } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    inputEvent: {
        type: Object as PropType<Event>
    },
    contentType: String,
    contentWidth: String,
    contentHeight: String,
    fullHeight: Boolean,
    top: String,
    left: {
        type: Number,
        default: 0
    },
    offsetTop: {
        type: String,
        default: '0'
    },
    offsetLeft: {
        type: String,
        default: '0'
    },
    position: {
        type: String,
        default: 'left'
    },
    zindex: {
        type: String,
        default: '1010'
    },
    showOwerlay: {
        type: Boolean,
        default: false
    },
    name: String,
    left2: Number,
    showCloseBtn: {
        type: Boolean,
        default: false
    },
    type: String
});

const emit = defineEmits(['update:modelValue', 'modalClose', 'changeView']);

const documentWidth = ref(window.innerWidth);

const dialogClass = computed(() => {
    return props.type || '';
});

const closeModal = () => {
    emit('modalClose');
    emit('update:modelValue', false);
};

const watchForWindowsResize = () => {
    documentWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener('resize', watchForWindowsResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', watchForWindowsResize);
});
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    width: 400px;
    margin: auto;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.modal-container {
    background: #f3f2f2;
    position: relative;
    padding: 16px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
}

.error {
    background-color: rgb(176, 0, 32);
    color: #fff;
}
.success {
    background-color: rgb(76, 175, 80);
    color: #fff;
}
.info {
    background-color: rgb(33, 150, 243);
    color: #fff;
}
</style>
