<template>
    <div class="form-container text-black text-sm leading-6 w-[354px]">
        <div class="tracking-form__header flex items-center justify-between px-4 py-2 border-b border-gray3">
            <p class="header__title uppercase text-base font-medium leading-5">
                {{ $t('buttons.addRecord') }}
            </p>
            <BaseButton variant="text" color="third" size="xs" @click.stop="cancel" v-tooltip:top="$t('buttons.close')">
                <template v-slot:icon> <BaseIcon class="w-6 h-6" name="Clouse" /></template>
            </BaseButton>
        </div>
        <div class="tracking-form__main p-4">
            <div class="flex">
                <BaseDatePicker class="shrink-0" style="width: 150px" v-model="date" :showResetButton="false" @update="focusOnDurationInput"></BaseDatePicker>
                <DurationInput class="ml-4" :value="duration" :valid="isDurationValid" @setDuration="setDuration" ref="durationInput"></DurationInput>
            </div>
            <div class="description__wrapper mt-2.5">
                <p class="main__description text-base font-medium">
                    {{ $t('labels.description') }}
                </p>
                <textarea
                    :placeholder="$t('inputs.addComment')"
                    v-model="comment"
                    class="comment-input border border-gray3 rounded-xl p-2 w-full mt-2"
                    name="comment"
                    id=""
                    cols="30"
                    rows="4"
                    autofocus
                    ref="textArea"
                    @click="
                        () => {
                            $analytics.addEvent({
                                category: 'Task card tracking form',
                                action: 'lmbc',
                                label: 'Click to type description in record modal form',
                                entity: 'task',
                                instance: ''
                            });
                        }
                    "></textarea>
            </div>
        </div>
        <div class="tracking-form__footer p-4 flex justify-end gap-4 border-t border-gray3">
            <BaseButton class="px-4" color="secondary" @click="cancel" :label="$t('buttons.cancel')"> </BaseButton>
            <BaseButton class="px-4" @click="submit" :disabled="!isDurationValid" :label="$t('buttons.save')"> </BaseButton>
        </div>
    </div>
</template>

<script>
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import DurationInput from '@/components/ui/DurationInput';
import dayjs from 'dayjs';

export default {
    name: 'TrackingForm',
    components: {
        BaseDatePicker,
        DurationInput,
        BaseButton,
        BaseIcon
    },
    props: {
        item: {
            type: Object
        }
    },
    emits: ['closeModal', 'submit'],
    data() {
        return {
            date: '',
            duration: '00:15',
            comment: '',
            isDurationValid: true
        };
    },
    computed: {},
    methods: {
        cancel() {
            this.$analytics.addEvent({
                category: 'Task card tracking form',
                action: 'lmbc',
                label: 'Click to cancel changes record data',
                entity: 'task',
                instance: ''
            });
            this.$emit('closeModal');
        },
        submit() {
            this.$analytics.addEvent({
                category: 'Task card tracking form',
                action: 'lmbc',
                label: 'Click to save record data',
                entity: 'task',
                instance: ''
            });
            const formatStrToSeconds = str => {
                let mas = str.split(':');
                return Number(mas[0]) * 60 * 60 + Number(mas[1]) * 60;
            };
            let res = {
                date: dayjs(this.date).unix() * 1000,
                duration: formatStrToSeconds(this.duration),
                comment: this.comment
            };
            this.$emit('submit', res);
        },
        focusOnDurationInput() {
            this.$analytics.addEvent({
                category: 'Task card tracking form',
                action: 'lmbc',
                label: 'Set date in record modal form',
                entity: 'task',
                instance: ''
            });

            this.$refs?.durationInput?.focusOnInput();
        },
        setDuration(value) {
            this.duration = value;

            this.$analytics.addEvent({
                category: 'Task card tracking form',
                action: 'lmbc',
                label: 'Set duration in record modal form',
                entity: 'task',
                instance: ''
            });

            this.isDurationValid = this.checkTheValueIsValid(this.duration);
        },
        checkTheValueIsValid(duration) {
            const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

            if (!timeRegex.test(duration)) {
                return false;
            }

            const [hour, minute] = duration.split(':');

            const isValidHour = parseInt(hour, 10) >= 0 && parseInt(hour, 10) <= 24;
            const isValidMinute = parseInt(minute, 10) >= 0 && parseInt(minute, 10) <= 59;
            let isValidFullDay = true;

            if (hour === '24' && minute !== '0') {
                isValidFullDay = false;
            }

            return isValidHour && isValidMinute && isValidFullDay;
        },
        getDurationTitle(duration) {
            if (!duration) return '';

            const fullHours = Math.floor(duration / 3600);
            const fullMinutes = Math.floor((duration - fullHours * 3600) / 60);

            return `${fullHours ? fullHours.toString().padStart(2, '0') : '00'}:${fullMinutes ? fullMinutes.toString().padStart(2, '0') : '00'}`;
        }
    },
    created() {
        if (this.item) {
            this.date = this.item.date;
            this.duration = this.getDurationTitle(this.item.duration);
            this.comment = this.item.comment;
        } else {
            this.date = dayjs().valueOf();
        }
    },
    mounted() {
        this.$refs?.textArea?.focus();
    }
};
</script>

<style lang="scss" scoped>
.comment-input {
    outline: none;
    resize: none;
}
</style>
