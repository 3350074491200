function sanitizeHtml(str, config) {
    // створюємо тимчасовий div для парсингу рядка
    const tempDiv = document.createElement('div');
    // встановлюємо рядок з тегами і атрибутами в div як innerHTML
    tempDiv.innerHTML = str;

    // отримуємо всі дочірні елементи div
    const childNodes = tempDiv.childNodes;

    // рекурсивна функція для перевірки та очистки кожного елемента
    function cleanElement(el) {
        // якщо елемент є текстовим вузлом, просто вертаємо його значення
        if (el.nodeType === Node.TEXT_NODE) {
            return el.nodeValue;
        }

        // якщо елемент є тегом, перевіряємо його та його атрибути на відповідність конфігурації
        if (el.nodeType === Node.ELEMENT_NODE) {
            // перевіряємо, чи дозволений тег елементу
            if (!config.allowedTags.includes(el.tagName.toLowerCase())) {
                // якщо тег не дозволений, повертаємо порожній рядок
                return '';
            }

            // очищаємо недозволені атрибути елементу
            const allowedAttrs = config.allowedAttributes[el.tagName.toLowerCase()] || [];
            for (let i = 0; i < el.attributes.length; i++) {
                const attr = el.attributes[i];
                if (!allowedAttrs.includes(attr.name.toLowerCase())) {
                    el.removeAttribute(attr.name);
                }
            }

            // рекурсивно очищаємо всі дочірні елементи
            const children = el.childNodes;
            for (let i = children.length - 1; i >= 0; i--) {
                const child = children[i];
                const cleanedChild = cleanElement(child);
                if (!cleanedChild) {
                    el.removeChild(child);
                }
            }

            // повертаємо очищений елемент
            return el.outerHTML;
        }

        // якщо елемент не є текстовим вузлом або тегом, повертаємо порожній рядок
        return '';
    }

    // очищаємо кожний дочірній елемент div
    let sanitizedStr = '';
    for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        const cleanedChild = cleanElement(child);
        if (cleanedChild) {
            sanitizedStr += cleanedChild;
        }
    }

    // повертаємо очищений рядок
    return sanitizedStr;
}

export function sanitizeText(str) {
    const config = {
        allowedTags: [
            'address',
            'article',
            'aside',
            'footer',
            'header',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'hgroup',
            'main',
            'nav',
            'section',
            'blockquote',
            'dd',
            'div',
            'dl',
            'dt',
            'figcaption',
            'figure',
            'hr',
            'li',
            'main',
            'ol',
            'p',
            'pre',
            'ul',
            'a',
            'abbr',
            'b',
            'bdi',
            'bdo',
            'br',
            'cite',
            'code',
            'data',
            'dfn',
            'em',
            'i',
            'kbd',
            'mark',
            'q',
            'rb',
            'rp',
            'rt',
            'rtc',
            'ruby',
            's',
            'samp',
            'small',
            'span',
            'strong',
            'sub',
            'sup',
            'time',
            'u',
            'var',
            'wbr',
            'caption',
            'col',
            'colgroup',
            'table',
            'tbody',
            'td',
            'tfoot',
            'th',
            'thead',
            'tr'
        ],
        allowedAttributes: {
            a: ['href', 'name', 'target', 'class', 'data-id', 'id'],
            span: ['class', 'style'],
            div: ['class', 'style'],
            blockquote: ['class']
        }
    };

    let res = sanitizeHtml(str, config);

    return res;
}

export function sanitizeDescription(str) {
    const config = {
        allowedTags: ['address', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li', 'p', 'a', 'b', 'cite', 'mark', 'small', 'span'],
        allowedAttributes: {
            a: ['href', 'name', 'target', 'class', 'data-id', 'id', 'rel'],
            span: ['class', 'style'],
            div: ['class', 'style'],
            blockquote: ['class']
        }
    };

    let res = sanitizeHtml(str, config);

    return res;
}

export function cleanText(input) {
    if (typeof input !== 'string') {
        return '';
    }
    const cleanText = input.replace(/<\/?[^>]+(>|$)/g, ''); // Видаляє HTML теги
    return cleanText.replace(/&\w+;/g, '').trim(); // Видаляє системні символи та пробіли
}
