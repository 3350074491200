<template>
    <a :href="itemHref(props.item)" @click.prevent="">
        <div
            class="fade-in-animation flex relative items-center group/menu-element cursor-pointer gap-1"
            :class="[
                { 'hover:z-[2]': props.item.actions?.length },
                { 'z-[2]': props.actionsMenuOpened },
                isMobile ? 'py-2 px-4 flex-auto justify-between hover:bg-[#519df524]' + (props.active ? ' bg-[#519df524]' : '') : 'px-2 h-full'
            ]"
            @click="onElementClick"
            data-action="element-click">
            <div class="flex">
                <BaseIcon v-if="isMobile" :name="props.item.icon ? props.item.icon : 'dotFill'" class="h-[24px] w-[24px] mr-2 fill-[#C0C2CD]"></BaseIcon>
                <!-- <div v-if="isMobile && !props.item.icon" class="h-[24px] w-[24px] mr-2"></div> -->

                <span
                    data-action="element-click"
                    class="font-medium whitespace-nowrap truncate"
                    :class="[(props.childerenMenuOpened || props.actionsMenuOpened) && !isMobile ? 'text-[#FFFFFF]' : isMobile ? 'text-[#131416]' : 'text-[#C0C2CD]']"
                    :style="{ 'max-width': `${props.maxLabelWidth}px` }">
                    {{ props.item.name }}
                </span>

                <RoundBadge data-action="element-click" v-if="item?.pins" color="danger" size="sm" class="cursor-pointer ml-1">
                    {{ pinCounter() }}
                </RoundBadge>
            </div>

            <BaseButton
                v-if="props.item.children?.length"
                variant="text"
                color="third"
                size="xs"
                class="link__toggle-btn group/collapse-button"
                @click="event => onShowProperties(event, 'children')">
                <template #icon>
                    <BaseIcon v-if="!isMobile" name="arrow-down-01" class="h-[24px] w-[24px]" :class="[{ 'fill-[#519DF5]': props.childerenMenuOpened }]"></BaseIcon>

                    <BaseIcon
                        v-else
                        name="arrow_Right_24px"
                        class="h-[24px] w-[24px] fill-none stroke-[#C0C2CD] group-hover/collapse-button:stroke-[#519DF5] transition-transform rotate-90"
                        :class="[{ 'fill-[#519DF5]': props.childerenMenuOpened }, { 'rotate-[270deg]': state.showChilds }]"></BaseIcon>
                </template>
            </BaseButton>
            <div
                v-if="props.active"
                class="absolute left-0 h-0 pointer-events-none"
                :class="isMobile ? 'h-full border-l-2 border-l-[#519DF5]' : 'w-full bottom-0 border-t-2 border-t-[#519DF5]'"></div>

            <BaseButton
                v-if="!isMobile && props.item.actions?.length"
                class="absolute left-1/2 translate-x-[-50%] bottom-[1px] translate-y-[50%] group-hover/menu-element:block"
                :class="[props.actionsMenuOpened ? 'block' : 'hidden']"
                @click="event => onShowProperties(event, 'actions')"
                size="20">
                <template #icon>
                    <BaseIcon name="Plas_24px" class="h-[18px] fill-[#FFFFFF]"></BaseIcon>
                </template>
            </BaseButton>
        </div>
        <HeaderMenu
            v-if="props.item.children?.length && isMobile && state.showChilds"
            :items="[props.item.children]"
            class="pl-3"
            @click-item="
                (ev, item) => {
                    emit('elementClick', ev, item);
                }
            "></HeaderMenu>
    </a>
</template>

<script setup>
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import RoundBadge from '@/components/ui/RoundBadge.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import HeaderMenu from '@/components/headerMenu/HeaderMenu.vue';

import { reactive } from 'vue';
//const { proxy } = getCurrentInstance();
//const isMobile = proxy.$isMobile.value;
import { useUserStore } from '@/stores/userStore';
const { isMobile } = useUserStore();

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    maxLabelWidth: {
        type: Number
    },
    actionsMenuOpened: {
        type: Boolean,
        default: false
    },
    childerenMenuOpened: {
        type: Boolean,
        default: false
    },
    active: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['showProperties', 'elementClick']);

const state = reactive({ showChilds: false });

const pinCounter = () => {
    if (props.item?.pins && props.item?.pins > 99) return '99+';
    return props.item?.pins;
};

const onElementClick = event => {
    if (event.target?.dataset?.action === 'element-click') {
        emit('elementClick', event, props.item);
    }
};

const onShowProperties = (event, type) => {
    state.showChilds = !state.showChilds;
    emit('showProperties', event, props.item.id, type, props.item);
};

const itemHref = element => {
    return element.href ? element.href : `/base/${element.id}`;
};
</script>

<style lang="scss" scoped>
.fade-in-animation {
    opacity: 0;
    animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
</style>
