export function MentionCustomization(editor) {
    // The upcast converter will convert <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for('upcast').elementToAttribute({
        view: {
            name: 'a',
            key: 'data-mention',
            classes: 'mention-user',
            attributes: {
                id: true,
                'data-id': true,
                href: true
            }
        },
        model: {
            key: 'mention',
            value: viewItem => {
                // The mention feature expects that the mention attribute value
                // in the model is a plain object with a set of additional attributes.
                // In order to create a proper object, use the toMentionAttribute helper method:
                const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
                    // Add any other properties that you need.
                    link: viewItem.getAttribute('href'),
                    userId: viewItem.getAttribute('data-id')
                });

                return mentionAttribute;
            }
        },
        converterPriority: 'high'
    });
    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for('downcast').attributeToElement({
        model: 'mention',
        view: (modelAttributeValue, { writer }) => {
            // Do not convert empty attributes (lack of value means no mention).
            if (!modelAttributeValue) {
                return;
            }

            return writer.createAttributeElement(
                'a',
                {
                    class: 'mention-user',
                    id: 'mention-' + modelAttributeValue.userId,
                    'data-id': modelAttributeValue.userId,
                    href: modelAttributeValue.photo_url
                },
                {
                    // Make mention attribute to be wrapped by other attribute elements.
                    priority: 20,
                    // Prevent merging mentions together.
                    id: modelAttributeValue.uid
                }
            );
        },
        converterPriority: 'high'
    });
}

export function ChangeShiftEnter(editor) {
    editor.editing.view.document.on(
        'enter',
        (evt, data) => {
            data.preventDefault();
            evt.stop();
            editor.execute('enter');
            editor.editing.view.scrollToTheSelection();
        },
        { priority: 'high' }
    );
}
