// export function debounce(func, timeout = 300) {
//   let timer;
//   return (...args) => {
//     if (!timer) {
//       func.apply(this, args);
//     }
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       timer = undefined;
//     }, timeout);
//   };
// }
import { diff } from 'deep-object-diff';
import { toRaw } from 'vue';

export function debounce(func, wait = 300, immediate = false) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
export function throttle(func, wait = 300) {
    let isThrottled = false;
    let timeout;
    let lastArgs;
    let lastContext;

    function clearThrottle() {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    }

    function throttled() {
        if (isThrottled) {
            lastArgs = arguments;
            lastContext = this;
            return;
        }

        func.apply(this, arguments);
        isThrottled = true;

        timeout = setTimeout(() => {
            isThrottled = false;
            if (lastArgs) {
                throttled.apply(lastContext, lastArgs);
                lastArgs = null;
                lastContext = null;
            }
        }, wait);
    }

    throttled.cancel = function () {
        isThrottled = false;
        clearThrottle();
    };

    return throttled;
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}
export const isObject = obj => {
    return typeof obj === 'object' && obj !== null && obj !== undefined && !Array.isArray(obj);
};
export const hasProperty = (obj, prop) => {
    if (!obj) return false;
    return Object.prototype.hasOwnProperty.call(obj, prop);
};
export function groupBy(data, property) {
    return data.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}
export const compareArrays = (a, b) => {
    if (!a || !b) return null;
    return (
        a.length === b.length &&
        a.every(el => {
            return b.includes(el);
        })
    );
};

export const arrayСontainsAllValues = (a, b) =>
    a.every(el => {
        return b.includes(el);
    });

export function compareArraysByField(array1, array2, field) {
    // Перевіряємо, чи масиви мають однакову довжину
    if (array1.length !== array2.length) {
        return false;
    }

    function createFieldValueMap(array, field) {
        const fieldValueMap = {};
        array.forEach(item => {
            const value = item[field];
            if (Object.prototype.hasOwnProperty.call(fieldValueMap, value)) {
                fieldValueMap[value]++;
            } else {
                fieldValueMap[value] = 1;
            }
        });
        return fieldValueMap;
    }

    // Створюємо об'єкти-карти для кожного масиву, де ключ - значення поля, а значення - кількість входжень цього значення
    const map1 = createFieldValueMap(array1, field);
    const map2 = createFieldValueMap(array2, field);

    // Порівнюємо масиви за значенням поля
    for (const key in map1) {
        if (Object.prototype.hasOwnProperty.call(map1, key) && Object.prototype.hasOwnProperty.call(map2, key)) {
            if (map1[key] !== map2[key]) {
                return false;
            }
        } else {
            return false;
        }
    }

    return true;
}

export function setObserver(el, func) {
    if (!el) return;
    const resizeObserver = new ResizeObserver(func);
    resizeObserver.observe(el);
    return resizeObserver;
}

export function getUniqObjectsArray(arr) {
    let result = [];
    const allId = [];
    for (const item of arr) {
        if (allId.includes(item.id)) continue;
        result.push(item);
        allId.push(item.id);
    }
    return result;
}
export function getUniqueObjectsArray(arr, key = 'id') {
    const uniqueObjects = new Map();

    for (const obj of arr) {
        const value = obj?.[key];
        if (!uniqueObjects.has(value)) {
            uniqueObjects.set(value, obj);
        }
    }

    return Array.from(uniqueObjects.values());
}
export function getUniqueArray(arr) {
    let result = [];
    const seen = new Set();

    for (const item of arr) {
        const serializedItem = JSON.stringify(item);
        if (seen.has(serializedItem)) continue;
        result.push(item);
        seen.add(serializedItem);
    }

    return result;
}
export function deleteObjFromArray(arr, deleteObjKey, key = 'id') {
    const idx = arr.findIndex(el => el?.[key] === deleteObjKey);
    if (idx >= 0) {
        arr.splice(idx, 1);
    } else {
        console.log('Array don`t have object with this id:', deleteObjKey);
    }
}
export function updateDifferentFieldsInObj(obj, updatedObj) {
    let diffs = diff(obj, updatedObj);

    if (Object.keys(diffs).length !== 0) {
        for (let key in diffs) {
            if (key !== 'id') obj[key] = toRaw(updatedObj[key]);
        }
    }
}

export function firstLevelDiff(oldObj, newObj) {
    const diffs = diff(oldObj, newObj);
    const getFullChanges = (original, changes) => {
        for (const key in changes) {
            if (Array.isArray(changes[key]) || typeof changes[key] === 'object') {
                changes[key] = newObj[key];
            }
        }
        return changes;
    };
    return getFullChanges(oldObj, diffs);
}

export function getFullName(obj) {
    if (!obj) return 'No data';
    return obj?.name ? obj.name : `${obj?.first_name} ${obj?.last_name ?? ''}`;
}
export function getInitials(name) {
    let parts = name.split(/[\s.@]/);
    let initials = '';

    for (let index = 0; index < parts.length; index++) {
        if (index < 2) initials += parts[index].charAt(0).toUpperCase();
    }

    return initials;
}

export function getShortName(item) {
    if (item?.first_name && item?.last_name) return `${item.first_name} ${item.last_name[0]}.`;
    else if (item?.name) {
        const name = item.name;
        let shortName = name.split(' ')[0];
        if (name.indexOf(' ') > -1) {
            shortName = `${name.substring(0, name.indexOf(' ') + 2)}.`;
        }
        return shortName;
    }
    return '---';
}

export function generateColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}
export function getColorText(backgroundColor) {
    function getColorFormat(color) {
        // A regular expression to specify the color format
        const hexRegex = /^#([0-9a-f]{3}){1,2}$/i;
        const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/i;

        if (hexRegex.test(color)) {
            return 'hex';
        } else if (rgbRegex.test(color)) {
            return 'rgb';
        } else {
            throw new Error('Unsupported color format');
        }
    }
    function colorToRgb(color, format) {
        switch (format) {
            case 'hex':
                return hexToRgb(color);
            case 'rgb':
                return rgbToRgb(color);
            default:
                throw new Error('Unsupported color format');
        }
    }
    function hexToRgb(hex) {
        // We extract the value of each color from the line
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return { r, g, b };
    }
    function rgbToRgb(rgb) {
        // We divide the value into three numbers
        const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        const r = parseInt(match[1]);
        const g = parseInt(match[2]);
        const b = parseInt(match[3]);

        return { r, g, b };
    }

    // Define the color format
    const colorFormat = getColorFormat(backgroundColor);

    // We convert the value of backgroundColor into RGB format
    const rgb = colorToRgb(backgroundColor, colorFormat);

    // We calculate the lightness of the color (https://en.wikipedia.org/wiki/Relative_luminance)
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

    // We return the black color if the lightness is less than 0.5, otherwise - the white color
    return luminance > 0.5 ? '#2a3547' : '#fff';
}
export function makeColorBlurry(color, percent = 10) {
    // Функція для конвертації будь-якого введення кольору у формат RGBA
    function convertColorToRGBA(color) {
        let div = document.createElement('div');
        div.style.backgroundColor = color;
        document.body.appendChild(div);
        let computedColor = window.getComputedStyle(div).backgroundColor;
        document.body.removeChild(div);
        return computedColor;
    }

    // Отримуємо колір у форматі RGBA
    let rgbaColor = convertColorToRGBA(color);

    // Витягаємо значення RGBA
    let match = rgbaColor.match(/rgba?\((\d+), (\d+), (\d+)(, [\d.]+)?\)/);
    if (!match) {
        throw new Error('Invalid color format');
    }

    // Отримуємо значення компонентів кольору
    let r = parseInt(match[1]);
    let g = parseInt(match[2]);
    let b = parseInt(match[3]);
    let alpha = match[4] ? parseFloat(match[4].slice(1)) : 1.0;

    // Застосовуємо 50% прозорості
    let newAlpha = alpha * (percent / 100);

    // Повертаємо колір у форматі rgba
    return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
}
export function makeColorBrighter(color, percent = 50) {
    // Функція для конвертації будь-якого введення кольору у формат RGB
    function convertColorToRGB(color) {
        let div = document.createElement('div');
        div.style.backgroundColor = color;
        document.body.appendChild(div);
        let computedColor = window.getComputedStyle(div).backgroundColor;
        document.body.removeChild(div);
        return computedColor;
    }

    // Отримуємо колір у форматі RGB
    let rgbColor = convertColorToRGB(color);

    // Розбираємо колір на складові RGB
    let match = rgbColor.match(/rgb\((\d+), (\d+), (\d+)\)/);
    if (!match) {
        throw new Error('Invalid color format');
    }

    let r = parseInt(match[1]);
    let g = parseInt(match[2]);
    let b = parseInt(match[3]);

    // Збільшуємо значення кожного каналу на відсоток від 0 до 100
    let newR = Math.min(255, r + r * (percent / 100));
    let newG = Math.min(255, g + g * (percent / 100));
    let newB = Math.min(255, b + b * (percent / 100));

    // Повертаємо новий колір у форматі RGB
    return `rgb(${Math.round(newR)}, ${Math.round(newG)}, ${Math.round(newB)})`;
}

export function draggableItems(array, oldIndex, newIndex, property) {
    array.map((item, idx) => {
        //drag down
        if (oldIndex < newIndex) {
            if (oldIndex <= idx && idx < newIndex) {
                item[property] -= 1;
            }
            if (idx === newIndex) {
                item[property] += newIndex - oldIndex;
            }
        }

        //drag up
        if (oldIndex > newIndex) {
            if (newIndex < idx && idx <= oldIndex) {
                item[property] += 1;
            }
            if (idx === newIndex) {
                item[property] -= oldIndex - newIndex;
            }
        }
    });
    return array;
}

// export function arraySort(arr, propertyName, order = "asc") {
//   const sortedArr = arr.sort((a, b) => {
//     // if (a[propertyName] < b[propertyName]) {
//     //   return -1;
//     // }
//     // if (a[propertyName] > b[propertyName]) {
//     //   return 1;
//     // }
//     // return 0;
//     return new Intl.Collator().compare(a[propertyName], b[propertyName]);
//   });

//   if (order === "desc") {
//     return sortedArr.reverse();
//   }

//   return sortedArr;
// }

export function arraySort(array, field, direction = 'asc') {
    function valueType(value) {
        if (value === null) {
            return 'null';
        } else return typeof value;
    }

    function comparator(a, b) {
        let valA, valB;
        switch (direction) {
            case 'asc':
                valA = a?.[field];
                valB = b?.[field];
                break;
            default:
                valA = b?.[field];
                valB = a?.[field];
                break;
        }
        const valueT = valueType(valA);
        // console.log('valueType',valueT, valA);
        switch (valueT) {
            case 'object':
                return compareObject(valA, valB);
            case 'string':
                return compareString(valA, valB);
            default:
                if (valA > valB) {
                    return 1;
                }
                if (valA < valB) {
                    return -1;
                }
                return 0;
        }

        function compareObject(a, b) {
            return new Intl.Collator().compare(a.name, b.name);
        }
        function compareString(a, b) {
            let valA = a.toLowerCase();
            let valb = b.toLowerCase();
            return new Intl.Collator().compare(valA, valb);
        }
    }
    return array.sort(comparator);
}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

export function redefineKeysValue(redefinedObject, updatedObject) {
    const keys1 = Object.keys(redefinedObject);

    for (const key of keys1) {
        redefinedObject[key] = updatedObject[key];
    }

    return redefinedObject;
}

export function convertDataToArray(data) {
    if (data) {
        if (Array.isArray(data)) return data;
        else if (isObject(data) && !isEmpty(data)) return [data];
        else return [];
    } else return [];
}
export function isPrimitive(inputValue) {
    if (inputValue == null || inputValue == undefined) {
        return true;
    }
    if (typeof inputValue == 'function' || typeof inputValue == 'object') {
        return false;
    } else {
        return true;
    }
}
export function isUUID(uuid) {
    let s = '' + uuid;
    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
        return false;
    }
    return true;
}
export function valueType(inputValue) {
    if (!inputValue || inputValue === null || inputValue === undefined) {
        return null;
    } else if (Array.isArray(inputValue)) {
        return 'array';
    } else if (isObject(inputValue)) {
        return 'object';
    } else return 'primitive';
}
// export async function copyToClipboard() {
//   try {
//     const url = window.location.href;
//     await navigator.clipboard.writeText(url);
//     return true;
//   } catch (error) {
//     console.error("Error while copying: ", error);
//     return false;
//   }
// }
export function hex2rgba(hex, alpha = 1) {
    if (hex) {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    }
}

export function getBodyFontFamily() {
    const bodyStyles = window.getComputedStyle(document.body);
    const fontFamily = bodyStyles.getPropertyValue('font-family');
    return fontFamily;
}

export function getTextWidth(text, font = `400 14px ${getBodyFontFamily()}`) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export function replaceSpaces(str) {
    if (!str) return null;
    console.log(str);
    str = str.split('');

    let spaces = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === ' ') {
            spaces++;
        }
    }

    for (let i = str.length - 1, j = str.length + 2 * spaces; i >= 0; i--) {
        if (str[i] === ' ') {
            str[j - 1] = '0';
            str[j - 2] = '2';
            str[j - 3] = '%';
            j -= 3;
        } else {
            str[j - 1] = str[i];
            j -= 1;
        }
    }
    return str.join('');
}

export function validateInput(inputValue, type) {
    if (!inputValue?.length) {
        return false;
    }
    switch (type) {
        case 'emails': {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(inputValue);
        }
        case 'phones': {
            const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{7,10}$/im;
            return phoneRegex.test(inputValue);
        }
        case 'websites':
        case 'linkedin':
        case 'facebook':
        case 'twitter':
        case 'instagram': {
            const validateWebsiteRegex = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', // fragment locator
                'i'
            );
            return validateWebsiteRegex.test(inputValue);
        }
    }
    return false;
}

export function generateInvalidFields(componentObject, requiredFieldsArray) {
    return requiredFieldsArray.reduce(
        (acc, el) =>
            !componentObject?.[el] || (Array.isArray(componentObject?.[el]) ? !componentObject?.[el].length : [undefined, null, ''].includes(componentObject?.[el]))
                ? [...acc, el]
                : acc,
        []
    );
}

export function isFieldValid(fieldName, fieldValue, requiredFieldsArray, invalidFieldsArray) {
    return Array.isArray(invalidFieldsArray) && invalidFieldsArray.length && requiredFieldsArray.includes(fieldName)
        ? Array.isArray(fieldValue)
            ? !!fieldValue.length
            : ![undefined, null, ''].includes(fieldValue)
        : true;
}

export function toCurrency(value, currency = 'USD', showFractionalPart = true) {
    if (!value) return '';
    let srt = value.toLocaleString('en-US', {
        style: 'currency',
        currency: currency
    });
    if (showFractionalPart) {
        return srt;
    } else return srt.slice(0, srt.length - 3);
}

export function cleanExtremeEmptyTagsFromEditor(text) {
    // Finding all occurrences of <p>&nbsp;</p>,that are at the beginning of the string
    const startPattern = /^(<p>&nbsp;<\/p>)+/g;
    // Finding all occurrences of <p>&nbsp;</p>,that are at the end of the string
    const endPattern = /(<p>&nbsp;<\/p>)+$/g;

    // Replace matching occurrences with an empty string
    const cleanedText = text.replace(startPattern, '').replace(endPattern, '');

    return cleanedText;
}

export function sortData(data, columnKey, cellRenderer, direction) {
    const stringComparator = (a, b) => (a[columnKey] < b[columnKey] ? -1 : a[columnKey] > b[columnKey] ? 1 : 0);
    const numberComparator = (a, b) => a[columnKey] - b[columnKey];

    const sorter = {
        booleanRenderer: numberComparator,
        currencyRenderer: numberComparator,
        richTextRenderer: stringComparator,
        dateRenderer: (a, b) => new Date(a[columnKey]) - new Date(b[columnKey])
    };
    return data.sort((a, b) => {
        const sortFunction = sorter[cellRenderer] || stringComparator;
        if (!sortFunction) {
            console.warn(`No sorter defined for cellRenderer: ${cellRenderer}`);
            return 0;
        }

        return direction === 'asc' ? sortFunction(a, b) : sortFunction(b, a);
    });
}

export function toBoolean(value) {
    if (typeof value === 'boolean') {
        return value;
    }
    return value.toLowerCase() === 'true';
}

export function mergeDeep(target, source) {
    for (const key in source) {
        if (hasProperty(source, key)) {
            if (typeof source[key] === 'object' && source[key] !== null) {
                if (!target[key]) {
                    target[key] = {};
                }
                mergeDeep(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
    }

    return target;
}
