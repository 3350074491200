<template>
    <div class="platform__header h-[56px] flex flex-nowrap px-4 grow w-full items-center gap-2" ref="platform__header">
        <router-link
            to="/"
            alt="Logo white"
            class="flex select-none h-full"
            :class="[isMobile ? 'max-h-[27px] max-w-[94px] min-w-[94px]' : ' max-h-[24px] max-w-[83px] min-w-[83px]']">
            <img src="@/assets/images/Logo white.svg" />
        </router-link>
        <HeaderMenu v-if="!isMobile && menuVsBadges.length > 0" :items="menuVsBadges" />
        <div v-else class="flex grow"></div>
        <div class="flex flex-nowrap items-center" :class="[{ 'gap-4': !isMobile }]">
            <div class="flex flex-nowrap items-center" :class="[{ 'gap-2': !isMobile }]">
                <GlobalSearch />
                <HeaderNotifications />
                <HeaderDivider v-if="!isMobile" />
                <TrackingHeader />
                <BugReport />
                <HeaderDivider />

                <BaseButton v-if="isMobile" color="third" variant="text" size="sm" :rounded="false" @click="toggleMenuMobile" v-tooltip:bottom="$t('tooltip.menu')">
                    <template #icon>
                        <BaseIcon name="menu" class="h-[30px] w-[30px]" viewBox="0 0 20 20"></BaseIcon>
                    </template>
                </BaseButton>
            </div>
            <ProfileMenu v-if="!isMobile" />
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import HeaderMenu from '@/components/headerMenu/HeaderMenu.vue';
import HeaderNotifications from '@/components/platformNotifications/HeaderNotifications.vue';
import TrackingHeader from '@/components/tasks/taskCard/timeTracking/TrackingHeader.vue';
import GlobalSearch from '@/components/globalSearch/GlobalSearch.vue';
import BugReport from '@/components/bugReport/BugReport.vue';
import ProfileMenu from '@/components/account/ProfileMenu.vue';
import HeaderDivider from '@/components/platformHeader/components/HeaderDivider.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import { useMenuStore } from '@/stores/menuStore';
import { useUserStore } from '@/stores/userStore';
import { useBadgesStore } from '@/stores/badgesStore';
//import { markRaw } from 'vue';

export default {
    name: 'PlatformHeader',
    components: {
        HeaderMenu,
        HeaderNotifications,
        TrackingHeader,
        GlobalSearch,
        ProfileMenu,
        HeaderDivider,
        BaseIcon,
        BaseButton,
        BugReport
    },
    computed: {
        ...mapState(useMenuStore, ['menu', 'menuVsBadges', 'getMenuItemById']),
        ...mapState(useUserStore, ['isMobile'])
    },
    methods: {
        ...mapActions(useUserStore, ['getAccounts', 'getSubordinates']),
        ...mapActions(useMenuStore, ['getUserMenu', 'addMenuItem', 'addMenuMainItem', 'updateMenuItem', 'deleteMenuItem', 'generateMenuItem']),
        ...mapActions(useBadgesStore, ['getBadges', 'insertBadgeByWS', 'deleteBadgeByWS', 'updateBadgeByWS', 'deleteBadge']),
        findMenuItemById(target, id) {
            for (const menu of target) {
                for (const item of menu) {
                    if (item.id === id) {
                        return item;
                    }
                    if (item.children) {
                        this.findMenuItemById([item.children], id);
                    }
                }
            }
            return false;
        },
        toggleMenuMobile() {
            this.$modal.open({
                name: 'menuContainer', //markRaw(HeaderMenu) 'BugReportForm',
                system: true,
                modalProps: {
                    horizontalAlign: 'right',
                    borderRTR: false,
                    borderRBR: false,
                    verticalAlign: 'center',
                    class: '',
                    fullHeight: true,
                    borderRadius: '1rem'
                },
                props: {}
            });
        }
    },
    async created() {
        await this.getAccounts();
        await this.getSubordinates();
        this.getBadges();
        this.getUserMenu();
    },
    sockets: {
        badge(payload) {
            if (payload.event) {
                switch (payload.event) {
                    case 'insert':
                        if (this.$route?.query?.id && this.$route?.query?.id === payload.data?.instance_id && payload.data?.type !== 'approve') {
                            this.deleteBadge(payload.data.entity_id, payload.data.instance_id);
                            return;
                        } else {
                            this.insertBadgeByWS(payload.data);
                        }
                        break;
                    case 'update':
                        this.updateBadgeByWS(payload.data);
                        console.log('Update Badge by WS', payload.data);
                        break;
                    case 'delete':
                        this.deleteBadgeByWS(payload.data);
                        break;
                    default:
                        break;
                }
            }
        },
        menu_item(payload) {
            if (payload.event) {
                switch (payload.event) {
                    case 'insert':
                        this.addMenuItem(payload.data.parent_id, payload.data, true);
                        break;
                    case 'update': {
                        const foundItem = this.findMenuItemById(this.menuVsBadges, payload.data.id);
                        if (foundItem) {
                            for (const [prop, value] of Object.entries(payload.data)) {
                                if (foundItem[prop] !== value) {
                                    this.updateMenuItem(payload.id, prop, value, true);
                                }
                            }
                        }
                        break;
                    }
                    case 'delete':
                        this.deleteMenuItem(payload.data.id, true);
                        break;
                    default:
                        break;
                }
            }
        },
        flyout(payload) {
            if (payload.data) {
                this.$notify(
                    {
                        type: payload.data?.type,
                        title: payload.data?.title,
                        text: payload.data?.text
                    },
                    2000
                );
            }
        }
    }
};
</script>
