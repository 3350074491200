<template>
    <div data-element="button" class="avatar__wrapper" :class="[{ inactive: !isActive }, (readonly ? 'cursor-not-allowed' : 'cursor-pointer')]" @click="openUserInformation">
        <div
            v-if="user && (user?.name || user?.first_name || user?.last_name || user?.[imageField])"
            class="avatar"
            :class="{ border: borderColor }"
            :style="'width:' + widthPic + 'px; height: ' + heightPic + 'px;'">
            <div v-if="user?.[imageField] && user?.[imageField] !== 'None'" class="picture">
                <img :src="user?.[imageField]" alt="photo of the person in charge" />
            </div>
            <div v-else class="initials">
                {{ userInitials }}
            </div>
        </div>
        <div
            v-else
            class="empty flex items-center justify-center text-primary fill-primary bg-[#f2f9ff]"
            :class="{ border: borderColor }"
            :style="'width:' + widthPic + 'px; height: ' + heightPic + 'px;'">
            <base-icon class="icon w-[18px] h-[18px]" :name="editable ? 'userPlus' : 'user'"></base-icon>
        </div>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';

import { getFullName, getInitials, generateColor, getColorText } from '@/helpers';

export default {
    name: 'BaseAvatar',
    components: {
        BaseIcon
    },
    props: {
        user: {
            type: [Object, String],
            default: null
        },
        imageField: {
            type: String,
            default: 'photo_url'
        },
        widthPic: {
            type: Number,
            default: 30
        },
        heightPic: {
            type: Number,
            default: 30
        },
        showInformation: {
            type: Boolean,
            default: true
        },
        rounded: {
            type: Number,
            default: 999
        },
        borderColor: {
            type: String
        },
        editable: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['userClicked'],
    data() {
        return {};
    },
    computed: {
        fullName() {
            return getFullName(this.user);
        },
        userInitials() {
            return getInitials(this.fullName);
        },
        userBackgroundColor() {
            return generateColor(this.fullName);
        },
        userTextColor() {
            return getColorText(this.userBackgroundColor);
        },
        imageRounded() {
            return `${this.rounded}px`;
        },
        initialSize() {
            const minSize = Math.min(this.widthPic, this.heightPic);
            const size = Number.parseInt(minSize / 2);
            return `${size}px`;
            // return "";
        },
        isActive() {
            if (this.user && typeof this.user === 'object' && Object.prototype.hasOwnProperty.call(this.user, 'is_active')) return this.user.is_active;
            else return true;
        }
    },
    methods: {
        openUserInformation() {
            if (!this.showInformation) return;
            const userId = this.user?.id;
            if (userId) {
                this.$emit('userClicked', this.user);
                this.$modal.open({
                    id: userId,
                    name: 'UserInformation',
                    useInFavorite: false,
                    useCopyLink: false,
                    props: {
                        userId
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
img {
    display: block;
    max-width: 100%;
    height: auto;
}
.avatar__wrapper {
    display: inline;

    &.inactive {
        img {
            filter: grayscale(100%);
            opacity: 0.6;
        }
        .initials {
            background-color: gray;
        }
    }
}
.avatar {
    border-radius: v-bind(imageRounded);
    overflow: hidden;
    flex-shrink: 0;
    pointer-events: none;

    &.border {
        border: 2px solid;
        border-color: v-bind(borderColor);
    }
}

.picture {
    pointer-events: none;
}
.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: v-bind(initialSize);
    color: v-bind(userTextColor);
    background-color: v-bind(userBackgroundColor);
    pointer-events: none;
}
.empty {
    border-radius: v-bind(imageRounded);
    pointer-events: none;

    &.border {
        border: 2px solid;
        border-color: v-bind(borderColor);
    }
}
</style>
