<template>
    <Teleport to="#modal-container">
        <div
            :class="['flyout-wrapper', 'flex flex-col pointer-events-auto']"
            :style="{
                ...initialPosition,
                zIndex: props.zindex,
                maxWidth: maxWidth + 'px'
            }"
            @mouseenter="emit('mouseenter')"
            @mouseleave="emit('mouseleave')"
            :data-modal-created-timestamp="timeComponentCreated">
            <slot></slot>
        </div>
        <div
            v-if="props.showArrow && !props.positionOnMouse"
            class="flyout-arrow w-2.5 h-2.5 absolute border-t border-l"
            :style="{
                ...initialPositionArrow,
                zIndex: props.zindex
            }"></div>
    </Teleport>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';

// import { useUserStore } from '@/stores/userStore';
// const { isMobile } = useUserStore();
// const isMobile = computed(() => useUserStore().isMobile);

const emit = defineEmits(['closeFlyoutWrapper', 'close', 'mouseenter', 'mouseleave', 'componentApi']);

const props = defineProps({
    event: {
        type: Object,
        default: null
    },
    parentIdForSubWrapper: { type: String, Number, default: '' },
    timeParentCreated: {
        type: Number,
        default: null
    },
    maxHeight: {
        type: Number,
        default: 1000
    },
    showArrow: {
        type: Boolean,
        default: false
    },
    aside: {
        type: Boolean,
        default: false
    },
    showFullSlot: {
        type: Boolean,
        default: false
    },
    positionOnMouse: {
        type: Boolean,
        default: false
    },
    zindex: {
        type: Number,
        default: 1000
    },
    positions: {
        type: Object
    },
    maxWidth: {
        type: Number,
        default: 500
    }
});
let componentApi = {};
const initComponentApi = () => {
    componentApi = {
        recalculatePosition: recalculatePosition
    };
};

const initialPosition = ref({ left: 0, top: 0 });
const initialPositionArrow = ref({ left: 0, top: 0 });
let realFlyoutSize = { width: 0, height: 0 };

const handleKeyPress = event => {
    if (event.key === 'Escape') {
        event.stopPropagation();
        emit('closeFlyoutWrapper');
        emit('close');
    }
};

const recalculatePosition = async () => {
    initialPositionArrow.value = { left: 0, top: 0 };
    initialPosition.value = { left: 0, top: 0 };
    await nextTick();
    initialPosition.value = coordinates(props.event);
};

onMounted(() => {
    if (props.event) {
        setTimeout(() => {
            document.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('mouseup', handleMouseUp);
        }, 0);

        initialPosition.value = coordinates(props.event);

        window.addEventListener('resize', () => {
            emit('closeFlyoutWrapper');
            emit('close');
        });

        const clickedElement = props.event?.target;
        if (clickedElement) {
            let parent = clickedElement.parentNode;
            while (parent) {
                if (parent.scrollHeight > parent.clientHeight) {
                    parent.addEventListener('scroll', () => {
                        emit('closeFlyoutWrapper');
                        emit('close');
                    });
                }
                parent = parent.parentNode;
            }
        }
    }
    initComponentApi();
    emit('componentApi', componentApi, timeComponentCreated);
    document.addEventListener('keydown', handleKeyPress, true);
});

onUnmounted(() => {
    if (props.event) {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);

        window.removeEventListener('resize', () => {
            emit('closeFlyoutWrapper');
            emit('close');
        });

        const clickedElement = props.event.target;
        if (clickedElement) {
            let parent = clickedElement.parentNode;
            while (parent) {
                if (parent.scrollHeight > parent.clientHeight) {
                    parent.removeEventListener('scroll', () => {
                        emit('closeFlyoutWrapper');
                        emit('close');
                    });
                }
                parent = parent.parentNode;
            }
        }
    }
    document.removeEventListener('keydown', handleKeyPress, true);
});

const isMouseDownInside = ref(false);

function isClickInside(event) {
    let isClickInside = false;

    const flyout = document.querySelector(`[data-modal-created-timestamp="${timeComponentCreated}"].flyout-wrapper`);

    if (flyout?.contains(event.target)) {
        isClickInside = true;
    }
    let isClickInsideModal = false;
    let currentElement = event.target;
    while (currentElement) {
        const elementTimestamp = parseFloat(currentElement.dataset.modalCreatedTimestamp);
        if (elementTimestamp && elementTimestamp > timeComponentCreated) {
            isClickInside = true;
            break;
        }
        currentElement = currentElement.parentElement;
    }

    return isClickInside || isClickInsideModal;
}

function handleMouseDown(event) {
    isMouseDownInside.value = isClickInside(event);
}

function handleMouseUp(event) {
    if (!isClickInside(event) && !isMouseDownInside.value) {
        emit('closeFlyoutWrapper');
        emit('close');
    }
}

// const elementClicked = props.event?.currentTarget ? props.event?.currentTarget : props.event?.target;
const elementClicked = props.event?.target;

const coordinates = event => {
    if (!event) {
        console.warn('Flyout menu WARNING. Cannot display flyout menu, no event in props');
        emit('closeFlyoutWrapper');
        emit('close');
        return {};
    }
    const flyout = document.querySelector(`[data-modal-created-timestamp="${timeComponentCreated}"].flyout-wrapper`);
    realFlyoutSize.width = flyout?.offsetWidth;
    realFlyoutSize.height = flyout?.offsetHeight;
    const distanceFromParent = 8;
    const scrollSize = 10;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let elementRect = elementClicked?.getBoundingClientRect();

    if (props.positions) {
        const horizontalAlignment = { left: props.positions.x + 'px' };
        const verticalAlignment = { top: props.positions.y + 'px' };
        return {
            ...horizontalAlignment,
            ...verticalAlignment
        };
    } else if ((props.positionOnMouse && props.event?.clientX) || (elementRect.width === 0 && elementRect.height === 0)) {
        const horizontalAlignment =
            event.pageX + realFlyoutSize.width + scrollSize < windowWidth
                ? { left: event.pageX + 'px' }
                : {
                      left: windowWidth + window.scrollX - realFlyoutSize.width - scrollSize + 'px',
                      maxWidth: realFlyoutSize.width + 'px'
                  };

        let verticalAlignment =
            windowHeight - event.clientY - scrollSize > Math.min(realFlyoutSize.height, props.maxHeight) || windowHeight - event.clientY > windowHeight / 2
                ? {
                      top: event.pageY + 'px',
                      maxHeight: props.showFullSlot ? windowHeight - event.clientY - scrollSize + 'px' : Math.min(windowHeight - event.clientY - scrollSize, props.maxHeight) + 'px'
                  }
                : {
                      bottom: windowHeight - event.pageY + 'px',
                      maxHeight: props.showFullSlot ? event.clientY - scrollSize + 'px' : Math.min(event.clientY - scrollSize, props.maxHeight) + 'px'
                  };

        return {
            ...verticalAlignment,
            ...horizontalAlignment
        };
    } else {
        let horizontalAlignment = {};
        let verticalAlignment = {};
        initialPositionArrow.value = {
            left: realFlyoutSize.width > elementRect.width / 2 ? elementRect.left + elementRect.width / 2 - 4 + 'px' : elementRect.left + realFlyoutSize.width - 20 + 'px',
            backgroundImage: 'linear-gradient(135deg, #ffffff 49%, #ffffff00 49%)'
        };
        if (props.aside && elementRect.right + realFlyoutSize.width + window.scrollX + distanceFromParent < windowWidth) {
            horizontalAlignment = {
                left: elementRect.right + distanceFromParent + 'px',
                maxWidth: windowWidth - (elementRect.right + distanceFromParent + scrollSize) + 'px'
            };
            verticalAlignment = {
                top: elementRect.top - Math.max(elementRect.top + realFlyoutSize.height - windowHeight + scrollSize, 0) + 'px',
                maxHeight:
                    Math.min(windowHeight - (elementRect.top - Math.max(elementRect.top + realFlyoutSize.height - windowHeight + scrollSize, 0) + scrollSize), props.maxHeight) +
                    'px'
            };
            initialPositionArrow.value = {
                ...initialPositionArrow.value,
                top: Math.min(elementRect.top + 8, windowHeight - 26) + 'px',
                left: parseInt(horizontalAlignment.left, 10) - 4 + 'px',
                transform: 'rotate(315deg)'
            };
        } else {
            horizontalAlignment =
                elementRect.left < 0
                    ? { left: distanceFromParent }
                    : elementRect.left + realFlyoutSize.width + window.scrollX < windowWidth
                      ? {
                            left: elementRect.left + window.scrollX + 'px',
                            maxWidth: windowWidth - (elementRect.left + window.scrollX + scrollSize) + 'px'
                        }
                      : {
                            left: windowWidth - realFlyoutSize.width + window.scrollX - distanceFromParent + 'px',
                            maxWidth: windowWidth - scrollSize * 2 + 'px'
                        };

            verticalAlignment =
                (windowHeight - (elementRect.bottom + distanceFromParent + scrollSize + 20) > Math.min(140, realFlyoutSize.height) &&
                    !(props.showFullSlot && windowHeight - (elementRect.bottom + distanceFromParent + scrollSize) < realFlyoutSize.height)) ||
                windowHeight - elementRect.bottom > elementRect.top
                    ? {
                          top: elementRect.bottom + window.scrollY + distanceFromParent + 'px',
                          maxHeight: Math.min(windowHeight - elementRect.bottom - scrollSize, props.maxHeight) + 'px'
                      }
                    : {
                          bottom: windowHeight - elementRect.top + window.scrollY + distanceFromParent + 'px',
                          maxHeight: Math.min(elementRect.top - scrollSize, props.maxHeight) + 'px'
                      };

            if ('top' in verticalAlignment) {
                initialPositionArrow.value = {
                    ...initialPositionArrow.value,
                    top: parseInt(verticalAlignment.top, 10) - 4 + 'px',
                    transform: 'rotate(45deg)'
                };
            }
            if ('bottom' in verticalAlignment) {
                initialPositionArrow.value = {
                    ...initialPositionArrow.value,
                    bottom: parseInt(verticalAlignment.bottom, 10) - 4 + 'px',
                    transform: 'rotate(225deg)'
                };
            }
        }
        return {
            ...verticalAlignment,
            ...horizontalAlignment
        };
    }
};

const timeComponentCreated = performance.now();
</script>

<style lang="scss" scoped>
.flyout-wrapper {
    position: absolute;
    z-index: 10000;
    // overflow-y: auto;
    border-radius: 12px;
    border: 1px solid #eaebee;
    background: #fff;
    // box-shadow: var(--sp-shadow);
    box-shadow: 0px 10px 10px 2px #686c6f0f;
}
.flyout-arrow {
    z-index: 10001;
}
</style>
