import { isObject } from './common';

export function generateTree(data) {
    if (!Array.isArray(data) || data.length === 0) {
        return [];
    }
    let localData = JSON.parse(JSON.stringify(data));
    // console.log("start generate tree", data.length, new Date());
    const idMapping = localData.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
    }, {});
    let root = [];
    localData.forEach(el => {
        if (!el.parent_id || el.parent_id === '' || !Object.prototype.hasOwnProperty.call(idMapping, el.parent_id)) {
            root.push(el);
            return;
        }
        const parentEl = localData[idMapping[el.parent_id]];
        if (!Object.prototype.hasOwnProperty.call(parentEl, 'children')) {
            parentEl.children = [...(parentEl?.children || []), el];
        } else {
            let existChildrenIndex = parentEl.children.findIndex(item => item.id === el.id);
            if (existChildrenIndex !== -1) {
                parentEl.children[existChildrenIndex] = el;
            } else {
                parentEl.children.push(el);
            }
        }
    });
    return root;
}

export function findObjectInTree(
    data,
    value,
    options = {
        childrenField: 'children',
        searchFieldName: 'id',
        returnParent: false
    }
) {
    const stack = new Set(data); // Use a Set instead of an array
    const childrenField = options.childrenField ?? 'children';
    const searchFieldName = options.searchFieldName ?? 'id';
    const returnParent = options.returnParent ?? false;
    const parentsMapping = new Map();

    while (stack.size) {
        const item = stack.values().next().value; // Use values() and next() to get the next item
        stack.delete(item); // Remove the item from the set
        if (item[searchFieldName] === value) {
            const parent = parentsMapping.get(item.parent_id);
            return returnParent
                ? {
                      item,
                      parent
                  }
                : item;
        }
        if (item.children_count) {
            parentsMapping.set(item.id, item);
        }
        if (item[childrenField]) {
            item[childrenField].forEach(child => stack.add(child)); // Use forEach to add children to the set
        }
    }

    return returnParent
        ? {
              item: null,
              parent: null
          }
        : null;
}

export function onlyParrent(data) {
    if (!Array.isArray(data) || data.length === 0) {
        return [];
    }
    // console.log("start generate tree", data.length, new Date());
    const idMapping = data.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
    }, {});
    let root = [];
    data.forEach(el => {
        if (!el.parent_id || el.parent_id === '' || !Object.prototype.hasOwnProperty.call(idMapping, el.parent_id)) {
            root.push(el);
            return;
        }
        // const parentEl = data[idMapping[el.parent_id]];
        // parentEl.children = [...(parentEl?.children || []), el];
    });
    // console.log("and generate tree", new Date());
    return root;
}

function findR(tree, value, searchProps = 'id', childrenProps = 'items') {
    let res = null;

    function find(target, value, searchProps, childrenProps) {
        if (Array.isArray(target) && target.length > 0) {
            for (const el of target) {
                if (el?.[searchProps] === value) {
                    res = el;
                    break;
                } else if (isObject(el) && childrenProps in el) {
                    find(el[childrenProps], value, searchProps, childrenProps);
                }
            }
        }
    }

    find(tree, value, searchProps, childrenProps);
    return res;
}

export function findElementById(tree, elId) {
    return findR(tree, elId);
}

export function addElement(tree, newEll, parent = null, childrenProps = 'items') {
    console.warn('newEll', newEll);
    if (!parent) {
        tree.push(newEll);
        return true;
    } else {
        let el = findElementById(tree, parent);
        if (!el) return false;
        if (el[childrenProps] && Array.isArray(el[childrenProps])) {
            el[childrenProps].push(newEll);
            return true;
        } else {
            el[childrenProps] = [{ ...newEll }];
            return true;
        }
    }
}

export function updateElement(tree, elId, data) {
    let el = findElementById(tree, elId);
    if (!el) return false;
    Object.keys(data)?.forEach(key => {
        el[key] = data[key];
    });
    return el;
}
