// import menuItem from "./devdata/menu.json";
import { sendRequest } from '@/helpers';

export default {
    async getTranslate(locale) {
        return await sendRequest(`/api/i18n/${locale}`, 'get', '');
    },
    async getTranslateList() {
        return await sendRequest(`/api/i18n/list`, 'get', '');
    }
};
