<template>
    <li class="base-list-item border-b-[1px] border-[#eaebee] last:border-0" tabindex="-1">
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: 'NewBaseListItem'
};
</script>

<style lang="scss" scoped>
li {
    list-style: none;
}
.base-list-item {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: #040c12;
    /* border-left: 2px solid transparent; */
    /* border-bottom: 1px solid #eaebee; */
    cursor: pointer;
    outline: none;

    transition-property: color, background-color, border-color;
    transition-duration: var(--sp-transition-duration, 250ms);
    transition-timing-function: var(--sp-transition-duration, cubic-bezier(0.4, 0, 0.2, 1));

    &:hover {
        color: #519df5;
        /* background-color: var(--sp-secondary-hover, #f4f4f4);
    border-left-color: var(--sp-third-hover, #e0e0e0); */
    }
    &:focus {
        color: #519df5;
        /* color: var(--sp-primary, #2693ff);
    background-color: var(--sp-primary-focus, #ebf5ff);
    border-left-color: var(--sp-primary, #2693ff); */
    }
}
</style>
