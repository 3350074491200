export function isNumber(value) {
    const numberRegex = /^\d+$/; // Checks whether the value consists only of numbers
    if (numberRegex.test(value)) {
        const numberValue = parseInt(value, 10);
        return numberValue;
    } else {
        return false;
    }
}

export function extractIdsFromUrl(url) {
    const regex = /[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/gi;
    const matches = url.match(regex);

    return matches || [];
}

export function createRequestParams(value = '', currentTaskId, relation = 'parent') {
    const params = {
        // for_parent: currentTaskId, //task_id
        fields: {
            id: {},
            name: {},
            groups: {
                id: {},
                name: {}
            },
            responsible: {
                id: {},
                name: {},
                photo_url: {}
            },
            stage: {
                id: {},
                name: {},
                color: {}
            }
        },
        filter: {
            conditions: [
                {
                    field: 'name',
                    comparator: 'like',
                    value: value,
                    type: 'condition'
                }
            ],
            operator: 'or',
            type: 'conjunction'
        },
        with_groups: true,
        limit: 20
    };

    // relation
    // for_parent (parent) - returns a list of tasks for which this task task_id can be a parent
    // for_children (children) - returns a list of tasks for which this task task_id can be a child
    switch (relation) {
        case 'parent':
            params.for_parent = currentTaskId;
            break;
        case 'children':
            params.for_children = currentTaskId;
            break;

        default:
            break;
    }

    const number = isNumber(value);
    if (number) {
        params.filter.conditions.push({
            field: 'short_id',
            comparator: '=',
            value: number,
            type: 'condition'
        });
        params.filter.conditions.push({
            field: 'external_id_int',
            comparator: '=',
            value: number,
            type: 'condition'
        });
    }

    const ids = extractIdsFromUrl(value);
    if (ids?.length) {
        ids.map(id =>
            params.filter.conditions.push({
                field: 'id',
                comparator: '=',
                value: id,
                type: 'condition'
            })
        );
    }

    return params;
}
