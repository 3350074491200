<template>
    <a
        :href="props.data?.url"
        @click.prevent="!props.data.readonly ? handleButtonClick(props.data.id, $event, props.data) : (submenuVisible = false)"
        class="context-menu__element flex justify-between items-center"
        :class="[
            { 'context-menu__element_readonly': props.data.readonly },
            {
                'bg-[#519DF51A]': props.data.id === props.buttonActive || props.data.active
            },
            {
                top_element: props.isTop
            }
        ]">
        <div class="flex gap-1 items-center">
            <div
                v-if="props.data.icon && typeof props.data.icon === 'string'"
                class="context-menu__element__icon flex items-center justify-center rounded-full"
                :style="{
                    height: `${props.iconWrapperHeight}px`,
                    width: `${props.iconWrapperHeight}px`,
                    background: props.data.iconBgColor ? props.data.iconBgColor : ''
                }">
                <base-icon
                    v-if="props.data.icon"
                    :style="{
                        height: `${props.iconHeight}px`,
                        fill: props.data.iconColor ? props.data.iconColor : ''
                    }"
                    :name="props.data.icon"
                    class="context-menu__element-icon"></base-icon>
            </div>
            <span v-if="props.data.label">
                {{ props.data.label }}
            </span>
        </div>
        <BaseButton v-if="props.data.items" data-action="open-submenu" variant="text" color="third" size="xs" :rounded="false">
            <template #icon>
                <BaseIcon name="arrow_Right_24px" class="h-[24px] w-[24px] pointer-events-none fill-none stroke-[#C0C2CD]"></BaseIcon>
            </template>
        </BaseButton>
        <ContextMenu
            v-if="props.data.items && submenuVisible"
            :items="props.data.items"
            :event="coordinateEvent"
            @changedValue="(...data) => transferData(...data)"
            @close="submenuVisible = false"
            :iconHeight="props.iconHeight" />
    </a>
</template>

<script setup>
import { inject, ref } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ContextMenu from '../ContextMenu.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import { InjectionKeyButtonClick, InjectionKeyTransferData } from '../keys.js';

const transferData = inject(InjectionKeyTransferData);
const buttonClick = inject(InjectionKeyButtonClick);

const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    timeParentCreatedForSubMenu: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    buttonActive: String,
    isTop: Boolean
});

const submenuVisible = ref(false);

let coordinateEvent = null;

const handleButtonClick = (id, event, object) => {
    if (event.target?.dataset?.action !== 'open-submenu') {
        buttonClick(id, event, object);
    } else if (props.data.items) {
        coordinateEvent = event;
        submenuVisible.value = true;
    }
};
</script>

<style scoped>
.top_element {
    margin-bottom: 5px;
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #dae6f8;
        position: absolute;
        bottom: -5px;
        z-index: 10;
        left: 0;
    }
}
</style>
