<template>
    <div></div>
</template>
<script>
export default {
    name: 'TopbarAlert',
    methods: {
        showAlert(data) {
            this.$notify(
                {
                    type: data?.type,
                    title: data?.title,
                    text: data?.text
                },
                data?.timeout
            );
            // info, warning, success, error
        }
    },

    sockets: {
        flyout(payload) {
            this.showAlert(payload);
        }
    }
};
</script>
