export function replaysRouterQueryParams(router, newQueryParams) {
    let queryParams = { ...router.query };
    for (let key in newQueryParams) {
        if (newQueryParams[key] && newQueryParams[key] != '' && newQueryParams[key] != 'null') {
            queryParams[key] = newQueryParams[key];
        } else {
            delete queryParams[key];
        }
    }
    let clearQueryParams = {};
    for (let key in queryParams) {
        if (queryParams[key] != '' && queryParams[key] != 'null') {
            clearQueryParams[key] = queryParams[key];
        }
    }
    return clearQueryParams;
}

export function hashHasMultiRules(hash) {
    return hash?.includes('&');
}
