<template>
    <div class="bg-[var(-sp-bg)] grow flex items-center justify-center relative overflow-hidden">
        <span class="text-[240px] lg:text-[360px] font-semibold text-black opacity-[0.06] scale-x-110 select-none leading-[0.75em]">404</span>
        <div class="absolute flex flex-col items-center w-[400px] lg:w-[700px]">
            <h1 class="text-5xl text-[var(--sp-text-primary)] font-bold tracking-wide text-center">
                {{ $t('lables.pageNotFound') }}
            </h1>
            <!-- <p class="text-base mt-2 text-[var(--sp-text-secondary)]">
        {{ $t("lables.goHome") }}
      </p> -->
            <base-button @click="goToHome" class="mt-5">{{ $t('lables.goHome') }}</base-button>
        </div>
    </div>
</template>
<script setup>
import BaseButton from '@/components/ui/BaseButton.vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const goToHome = () => {
    router.push({ path: '/' });
};
</script>
