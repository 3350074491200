import AlertComponent from './AlertComponent.vue';

import { methods } from './notifications.js';

let installed = false;
export default {
    install(app) {
        if (installed) return;
        app.component('AlertComponent', AlertComponent);
        app.config.globalProperties.$notify = (data, timeout) => methods.notify(data, timeout);
        installed = true;
    }
};
