import { useCursorLoaderStore } from '@/stores/cursorLoader';

class CursorLoader {
    constructor() {
        this.store = useCursorLoaderStore();
    }

    show() {
        this.store.show();
    }

    hide() {
        this.store.hide();
    }
}

export { CursorLoader };
