import { defineStore } from 'pinia';
import notificationsApi from '../api/notifications';

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        initialNotifications: null,
        notifications: null
    }),

    getters: {},
    actions: {
        async getNotifications() {
            const res = await notificationsApi.getUserNotifications();
            this.initialNotifications = res;
            this.notifications = res;
        },
        async getNewNotifications() {
            this.notifications = await notificationsApi.getNewUserNotifications();
        },
        async updateNotifications(id, data) {
            let el = this.notifications.find(el => el.id === id);
            if (el) {
                el = Object.assign(el, data);
            }
            return el;
        },

        insertNotificationsByWS(data) {
            console.log('insertNotificationsByWS', data);
            if (!this.notifications || !Array.isArray(this.notifications)) {
                this.notifications = [];
            }
            let existNotifications = this.notifications.find(el => {
                return el.id === data.id;
            });
            if (existNotifications) {
                console.log('Notifications exist, not push');
                return;
            } else {
                console.log('Notifications not exist, push');
                this.notifications.push(data);
            }
        },
        updateNotificationsByWS(data) {
            if (!this.notifications || this.notifications?.length === 0) {
                return;
            }
            let existNotifications = this.notifications.find(el => {
                return el.id === data.id;
            });
            if (existNotifications) {
                existNotifications.read = data?.read;
            }
        },
        deleteNotificationsByWS(data) {
            if (!this.notifications || this.notifications?.length === 0) {
                return;
            }
            let existNotificationsIndex = this.notifications.findIndex(el => {
                return el.id === data.id;
            });
            if (existNotificationsIndex) {
                this.notifications.splice(existNotificationsIndex, 1);
            }
        },

        addNotifications(data) {
            this.notifications.push(data);
        },
        markNotificationsAsRead(instanceId) {
            notificationsApi.markNotificationsAsRead('read', [instanceId]);
        },
        // async clearNotifications() {
        //   let res = await notificationsApi.deleteAllNotifications();
        //   if (res) {
        //     this.notifications = [];
        //   }
        // },

        async deleteNotifications(idArray) {
            const res = await notificationsApi.performAction('delete', idArray);
            if (res) {
                this.deleteNotificationsFromStore(idArray);
            }
        },
        deleteNotificationsFromStore(idArray) {
            idArray.map(id => {
                const notificationsIndx = this.notifications.findIndex(el => el.id === id);
                if (notificationsIndx >= 0) {
                    this.notifications.splice(notificationsIndx, 1);
                } else {
                    console.log('no notificationsIndx');
                }
            });
        },

        async markNotificationsAs(action, idArray) {
            const res = await notificationsApi.performAction(action, idArray);
            if (res) {
                this.changeKeyRead(action === 'read', idArray);
            }
        },

        changeKeyRead(bool, idArray) {
            idArray.map(id => {
                const notificationsIndx = this.notifications.findIndex(el => el.id === id);
                if (notificationsIndx >= 0) {
                    this.notifications[notificationsIndx].read = bool;
                } else {
                    console.log('no notificationsIndx');
                }
            });
        }
    }
});
