<script>
import { TransitionGroup, h } from 'vue';
import { state, methods } from './notifications.js';
export default {
    name: 'AlertComponent',
    components: {},
    props: {
        transitionGroupClasses: {
            default: () => {
                return {
                    enterActiveClassDelayed: 'transform ease-out duration-300 transition delay-300',
                    enterActiveClass: 'transform ease-out duration-300 transition',
                    enterFromClass: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4',
                    enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
                    leaveActiveClass: 'transition ease-in duration-500',
                    leaveFromClass: 'opacity-100',
                    leaveToClass: 'opacity-0',
                    moveClass: 'transition duration-500'
                };
            }
        }
    },
    render() {
        return h(
            TransitionGroup,
            {
                'enter-active-class': this.alertsByGroup.length > 1 ? this.transitionGroupClasses.enterActiveClassDelayed : this.transitionGroupClasses.enterActiveClass,
                'enter-from-class': this.transitionGroupClasses.enterFromClass,
                'enter-to-class': this.transitionGroupClasses.enterToClass,
                'leave-active-class': this.transitionGroupClasses.leaveActiveClass,
                'leave-from-class': this.transitionGroupClasses.leaveFromClass,
                'leave-to-class': this.transitionGroupClasses.leaveToClass,
                'move-class': this.transitionGroupClasses.moveClass
            },
            {
                default: () => {
                    return this?.$slots?.default({
                        alerts: this.sortedAlerts,
                        close: this.close
                    });
                }
            }
        );
    },
    data() {
        return {
            alerts: state.alerts,
            context: {
                group: '',
                position: 'top'
            }
        };
    },
    computed: {
        sortedAlerts() {
            // if (this.context.position === 'bottom')
            //   return [...this.alertsByGroup].slice(0, this.maxNotifications);
            return [...this.alertsByGroup].reverse();
            // .slice(0, this.maxNotifications);
        },
        alertsByGroup() {
            return this.alerts.filter(n => n.group === this.context.group);
        }
    },
    methods: {
        close(id) {
            this.$emit('close');
            methods.removeNotification(id);
        }
    }
};
</script>

<style lang="scss" scoped></style>
