const resizeDirective = app => {
    app.directive('resize', {
        mounted(el, binding) {
            initResize(el, binding);
        },
        updated(el, binding) {
            if (el._resizeObserver) {
                el._resizeObserver.disconnect();
            }
            initResize(el, binding);
        },
        unmounted(el) {
            if (el._resizeObserver) {
                el._resizeObserver.disconnect();
            }
        }
    });
};

function initResize(el, binding) {
    const callback = binding.value;

    if (typeof callback !== 'function') {
        console.warn('v-resize expects a function as its value.');
        return;
    }

    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            const { width, height } = entry.contentRect;

            requestAnimationFrame(() => {
                callback({ width, height });
            });
        }
    });

    resizeObserver.observe(el);
    el._resizeObserver = resizeObserver;
}

export default resizeDirective;
