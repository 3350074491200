import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
import { app } from '@/main';


// TODO add types when CustomCalendar migrate to ts
export function getSeconds(value: string, workHours = 24): number | null {
    if (!value) return null;
    const translatedDuration = translateDurationSymbols(value);

    let seconds: number = 0;

    const array = translatedDuration?.split(' ');

    array?.map((item: string) => {
        const indicator = item[item.length - 1]?.toLowerCase();
        const validValue = parseFloat(item);

        if (!validValue) {
            return;
        }

        if (indicator === 'w') {
            seconds += validValue * 7 * workHours * 60 * 60;
        }

        if (indicator === 'd') {
            seconds += validValue * workHours * 60 * 60;
        }
        if (indicator === 'h') {
            seconds += validValue * 60 * 60;
        }
        if (indicator === 'm') {
            seconds += validValue * 60;
        }
    });

    return seconds;
}

export function isWeekOff(weekends: number[], day: any): boolean {
    const currentWeekDay = dayjs(day).day(); // Get the day of the week (0=Sunday, 6=Saturday)

    return weekends.includes(currentWeekDay);
}

export function isDayOff(holidays: any, day: any): boolean {
    if (holidays.length) return false;

    if (!Array.isArray(holidays)) {
        holidays = [holidays];
    }

    return holidays.some((holiday: any) => dayjs(holiday).isSame(dayjs(day), 'day'));
}

export function formatDuration({ days, hours, minutes }: { days: any; hours: any; minutes: any }) {
    let result = '';

    const localeSymbols = {
        en: { weeks: 'w', days: 'd', hours: 'h', minutes: 'm' },
        uk: { weeks: 'т', days: 'д', hours: 'г', minutes: 'х' },
        pl: { weeks: 't', days: 'd', hours: 'g', minutes: 'm' }
    };

    const locale = (app?.config?.globalProperties?.$i18n?.locale as 'en' | 'uk' | 'pl') || 'en';

    const symbols = localeSymbols[locale];
    if (days >= 7) {
        const weeks = Math.floor(days / 7);
        days = days % 7;
        result += `${weeks}${symbols.weeks} `;
    }

    if (days) result += `${days}${symbols.days}`;
    if (hours) result += ` ${hours}${symbols.hours}`;
    if (minutes) result += ` ${minutes}${symbols.minutes}`;

    return result.trim();
}

export function countWorkMinutes(start: any, end: any): any {
    let count = 0;
    const startTime = dayjs(start).minute();
    const endTime = dayjs(end).minute();
    if (startTime < endTime) {
        count = endTime - startTime;
    } else if (endTime < startTime) {
        count = 0 - endTime + startTime - 60;
    }

    return count;
}

export function countWorkHours(hoursDayLoad: any, planningStartHour: any, planningEndHour: any, start: any, end: any): any {
    let count: any = 0;
    const startTime = dayjs(start).hour();
    const endTime = dayjs(end).hour();

    if (startTime < endTime) {
        count = endTime - startTime;
    } else if (endTime < startTime) {
        if (hoursDayLoad) {
            count = planningStartHour - endTime + startTime - planningEndHour;
        } else count = endTime + 24 - startTime;
    } else count = null;

    return count;
}

export function countWorkDays(weekends: number[], holidays: any, start: any, end: any) {
    let workdays = 0;
    const startDate = dayjs(start).startOf('day');
    const endDate = dayjs(end).startOf('day');

    for (let current = startDate; current.isSameOrBefore(endDate, 'day'); current = current.add(1, 'day')) {
        if (!isWeekOff(weekends, current)) {
            workdays += 1;
        }
    }

    if (holidays && holidays?.length) {
        holidays.forEach((holiday: any) => {
            const holidayDate = dayjs(holiday).startOf('day');
            if (holidayDate.isBetween(startDate, endDate, 'day', '[]')) {
                workdays -= 1;
            }
        });
    }

    return workdays;
}

export function isDateTimeValid(value: any, matchWorkTime: any, planningStartHour: any, planningEndHour: any) {
    const regex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4} (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

    if (!regex.test(value)) {
        return false;
    }

    const [datePart, timePart] = value.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hour, minute] = timePart.split(':').map(Number);

    const isLeapYear = (year: any) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    const daysInMonth = (year: any, month: any) => {
        const monthDays = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return monthDays[month - 1];
    };

    const isValidDay = day >= 1 && day <= daysInMonth(year, month);
    const isValidMonth = month >= 1 && month <= 12;
    const isValidYear = year >= 1991 && year <= 2200;
    const isValidHour = hour >= 0 && hour <= 23;
    const isValidMinute = minute >= 0 && minute <= 59;

    let isWithinWorkingHours = true;
    if (matchWorkTime) {
        isWithinWorkingHours = hour >= planningStartHour && hour <= planningEndHour;
        if (hour === planningEndHour) {
            isWithinWorkingHours = minute === 0;
        }
    }

    return isValidDay && isValidMonth && isValidYear && isValidHour && isValidMinute && isWithinWorkingHours;
}

export function addTimeExcludingWeekends(date: any, secondsToAdd: any) {
    let currentDate = date;

    while (secondsToAdd > 0) {
        // Check if it's a weekend (Saturday or Sunday)
        const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;

        if (!isWeekend) {
            // If matchWorkTime is true, respect working hours
            const currentHour = currentDate.hour();
            const currentMinutes = currentDate.minute();
            const endOfDayHour = 16;
            const startOfDayHour = 8;

            // Check if current time is after work hours
            if (currentHour >= endOfDayHour) {
                // Move to the next workday at the start of working hours
                currentDate = currentDate.add(1, 'day').hour(startOfDayHour).minute(0).second(0);
                continue;
            }

            // Calculate remaining work time in the current day
            const remainingWorkSeconds = ((endOfDayHour - currentHour) * 60 * 60) - currentMinutes * 60;

            // If the remaining time fits within the current workday
            if (secondsToAdd <= remainingWorkSeconds) {
                currentDate = currentDate.add(secondsToAdd, 'second');
                secondsToAdd = 0; // All time added
            } else {
                // Otherwise, subtract the remaining time and move to the next workday
                currentDate = currentDate.add(remainingWorkSeconds, 'second');
                secondsToAdd -= remainingWorkSeconds;
                // Move to the next workday
                currentDate = currentDate.add(1, 'day').hour(startOfDayHour).minute(0).second(0);
            }
        } else {
            // If it's a weekend, move to the next Monday at 8:00 AM
            if (currentDate.day() === 6) {
                // Saturday
                currentDate = currentDate.add(2, 'day').hour(8).minute(0).second(0);
            } else if (currentDate.day() === 0) {
                // Sunday
                currentDate = currentDate.add(1, 'day').hour(8).minute(0).second(0);
            }
        }
    }
    return currentDate;
}

export function getDurationFromSeconds(seconds: any, hoursPerDay: any) {
    const workHoursInSeconds = hoursPerDay * 60 * 60;
    const days = Math.floor(seconds / workHoursInSeconds);
    const hours = Math.floor((seconds % workHoursInSeconds) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return { days, hours, minutes };
}

export function isDurationValid(start: any, end: any, duration: any) {
    return start < end && end - start >= duration;
}

export function addSpaceAfterDurationSymbols(value: string) {
    const lastChar = value.slice(-1);
    const lastCharLowerCase = value.slice(-1).toLowerCase();

    const symbols = ['w', 'т', 't', 'd', 'д', 'h', 'г', 'g'];

    if (symbols.includes(lastCharLowerCase)) {
        if (value.slice(-2, -1) !== ' ') {
            return value.slice(0, -1) + lastChar + ' ';
        }
    }
    return value;
}

export function formatDurationForSeconds(value: string) {
    value = value.trim();

    value = value.replace(/\s+/g, ' ');

    return value.replace(/(\d+)([wdhm])/g, '$1$2 ').trim();
}

export function translateDurationSymbols(value: any) {
    const translations: { [key: string]: string } = {
        'т': 'w',  // Ukrainian 'н' to 'w' for weeks, if needed
        'д': 'd', // Ukrainian 'т' to 'd' for days
        'г': 'h', // Ukrainian 'г' to 'h' for hours
        'х': 'm', // Ukrainian 'х' to 'h' for minutes

        't': 'w',  // Polish 't' to 'w' for weeks, if needed
        'g': 'h', // Polish 'g' to 'h' for hours
    };

    return value.toLowerCase().replace(/[тгхдtg]/gi, (char: string) =>
        translations[char as keyof typeof translations] || char
    );
}

export function hoursToSeconds(hours: number): number {
    return hours * 60 * 60;
}

export function subtractTimeExcludingWeekends(date: any, secondsToSubtract: number, weekends: number[], holidays: number[]) {
    let currentDate = dayjs(date, 'DD/MM/YYYY HH:mm');
    while (secondsToSubtract > 0) {
        const isWeekend = isWeekOff(weekends, currentDate) || isDayOff(holidays, currentDate);
        const startOfDayHour = 8;
        const endOfDayHour = 16;
        if (!isWeekend) {
            const currentHour = currentDate.hour();


            // Check if current time is before work hours
            if (currentHour < startOfDayHour) {
                // Move to the previous workday at the end of working hours
                currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
                continue;
            }

            // Calculate worked seconds passed in the current day
            const workedSeconds = (currentHour - startOfDayHour) * 60 * 60;

            // If remaining time fits within the current workday
            if (secondsToSubtract <= workedSeconds) {
                currentDate = currentDate.subtract(secondsToSubtract, 'second');
                secondsToSubtract = 0; // All time subtracted
            } else {
                // Otherwise, subtract the available time and move to the previous workday
                currentDate = currentDate.subtract(workedSeconds, 'second');
                secondsToSubtract -= workedSeconds;
                // Move to the previous workday
                currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
            }
        } else {
            // If it's a weekend, move to the previous Friday at 16:00
            if (currentDate.day() === 6) {
                // Saturday
                currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
            } else if (currentDate.day() === 0) {
                // Sunday
                currentDate = currentDate.subtract(2, 'day').hour(endOfDayHour).minute(0).second(0);
            }
        }
    }
    return currentDate;
}
