export class AnalyticsService {
    static instance = null;
    constructor(token, dbName, analyticsUrl, countEvents, host, userStore) {
        if (AnalyticsService.instance) {
            return AnalyticsService.instance;
        }

        if (window.Worker) {
            if (!token) {
                return;
            }
            this.myWorker = new Worker('/analyticsWorker.js');
            this.myWorker.onmessage = function (e) {
                console.log('Message from worker:', e.data);
            };

            this.myWorker.onerror = function (error) {
                console.error('Worker error:', error);
            };
            this.userStore = userStore;

            this.postMessage('setConfig', {
                token: token,
                dbName: dbName,
                url: analyticsUrl,
                host: host,
                countEvents: countEvents
            });
        }
        AnalyticsService.instance = this;
    }

    postMessage(command, data) {
        if (!this.myWorker) {
            return;
        }
        this.myWorker.postMessage({
            command: command,
            event: JSON.parse(JSON.stringify(data)),
            accessToken: this.userStore.token
        });
    }

    addEvent(data) {
        if (data) {
            data.route = window.location.pathname;
            data.timestamp = Date.now();
            this.postMessage('addEvent', data);
        }
    }

    updateToken(token) {
        this.postMessage('updateAccessToken', token);
    }

    sendErrorLog(err, vm, info) {
        const trace = err ? err.stack : '';
        console.error(err, vm, info, trace);
        this.postMessage('addError', {
            route: window.location.pathname,
            timestamp: Date.now(),
            error: err.message,
            component: vm.$options.name || vm.$options._componentTag || 'not found',
            info: typeof info !== 'string' ? JSON.stringify(info) : info,
            trace: trace
        });
    }
}

export function addEvent(data) {
    if (!AnalyticsService.instance) {
        console.error('AnalyticsService is not initialized');
        return;
    }
    AnalyticsService.instance.addEvent(data);
}
