<template>
    <div
        class="multiselect__chip flex px-2.5 items-center justify-center gap-0"
        :class="[{ 'cursor-not-allowed': props.disabled }, { 'cursor-default	': props.readonly }, { 'cursor-default': !props.disabled && !props.readonly }]"
        :style="{ height: props.chipHeight }">
        <span
            @click="clickOnTag(props.item)"
            v-tooltip="textTruncated ? chipText : null"
            ref="textElement"
            class="multiselect__chip-text truncate"
            :style="{ maxWidth: `${maxChipTextWidth}px` }"
            >{{ chipText }}
        </span>

        <BaseIcon
            v-if="multiple && !(props.readonly || props.disabled)"
            name="clouse"
            @click="toggleSelection(props.item)"
            class="fill-[#C0C2CD] shrink-0"
            :class="[{ 'cursor-not-allowed': props.disabled }, { 'cursor-default	': props.readonly }, { 'cursor-pointer': !props.disabled && !props.readonly }]"
            :style="{ height: '24px' }" />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { inject } from 'vue';
import { InjectionKeyToggleSelection, InjectionKeyClickOnTag } from '../keys.js';
import BaseIcon from '@/components/ui/BaseIcon.vue';

const toggleSelection = inject(InjectionKeyToggleSelection);
const clickOnTag = inject(InjectionKeyClickOnTag);
const props = defineProps({
    item: {
        type: [Object, Array, String, Number, null],
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    labelProp: {
        type: String,
        default: 'label'
    },
    valueProp: {
        type: String,
        default: 'id'
    },
    object: {
        type: Boolean,
        default: false
    },
    maxChipTextWidth: Number,
    chipHeight: {
        type: String,
        default: '30px'
    }
});

const chipText = props.object ? props.item[props.labelProp] : props.item;

function isTextTruncated(element) {
    return element.scrollWidth > element.clientWidth;
}

const textElement = ref(null);
const textTruncated = ref(false);

onMounted(() => {
    if (textElement.value) {
        textTruncated.value = isTextTruncated(textElement.value);
    }
});
</script>

<style scoped>
.multiselect__chip {
    /* color: #2693ff; */
    border-radius: 6px;
    background: #eaebee;
}

.multiselect__chip-text {
    color: #131416;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
</style>
