<template>
    <div class="creator-reactions" ref="reactions" @mouseenter="onReactionsHover" @mouseleave="onReactionOut" @blur="closeListReactions">
        <div class="creator-reaction__wrapper z-10">
            <div class="creator-reaction__container">
                <div class="reaction-text__container flex items-center justify-center h-4 w-4">
                    <span v-if="userReaction" @click="selectReaction(userReaction)">
                        <svg width="24" height="24">
                            <use :href="getItemPath(currentReaction?.icon)">
                                <title>{{ currentReaction?.name }}</title>
                            </use>
                        </svg>
                    </span>
                    <button v-else class="reaction__btn flex items-center justify-center h-6 w-6" @click="selectReaction(reactions[0])">
                        <BaseIcon name="like" class="h-6 w-6"></BaseIcon>
                    </button>
                </div>
                <div class="reaction-list__container" :class="{ left: positionListReactions === 'left' }" v-if="showReactionsList">
                    <list-reactions ref="listreactions" :reactions="reactions" @select-reaction="selectReaction"></list-reactions>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';

import ListReactions from '@/components/reactions/ListReactions.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import svg from '@/assets/images/emojis.svg';

export default {
    name: 'CreatorReactions',
    components: {
        ListReactions,
        BaseIcon
    },
    props: {
        userReaction: {
            default: null
        },
        iconSize: {
            type: String,
            default: 'xs'
            // "ss", "xs(default)", "s", "m", "l", "xl",
        },
        positionListReactions: {
            type: String,
            default: 'right' // "right", "left"
        }
    },
    data() {
        return {
            hoverOnReactions: false,
            showReactionsList: false,
            reactions: [
                {
                    id: 'like',
                    name: 'Like',
                    icon: '1f44d',
                    unicode: '1f44d',
                    char: '👍',
                    description: 'like'
                },
                {
                    id: 'applaud',
                    name: 'Applaud',
                    icon: '1f44f',
                    unicode: '1f44f',
                    char: '👏',
                    description: 'applaud'
                },
                {
                    id: 'smile',
                    name: 'Smile',
                    icon: '1f601',
                    unicode: '1f601',
                    char: '😁',
                    // unicode: "&#x1F609",
                    description: 'smile'
                },
                {
                    id: 'surprise',
                    name: 'Surprise',
                    icon: '1f62e',
                    unicode: '1f62e',
                    char: '😮',
                    description: 'surprise'
                },
                {
                    id: 'unhappy',
                    name: 'Unhappy',
                    icon: '1f622',
                    unicode: '1f622',
                    char: '😢',
                    description: 'unhappy'
                },
                {
                    id: 'evil',
                    name: 'Evil',
                    icon: '1f621',
                    unicode: '1f621',
                    char: '😡',
                    description: 'evil'
                },
                {
                    id: 'disappointed',
                    name: 'Disappointed',
                    icon: '1f44e',
                    unicode: '1f44e',
                    char: '👎',
                    description: 'disappointed'
                }
            ],

            clickToShow: false,
            tmrclickToShow: null
        };
    },
    computed: {
        ...mapState(useUserStore, ['isMobile']),
        currentReaction() {
            if (!this.userReaction) return null;
            const currentReaction = this.reactions.find(({ icon }) => icon === this.userReaction.icon);
            if (currentReaction) return currentReaction;
            return this.userReaction.icon;
        }
    },
    methods: {
        getItemPath(code) {
            return `${svg}#${code}`;
        },
        onReactionsHover() {
            if (this.isMobile) return;
            this.hoverOnReactions = true;

            setTimeout(() => {
                if (this.hoverOnReactions) this.openListReactions();
            }, 250);
        },
        onReactionOut() {
            if (this.isMobile) return;
            this.hoverOnReactions = false;
            setTimeout(() => {
                if (!this.hoverOnReactions) this.closeListReactions();
            }, 400);
        },

        openListReactions() {
            this.clickToShow = true;
            this.tmrclickToShow = setTimeout(() => {
                this.clickToShow = false;
                clearTimeout(this.tmrclickToShow);
                this.tmrclickToShow = null;
            }, 500);
            this.showReactionsList = true;
        },
        closeListReactions() {
            this.showReactionsList = false;
        },

        selectReaction(reaction) {
            this.$emit('selectReaction', reaction);
            if (this.isMobile) {
                this.openListReactions();
            } else {
                this.closeListReactions();
            }
        },
        handleClickOutside(event) {
            if (!this.showReactionsList || this.clickToShow) return;

            const component = this.$refs.listreactions;

            if (component && component.$el && !component.$el.contains(event.target)) {
                this.closeListReactions();
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>

<style lang="scss" scoped>
.creator-reactions {
    & .reaction-text__container span {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &:hover .reaction-text__container span {
    }
    &:hover .reaction-text__icon {
    }
}
.creator-reaction__wrapper {
}
.creator-reaction__container {
    position: relative;
}
.reaction-text__container {
    display: flex;
    align-items: center;

    & span {
        color: var(--sp-primary);
        fill: var(--sp-primary);
    }
}

.reaction__btn {
    &:hover,
    &:focus {
        color: var(--sp-primary);
        fill: var(--sp-primary);
    }
}
.reaction__text {
}
.reaction-list__container {
    position: absolute;
    top: -8px;
    left: 0;
    z-index: 8;
    transform: translateY(-100%);

    &.left {
        left: auto;
        right: 0;
    }
}
.reaction-text__icon {
}
</style>
