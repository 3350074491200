import { defineStore } from 'pinia';
import menuApi from '../api/menu';
import { useUserStore } from './userStore';
import { useBadgesStore } from './badgesStore';
import dayjs from 'dayjs';
import { uuid } from 'vue-uuid';
import { groupBy } from '@/helpers';

export const useMenuStore = defineStore('menu', {
    state: () => ({
        menu: []
    }),
    getters: {
        menuVsBadges: state => {
            const badgesStore = useBadgesStore();
            let groupedTaskBadges = [];
            if (state.menu && badgesStore?.tasksBadges && badgesStore?.tasksBadges.length > 0) {
                groupedTaskBadges = groupBy(badgesStore.tasksBadges, 'instance_id');
                state.UpdateMenuItemByProperty('alias', 'tasks', 'pins', Object.keys(groupedTaskBadges).length);
                return state.menu;
            } else {
                state.UpdateMenuItemByProperty('alias', 'tasks', 'pins', 0);
                return state.menu;
            }
        },
        pinMenuItem: state => {
            if (state.menu && state.menu?.length > 0) {
                let taskMenuItem = state.findMenuItemByProperty('alias', 'tasks');
                return taskMenuItem ? taskMenuItem?.children : null;
            } else return null;
        },
        getMenuItemById: state => {
            if (state.menu.length > 0) {
                return itemId => {
                    let res = null;
                    function find(target, itemId) {
                        if (Array.isArray(target)) {
                            for (const element of target) {
                                if (Array.isArray(element)) {
                                    find(element, itemId);
                                }
                                if (element.id === itemId) {
                                    // console.log("find element", element);
                                    res = element;
                                }
                                find(element.children, itemId);
                            }
                        }
                    }
                    find(state.menu, itemId);
                    return res;
                };
            } else {
                console.log('no pages in store');
            }
        },
        getMenuItemByProperty: state => {
            if (state.menu.length > 0) {
                return (property, value) => {
                    let res = null;
                    function find(target, property, value) {
                        if (Array.isArray(target)) {
                            for (const element of target) {
                                if (Array.isArray(element)) {
                                    find(element, property, value);
                                }
                                if (property.includes('.')) {
                                    let propsArr = property.split('.');
                                    if (element[propsArr[0]]?.[propsArr[1]] === value) {
                                        // console.log("find element", element);
                                        res = element;
                                    }
                                } else {
                                    if (element[property] === value) {
                                        // console.log("find element", element);
                                        res = element;
                                    }
                                }

                                find(element.children, property, value);
                            }
                        }
                    }
                    find(state.menu, property, value);
                    return res;
                };
            } else {
                console.log('no pages in store');
            }
        }
    },
    actions: {
        initMenuStore() {
            this.menu = [];
            console.log('initMenuStore');
        },
        async getUserMenu() {
            const userStore = useUserStore();
            let res = await menuApi.getMenu(userStore.currentAccountId);
            if (res) {
                this.menu = res[0]?.menu;
            } else return null;
        },
        generateMenuItem(options) {
            const userStore = useUserStore();
            let elId = uuid.v4();
            let menuItem = {
                id: elId,
                href: `/page/${elId}`,
                name: 'Untitled',
                icon: 'fire',
                open: false,
                lastEdit: dayjs().unix(),
                hasContexMenu: true,
                disabled: false,
                parent_id: '',
                owner_id: userStore.user.id,
                account_id: userStore.currentAccountId,
                app_id: '',
                page_id: uuid.v4(),
                last_edit_time: dayjs().unix() * 1000
            };
            if (options) {
                for (let key in options) {
                    menuItem[key] = options[key];
                }
            }
            return menuItem;
        },
        async addMenuItem(parentId, item, onlyAddToStore) {
            async function addItem(target, parentItemId, newItem) {
                let result = false;

                function findItemById(target, id) {
                    for (const menu of target) {
                        for (const item of menu) {
                            if (item.id === id) {
                                return true;
                            }
                            if (item.children && findItemById([item.children], id)) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
                function add(target, parentItemId, newItem) {
                    if (Array.isArray(target)) {
                        for (const element of target) {
                            if (Array.isArray(element)) {
                                add(element, parentItemId, newItem);
                            }
                            if (element.id === parentItemId) {
                                if (Object.prototype.hasOwnProperty.call(element, 'children')) {
                                    newItem.order = element.children?.length * 10000 + 10000;
                                    element.children.push(newItem);
                                    result = true;
                                    break;
                                }
                                newItem.order = 10000;
                                element.children = [newItem];
                                result = true;
                                break;
                            }
                            add(element.children, parentItemId, newItem);
                        }
                    }
                }
                const itemExists = findItemById(target, newItem.id);
                if (!itemExists) {
                    add(target, parentItemId, newItem);
                }
                if (result && !onlyAddToStore) {
                    return await menuApi.onAddMenuItem(item);
                }
                return result;
            }
            return await addItem(this.menu, parentId, item);
        },

        addMenuMainItem(parentId, item) {
            let res = false;
            this.menu.forEach(element => {
                if (element[0].id == parentId) {
                    item.order = element?.length * 10000;
                    element.push(item);
                    res = true;
                }
            });
            if (res) {
                menuApi.onAddMenuItem(item);
            }
            return res;
        },
        updateMenuItem(id, props, value, onlyUpdateInStore) {
            function changeItem(target, itemId, property, newValue) {
                let result = false;
                function change(target, itemId, property, newValue) {
                    if (Array.isArray(target)) {
                        for (const element of target) {
                            if (Array.isArray(element)) {
                                change(element, itemId, property, newValue);
                            }
                            if (element.id === itemId) {
                                element[property] = newValue;
                                element.last_edit_time = dayjs().unix() * 1000;
                                if (!onlyUpdateInStore) menuApi.onUpdateMenuItem(element);
                                result = true;
                                break;
                            }
                            change(element.children, itemId, property, newValue);
                        }
                    }
                }
                change(target, itemId, property, newValue);
                return result;
            }
            return changeItem(this.menu, id, props, value);
        },
        deleteMenuItem(itemId, onlyRemoveFromStore) {
            function deleteItem(array, itemId) {
                let obj = null;
                function findItemById(target, id) {
                    for (const menu of target) {
                        for (const item of menu) {
                            if (item.id === id) {
                                return true;
                            }
                            if (item.children && findItemById([item.children], id)) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
                function del(target, itemId) {
                    const idx = target.findIndex(({ id }) => id == itemId);
                    if (idx >= 0) {
                        const [removeObj] = target.splice(idx, 1);
                        obj = removeObj;
                        return obj;
                    }
                    for (const element of target) {
                        if (Array.isArray(element)) {
                            del(element, itemId);
                        }
                        if (Object.prototype.hasOwnProperty.call(element, 'children')) {
                            del(element.children, itemId);
                        }
                    }
                }
                const itemExists = findItemById(array, itemId);
                if (itemExists) {
                    del(array, itemId);
                }

                return obj;
            }
            let res = deleteItem(this.menu, itemId);
            if (res && !onlyRemoveFromStore) {
                menuApi.onDeleteMenuItem(itemId);
            }
            return res;
        },
        findMenuItemByProperty(property, value) {
            function changeItem(target, property, newValue) {
                let result = false;
                function change(target, property, newValue) {
                    if (Array.isArray(target)) {
                        for (const element of target) {
                            if (Array.isArray(element)) {
                                change(element, property, newValue);
                            }
                            if (element[property] === value) {
                                result = element;
                                break;
                            }
                            if (element?.children) {
                                change(element.children, property, newValue);
                            }
                        }
                    }
                }
                change(target, property, newValue);
                return result;
            }
            return changeItem(this.menu, property, value);
        },
        UpdateMenuItemByProperty(property, value, newProp, newVal) {
            function changeItem(target, property, newValue, newProp, newVal) {
                let result = false;
                function change(target, property, newValue, newProp, newVal) {
                    if (Array.isArray(target)) {
                        for (const element of target) {
                            if (Array.isArray(element)) {
                                change(element, property, newValue, newProp, newVal);
                            }
                            if (element[property] === value) {
                                element[newProp] = newVal;
                                break;
                            }
                            if (element?.children) {
                                change(element.children, property, newValue, newProp, newVal);
                            }
                        }
                    }
                }
                change(target, property, newValue, newProp, newVal);
                return result;
            }
            return changeItem(this.menu, property, value, newProp, newVal);
        },
        addBlockToPage(pageId, newItem, prevBlockId) {
            function changeItem(target, itemId, newItem, prevBlockId) {
                let result = false;
                function change(target, itemId) {
                    if (Array.isArray(target)) {
                        for (const element of target) {
                            if (Array.isArray(element)) {
                                change(element, itemId);
                            }
                            if (element.id === itemId) {
                                console.log('update');
                                if (Object.prototype.hasOwnProperty.call(element, 'properties') && Array.isArray(element.properties.content)) {
                                    if (prevBlockId) {
                                        let elIndex = element.properties.content.indexOf(prevBlockId) + 1;
                                        element.properties.content.splice(elIndex, 0, newItem.id);
                                    } else {
                                        element.properties.content.push(newItem.id);
                                    }
                                } else {
                                    element.properties['content'] = [newItem.id];
                                }
                                element.last_edit_time = dayjs().unix() * 1000;
                                // if (property !== "opened") {
                                //   menuApi.onUpdateMenuItem(element);
                                // }
                                result = true;
                                break;
                            }
                            change(element.children, itemId, newItem, prevBlockId);
                        }
                    }
                }
                change(target, itemId, newItem, prevBlockId);
                return result;
            }
            return changeItem(this.menu, pageId, newItem, prevBlockId);
        }
    }
});
