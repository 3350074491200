import { defineStore } from 'pinia';
import { i18n } from '@/main';

export const useFormBuilderComponentStore = defineStore('formBuilderComponent', {
    state: () => ({
        storeAvailableComponents: [],
        createItemId: null,
        moveItemId: null,
        currentLayouts: {},
        layouts: [
            { width: 0, value: 'default', text: 'formBuilder.mobile', valueForView: 400 },
            { width: 640, value: 'sm', text: 'formBuilder.tablet', valueForView: 700 },
            { width: 1024, value: 'lg', text: 'formBuilder.desktop', valueForView: 1100 }
        ],
        currentScale: { text: '100%', value: 1 }
    }),
    getters: {
        getAvailableComponents() {
            return this.storeAvailableComponents;
        },
        getItem: state => id => {
            return state.storeAvailableComponents.find(element => element.id === id);
        },
        getCreatedItemId() {
            return this.createItemId;
        },
        getMoveItemId() {
            return this.moveItemId;
        },
        getLayouts() {
            return this.layouts.map(layout => {
                return { ...layout, text: i18n.global.t(layout.text) };
            });
        },
        getCurrentLayouts() {
            return this.currentLayouts;
        },
        getCurrentScale() {
            return this.currentScale;
        }
    },
    actions: {
        setCreateItemId(id) {
            this.createItemId = id;
        },
        setMoveItemId(id) {
            this.moveItemId = id;
        },
        setAvailableComponentsItem(element) {
            this.storeAvailableComponents.push(element);
        },
        setAvailableComponents(items) {
            this.storeAvailableComponents = items;
        },
        changeItem(item) {
            let index = this.storeAvailableComponents.findIndex(element => element.id === item.id);
            this.storeAvailableComponents[index] = item;
        },
        setCurrentLayout(layout, id) {
            this.currentLayouts[id] = layout;
        },
        setCurrentScale(scale) {
            this.currentScale = scale;
        }
    }
});
