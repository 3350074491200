<template>
    <time class="clock" v-if="diffInObg">
        <span class="clock__hour">{{ hours }}</span>
        <span class="clock__colon">:</span>
        <span class="clock__minutes">{{ minutes }}</span>
        <span v-if="showSeconds" class="clock__colon">:</span>
        <span v-if="showSeconds" class="clock__minutes inline-block min-w-[18px]">{{ seconds }}</span>
    </time>
</template>

<script setup lang="ts">
import { ref, onMounted, onUpdated, onUnmounted, computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line vue/no-dupe-keys
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

const props = defineProps({
    startTime: {
        type: Number,
        required: true
    },
    duration: {
        type: Number,
        default: 0
    },
    showSeconds: {
        type: Boolean,
        default: true
    }
});

const diffInObg = ref<any>(null);
const ticker = ref<Number | null>(null);
const dateObg = ref(dayjs.utc(Number(props.startTime)));

const hours = computed(() => {
    return diffInObg.value ? Number.parseInt(diffInObg.value?.asHours()) : 0;
});

const minutes = computed(() => {
    return diffInObg.value ? diffInObg.value.minutes().toString().padStart(2, '0') : 0;
});

const seconds = computed(() => {
    return diffInObg.value ? diffInObg.value.seconds().toString().padStart(2, '0') : 0;
});

const incTime = () => {
    const today = dayjs().utc();
    const diffInMs = today.diff(dateObg.value) + props.duration * 1000;
    diffInObg.value = dayjs.duration(diffInMs);
};

onMounted(() => {
    ticker.value = setInterval(incTime, 1000);
});

onUpdated(() => {
    dateObg.value = dayjs.utc(Number(props.startTime));
});

onUnmounted(() => {
    if (ticker.value) {
        return clearInterval(Number(ticker?.value));
    }
});
</script>
