export function addListenerForMentions(parent, func) {
    const mentions = parent?.querySelectorAll('.mention-user');
    if (mentions?.length) mentions.forEach(item => item.addEventListener('click', func));
    addAttributeForLinks(parent);
}
export function removeListenerForMentions(parent, func) {
    const mentions = parent?.querySelectorAll('.mention-user');
    if (mentions?.length) mentions.forEach(item => item.removeEventListener('click', func));
}

function addAttributeForLinks(parent) {
    const links = parent?.querySelectorAll('a');
    if (links?.length) {
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        });
    }
}
