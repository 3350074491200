import { defineStore } from 'pinia';

const useCursorLoaderStore = defineStore('cursorLoader', {
    state: () => ({
        isShow: false
    }),
    getters: {
        isCursorLoader() {
            return this.isShow;
        }
    },
    actions: {
        show() {
            this.isShow = true;
        },
        hide() {
            this.isShow = false;
        }
    }
});

export { useCursorLoaderStore };
