<template>
    <div>
        <BaseModalWindow v-if="getDialog?.title" :horizontalAlign="getDialog.horizontalAlign" :verticalAlign="getDialog.verticalAlign" @closeModalWindow="closeModal" class="z-100">
            <div class="flex flex-col justify-center py-6 px-8 relative">
                <span class="text-[30px] font-[700] p-2 max-w-[600px] leading-[36px] text-center">{{ getDialog.title }}</span>
                <div v-if="getDialog?.components?.length">
                    <div v-for="(component, index) in getDialog.components" :key="index" class="flex flex-col justify-center py-6 px-8 relative">
                        <component :is="component.component" v-bind="component?.props" v-on="component?.events" />
                    </div>
                </div>
                <div class="flex justify-around pt-2">
                    <BaseButton
                        v-for="(button, index) in getDialog.buttons"
                        :key="index"
                        class="px-2 mx-1"
                        style="min-width: 100px"
                        :variant="button?.variant || 'base'"
                        :color="button?.color"
                        :size="button?.size || 'md'"
                        @click="clickOnButton(button)"
                        :is-rounded="true"
                        :label="button.text">
                        <template v-if="button?.icon" v-slot:icon>
                            <BaseIcon :class="button?.classIcon" :name="button.icon" />
                        </template>
                    </BaseButton>
                </div>

                <BaseIcon class="absolute top-2 right-2 cursor-pointer h-[24px] text-gray-300" name="close" @click="closeModal" />
            </div>
        </BaseModalWindow>
    </div>
</template>

<script>
// import baseCard from "@/components/BaseCard.vue";
import { useMultiModalFormStore } from '@/stores/multiModalForm';
import { mapActions, mapState } from 'pinia';
import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ComponentsList from '@/components/dynamicForm/components/formBuilder/componentsList/ComponentsList.vue';

export default {
    name: 'BaseDialog',
    components: { ComponentsList, BaseModalWindow, BaseButton, BaseIcon },
    props: {},
    emits: ['update:modelValue', 'modalClose', 'changeView'],
    data() {
        return {};
    },
    computed: {
        ...mapState(useMultiModalFormStore, ['dialog']),
        getDialog() {
            return this.dialog;
        }
    },
    methods: {
        ...mapActions(useMultiModalFormStore, ['closeDialog']),
        closeModal() {
            this.closeDialog();
        },
        clickOnButton(button) {
            this.closeDialog(button.value);
        }
    },
    watch: {},
    created() {},
    updated() {},
    mounted() {}
};
</script>
