// import tooltipDirective from './Tooltip/';
import resize from './Resize';

// register all directives
const directives = app => {
    // tooltipDirective(app);
    resize(app);
};

export default directives;
