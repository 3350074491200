<template>
    <div v-if="modelValue" class="hidden">
        <teleport to="#modal-container">
            <div class="modal-container unselectable" :style="{ zIndex: zindex }">
                <div>
                    <div @click.stop="closeModal" class="modal-owerlay" :class="{ background: showOwerlay || isMobile }"></div>
                    <div class="modal-position-inner" :style="{ top: innerPositionTop, left: innerPositionLeft }" ref="contentWrapper" id="content-wrapper">
                        <div style="width: 1px; height: 1px"></div>
                        <div class="absolute h-100 w-100 flex flex-col justify-start align-start" style="top: 0px; left: 0px">
                            <div class="relative" style="top: 100%; pointer-events: auto">
                                <div class="flex relative justify-center flex-col-reverse" style="transform-origin: 0% top; left: 0px; top: 0px; opacity: 1">
                                    <div
                                        class="relative"
                                        style="background: #fff; border-radius: 5px; max-width: calc(100vw - 24px)"
                                        :style="{ width: conteinerWidth }"
                                        :class="{ 'border-shadow': showBorder }">
                                        <div class="flex flex-col h-full" style="min-width: 180px; max-width: calc(100vw - 24px); max-height: 100dvh">
                                            <div class="slot-wraper" ref="slot_wraper" :style="slotWraperStyle">
                                                <div v-if="showCloseBtn" @click.stop="closeModal">Close</div>
                                                <div class="flex modal-header justify-between" v-if="hasSlot('header')">
                                                    <div class="align-end">
                                                        <top-menu viewInModule="modal" v-if="showModalTopMenu" @change-view="onChangeView"></top-menu>
                                                        <!-- <ModalTopMenu
                                                            viewInModule="modal"
                                                            @change-view="onChangeView"
                                                            v-if="showModalTopMenu"
                                                        ></ModalTopMenu> -->
                                                    </div>
                                                    <slot name="header"></slot>
                                                </div>
                                                <div class="w-full" v-if="hasSlot('topbar')">
                                                    <slot name="topbar"></slot>
                                                </div>
                                                <slot></slot>
                                            </div>
                                            <!-- <footer style="flex-shrink: 0"></footer> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import TopMenu from '@/components/platformSlider/TopMenu.vue';
import { nextTick } from 'vue';

export default {
    name: 'BaseModal',
    components: {
        TopMenu
    },
    props: {
        modelValue: {
            type: Boolean
        },
        inputEvent: {
            type: Event
        },
        contentType: {
            type: String
        },
        contentWidth: {
            type: String
        },
        contentHeight: {
            type: String
        },
        fullHeight: {
            type: Boolean
        },
        top: {
            type: String
        },
        left: {
            type: Number,
            default: 0
        },
        offsetTop: {
            type: String,
            default: '0'
        },
        offsetLeft: {
            type: String,
            default: '0'
        },
        position: {
            type: String,
            default: 'left'
        },
        zindex: {
            type: [String, Number],
            default: '10'
        },
        showOwerlay: {
            type: Boolean,
            default: false
        },
        showBorder: {
            type: Boolean,
            default: true
        },
        name: {
            type: String
        },
        left2: {
            type: Number
        },
        overflow: {
            type: String
        },
        showCloseBtn: {
            type: Boolean,
            default: false
        },
        showModalTopMenu: {
            type: Boolean,
            default: true
        }
    },
    emits: ['update:modelValue', 'modalClose', 'changeView'],
    data() {
        return {
            documentWidth: 0,
            localEvent: null
        };
    },
    computed: {
        ...mapState(useUserStore, ['isMobile']),
        conteinerWidth() {
            if (this.isMobile) {
                return 0.9 * this.documentWidth + 'px';
            } else if (this.contentWidth) {
                return this.contentWidth;
            } else if (!this.inputEvent) {
                return `${this.documentWidth * 0.85}px`;
            }

            return '100%';
        },
        innerPositionTop() {
            if (this.fullHeight) return 0;
            let checkPosition = top => {
                let documentHeight = window.innerHeight;
                let contentHeight = this.isMobile || !this.contentHeight ? 0.8 * documentHeight : Number(this.contentHeight.substring(0, this.contentHeight.indexOf('p')));
                if (documentHeight - top < contentHeight) {
                    return documentHeight - contentHeight;
                } else return top;
            };
            if (this.inputEvent) {
                let targ = this.inputEvent.target.getBoundingClientRect();
                if (targ.y > 0) {
                    let offsetTop = targ.y + targ.height + 5 + Number(this.offsetTop);
                    return `${checkPosition(offsetTop)}px`;
                } else return `${this.inputEvent.clientY + 10}px`;
            } else if (this.top) {
                return this.top;
            } else return `10px`;
        },
        innerPositionLeft() {
            let checkPosition = (position, width) => {
                let documentSize = window.innerWidth;
                if (position < 0) return 20;
                if (documentSize > position + width) {
                    return position;
                }
                if (documentSize < position + width) {
                    return documentSize - width - 5;
                }
            };

            let contentWidth = this.isMobile || !this.contentWidth ? 0.95 * this.documentWidth : Number(this.contentWidth.substring(0, this.contentWidth.indexOf('p')));

            if (this.inputEvent) {
                let targ = this.inputEvent.target.getBoundingClientRect();
                if (targ.x > 0) {
                    if (this.position && this.position.length) {
                        switch (this.position) {
                            case 'left': {
                                let position = targ.x + 10 + Number(this.offsetLeft);
                                let documentWidth = window.innerWidth;

                                if (position + contentWidth > documentWidth) {
                                    return `${documentWidth - contentWidth}px`;
                                } else if (position < 0) {
                                    return `10px`;
                                } else {
                                    // console.log("position left", this.left);
                                    if (this.left > 0) {
                                        position = this.left;
                                    } else {
                                        position = position - this.left;
                                    }
                                    return `${position}px`;
                                }

                                // return `${targ.x - Number(this.contentWidth.substring(0, this.contentWidth.indexOf("p"))) - 10}px`;
                            }
                            case 'right': {
                                return `${targ.right + 10 + Number(this.offsetLeft)}px`;
                            }
                            case 'center': {
                                let position = targ.x + Number(this.offsetLeft) - (contentWidth - targ.width) / 2;
                                return `${checkPosition(position, contentWidth)}px`;
                            }
                            //targ.x - (this.contentWidth - targ.width) / 2
                            default:
                                break;
                        }
                    }
                    return `${targ.x + 10}px`;
                } else return `${this.inputEvent.clientX + 10}px`;
            } else {
                return `${(this.documentWidth - contentWidth) / 2}px`;
            }
        },
        slotWraperStyle() {
            return this.overflow ? { overflow: 'auto' } : '';
        }
    },
    methods: {
        showModal(event) {
            console.log('showModal', event);
        },
        closeModal() {
            this.$emit('modalClose');
            this.$emit('update:modelValue', false);
        },
        onKeyDown(evt) {
            if (evt.key === 'Escape') {
                this.closeModal();
                // evt.stopPropagation();
                evt.preventDefault();
            }
        },
        watchForWindowsResize() {
            this.documentWidth = window.innerWidth;
        },
        hasSlot(name) {
            return !!this.$slots[name];
        },
        onChangeView(viewId) {
            this.$emit('changeView', viewId);
        },

        async insertInScreen(modalWrapper) {
            await nextTick();
            let slotWrapper = this.$refs.slot_wraper;
            const slotGeometricData = slotWrapper?.getBoundingClientRect();
            const screenWidth = document.documentElement.clientWidth;
            const screenHeight = document.documentElement.clientHeight;
            // const modalWrapper = document.querySelector('.modal-position-inner');

            // console.log(slotGeometricData?.right > screenWidth)
            if (slotGeometricData?.right > screenWidth) {
                const leftPosition = screenWidth - slotGeometricData.width - 8 + 'px';
                modalWrapper.style.left = leftPosition;
            }

            // console.log(slotGeometricData?.left < 0)
            if (slotGeometricData?.left < 0) modalWrapper.style.left = '8px';

            console.log(slotGeometricData?.bottom > screenHeight);
            if (slotGeometricData?.bottom > screenHeight) {
                const topPosition = screenHeight - slotGeometricData.height - 8 + 'px';
                modalWrapper.style.top = topPosition;
            }
        }
    },
    watch: {},
    mounted() {
        this.documentWidth = window.innerWidth;
        window.addEventListener('resize', this.watchForWindowsResize);
        document.addEventListener('keydown', this.onKeyDown);
    },
    unmounted() {
        window.removeEventListener('resize', this.watchForWindowsResize);
        document.removeEventListener('keydown', this.onKeyDown);
        // window.removeEventListener('keydown', this.onKeyDown);
    }
};
</script>

<style scoped>
.modal-container {
    pointer-events: auto;
    position: relative;
}
.modal-owerlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100dvh;
}
.modal {
    pointer-events: auto;
    position: relative;
    z-index: 0;
}

.modal-inner {
    width: 100%;
    height: 100%;
    background-color: rgba(199, 200, 201, 0.692);
}

.modal__position-element {
    background-color: rgb(32, 165, 165);
    width: 300px;
    height: 400px;
}
.modal-position-inner {
    position: fixed;
    pointer-events: none;
}
.background {
    background: rgba(0, 0, 0, 0.5);
}

.border-shadow {
    border: var(--sp-border-width, 1px) solid var(--sp-border-color, #e5eaef);
    border-radius: var(--sp-border-radius, 7px);
    box-shadow: var(--sp-shadow, 0px 0px 5px 0px rgba(199, 199, 199, 0.46));
    /* overflow: hidden; */
}
.slot-wraper {
    flex-grow: 1;
    min-height: 0px;
    background-color: #ffffff;
    transform: translateZ(0px);
    z-index: 1;
    margin-right: 0px;
    margin-bottom: 0px;
    border-radius: var(--sp-border-radius, 7px);
}
</style>
