import { sendRequest } from '@/helpers';
// import users from "./devdata/users.json";

export default {
    async getUsers() {
        return await sendRequest(`/auth/users?order=name&simple=true`, 'get', '');
    },
    async getUsersWithParams(requestData) {
        return await sendRequest(`/auth/users/search`, 'post', requestData);
    },
    async getCurrentUser(id) {
        return await sendRequest(`/auth/user/${id}`, 'get', '');
    },
    async checkUser() {
        return await sendRequest(`/auth/`, 'get', '');
    },
    async getUserProfile(id) {
        return await sendRequest(`/auth/profile/${id}`, 'get', '');
    },
    async setPasswForUser(userId, data) {
        return await sendRequest(`/auth/set_pass/${userId}`, 'put', data);
    },
    async updateUser(id, data) {
        return await sendRequest(`/auth/user/${id}`, 'put', data);
    },
    async createUser(data) {
        return await sendRequest(`/auth/user`, 'post', data);
    },
    async fogotPassw(email) {
        return await sendRequest(`/api/recovery/${email}`, 'post', '');
    },
    async resetPassw(data) {
        return await sendRequest(`/api/change_password`, 'post', data);
    },
    async getAccounts() {
        let res = await sendRequest('/api/accounts', 'get', '');
        return res;
    },
    async getAvailibleUser(groups) {
        let requestData = {};
        let val = [];
        groups?.forEach(el => {
            val.push({ id: el });
        });
        requestData.filter = {
            operator: 'and',
            type: 'conjunction',
            conditions: [
                {
                    field: 'groups',
                    comparator: 'in list',
                    value: val,
                    type: 'condition'
                },
                {
                    field: 'is_active',
                    comparator: '=',
                    value: true,
                    type: 'condition'
                }
            ]
        };
        let url = `/auth/users/search`;
        return await sendRequest(url, 'post', requestData);
        // let res = await sendRequest("/auth/get_users", "get", "");
        // return users;
    },
    async findUserByName(name) {
        let requestData = { limit: 40 };
        requestData['filter'] = {
            operator: 'and',
            type: 'conjunction',
            conditions: [
                {
                    operator: 'or',
                    type: 'conjunction',
                    conditions: [
                        {
                            field: 'email',
                            comparator: 'like',
                            value: name,
                            type: 'condition'
                        },
                        {
                            field: 'name',
                            comparator: 'like',
                            value: name,
                            type: 'condition'
                        }
                    ]
                },
                {
                    field: 'is_active',
                    comparator: '=',
                    value: true,
                    type: 'condition'
                },
                {
                    field: 'hidden',
                    comparator: '=',
                    value: false,
                    type: 'condition'
                }
            ]
        };
        let url = `/auth/users/search`;
        return await sendRequest(url, 'post', requestData);
    },
    async findUserById(id) {
        let requestData = {};
        requestData['filter'] = {
            operator: 'and',
            type: 'conjunction',
            conditions: [
                {
                    field: 'id',
                    comparator: '=',
                    value: id,
                    type: 'condition'
                }
            ]
        };
        if (Array.isArray(id) && id.length > 0) {
            requestData.filter.condition.comparator = 'in list';
            requestData.filter.condition.value = id;
        }

        let url = `/auth/users/search`;
        let res = await sendRequest(url, 'post', requestData);
        return res;
    },
    async getUsersAndGroups(search) {
        return await sendRequest(`/auth/users_groups?search=${search}`, 'get', '');
    },
    async getGroups(searchParams) {
        const searchObjParam = {
            fields: {},
            filter: {
                conditions: [
                    {
                        comparator: 'like',
                        field: 'name',
                        id: '0bac73b3-fd97-42eb-8899-50a5ed9bc97e',
                        type: 'condition',
                        value: searchParams
                    }
                ],
                id: 'ae33548a-70c6-4d59-892f-c56890cc5171',
                operator: 'and',
                type: 'conjunction'
            }
        };
        return await sendRequest(`/auth/groups/search`, 'post', searchObjParam);
    },
    async getAllRoles() {
        return await sendRequest(`/auth/roles`, 'get', '');
    },

    async getBirthdaysUsers(startDate, endDate) {
        return await sendRequest(`/users/birthdays?date_start=${startDate}&date_end=${endDate}`, 'get', '');
    },
    async getNewHires() {
        return await sendRequest(`/api/users/last_hired`, 'get', '');
    },
    async getAnniversaries(startDate, endDate) {
        return await sendRequest(`/api/users/hired_anniversary?date_start=${startDate}&date_end=${endDate}`, 'get', '');
    },
    async getSyncStatus() {
        return await sendRequest(`/api/user/sync_status`, 'get', '');
    },
    async getOnlineUsers(limit) {
        if (limit) return await sendRequest(`/users/online?limit=${limit}`, 'get', '');
        return await sendRequest(`/users/online`, 'get', '');
    },
    async getCountOnlineUsers(offset, limit) {
        return await sendRequest(`/users/online_count?offset=${offset}&limit=${limit}`, 'get', '');
    }

    // ?filter=${btoa(JSON.stringify(filters))}
};
