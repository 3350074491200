import LocaleApi from '@/api/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import 'dayjs/locale/pl';
// import { createI18n } from "vue-i18n";

export let getUserLocale = () => {
    let availableLocale = process.env.VUE_APP_I18N_AVAILABLE_LOCALES.split(',');
    let saveadLocale = localStorage.getItem('lang');
    if (saveadLocale?.length > 0) {
        let shortLang = saveadLocale.split('_')[0];
        if (shortLang !== 'en') {
            setDateLocale(shortLang);
        }
        return shortLang;
    } else if (window.navigator.userLanguage || window.navigator.language) {
        let userLanguage = window.navigator.userLanguage || window.navigator.language;
        if (availableLocale.includes(userLanguage.slice(0, 2))) {
            return userLanguage.slice(0, 2);
        } else return process.env.VUE_APP_I18N_LOCALE;
    } else {
        return process.env.VUE_APP_I18N_LOCALE;
    }
};
export let loadRemoteMessages = async lang => {
    return await LocaleApi.getTranslate(lang);
};

import en from './locales/en.json';
import uk from './locales/uk.json';
import pl from './locales/pl.json';

export function loadLocaleMessages() {
    let locales = {
        en: en,
        uk: uk,
        pl: pl
    };
    // const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    // const messages = {}
    // locales.keys().forEach(key => {
    //   const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    //   if (matched && matched.length > 1) {
    //     const locale = matched[1]
    //     messages[locale] = locales(key).default
    //   }
    // })
    return locales;
}

// export default createI18n({
//   legacy: false,
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
export function setDateLocale(locale) {
    dayjs.locale(locale);
    //   switch (locale) {
    //   case 'ua':
    //     dayjs.locale('uk');
    //     break;
    //   default:
    //     dayjs.locale('en');
    //     break;
    // }
}
