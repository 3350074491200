import { reactive } from 'vue';

export const state = reactive({
    alerts: []
});

let count = 0;
const generateId = () => {
    count = count + 1;
    return count;
};
export const methods = {
    notify(alert, timeout) {
        alert.id = generateId();
        alert.group = alert.group || ''; // add default group
        state.alerts.push(alert);
        console.log('from Alert');
        setTimeout(() => {
            this.removeNotification(alert.id);
        }, timeout || 3000); // default time 3s
    },
    removeNotification(id) {
        state.alerts.splice(
            state.alerts.findIndex(n => n.id === id),
            1
        );
    }
};
