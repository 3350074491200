import { sendRequest } from '@/helpers';
import EntityApi from '@/api/entity';

export default {
    getDefaultEntityComponentCard(entityId) {
        switch (entityId) {
            case 'CRMActivities':
                return 'CRMActivities';
            case 'Task':
                return 'CreateShortTask';
            default:
                return 'EntityCard';
        }
    },
    async getBasedGenerations(entityId) {
        return await sendRequest(`/api/entity_based_generations/${entityId}`, 'get', '');
    },
    async getEntityBasedGenerations(entityId) {
        const result = [];
        const basedGenerations = await this.getBasedGenerations(entityId);
        if (basedGenerations) {
            for (const basedGeneration of basedGenerations) {
                const generationData = await EntityApi.getEntity(basedGeneration.based_entity_id);
                generationData['entityId'] = generationData.id;
                generationData['component'] = this.getDefaultEntityComponentCard(generationData.id);
                generationData['field_relationship'] = basedGeneration.field_relationship;
                result.push(generationData);
            }
        }
        console.log('the result of function getBasedEntities', result);
        return result;
    },
    async saveEntityBasedGenerations(entityId, subEntitiesData) {
        const currentData = await this.getBasedGenerations(entityId);
        const currentIds = [];
        // create new based generation
        for (const subData of subEntitiesData) {
            const existingObject = currentData.find(item => item.based_entity_id === subData.id);
            if (!existingObject) {
                const newData = { entity_id: entityId, based_entity_id: subData.id, field_relationship: {} };
                await sendRequest(`/api/entity_based_generation/`, 'post', newData);
            } else {
                currentIds.push(existingObject.id);
            }
        }
        // delete not existing
        for (const currentObj of currentData) {
            if (!currentIds.includes(currentObj.id)) {
                await sendRequest(`/api/entity_based_generation/${currentObj.id}`, 'delete', '');
            }
        }
    },
    async updateEntitiesFieldRelationship(entityId, subEntitiesData){
        const currentData = await this.getBasedGenerations(entityId);
        for (const subData of currentData) {
            const field_relationship = subEntitiesData[subData.based_entity_id]
            const newData = { entity_id: entityId, based_entity_id: subData.based_entity_id, field_relationship: field_relationship };
            await sendRequest(`/api/entity_based_generation/${subData.id}`, 'put', newData);
        }
        return true;
    },
    async getEntityBasedGenerationData(entityId, instanceId, subEntityId) {
        return await sendRequest(`/api/entity_based_generations/data/${entityId}/${instanceId}/${subEntityId}`, 'get', '');
    }
};
