<template>
    <div class="platform_list__container h-full w-full">
        <DynamicScroller ref="dScroller" :items="data" :min-item-size="32" class="scroller" @scroll-end="handleScrollEnd">
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.message ? item.message : item.name]" :data-index="index">
                    <slot name="item" :item="item" :index="index"></slot>
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { DynamicScroller, DynamicScrollerItem } from '@vendor/vue-virtual-scroller';
export default {
    name: 'PlatformList',
    props: {
        data: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    emits: ['scrollEnd', 'scrollStart'],
    components: {
        DynamicScroller,
        DynamicScrollerItem
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(useUserStore, ['isMobile'])
    },
    methods: {
        onScrollEnd() {
            console.log('onScrollEnd, nead load task');
        },
        scrollTo(index) {
            console.log('scrollTo', index);
            this.$refs.dScroller.scrollToItem(index);
        },
        handleScrollEnd() {
            this.$emit('scrollEnd');
        },
        handleScrollStart() {
            this.$emit('scrollStart');
        }
    },
    mounted() {},
    unmounted() {}
};
</script>

<style lang="scss">
.scroller {
    height: 100%;
}

.scroller::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scroller:hover::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scroller::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroller::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: grey;
}
</style>
