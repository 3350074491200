import { defineStore } from 'pinia';
import { hasProperty } from '@/helpers';
export const useRouterStore = defineStore('router', {
    state: () => ({
        routParams: {}
    }),
    getters: {},
    actions: {
        saveRouterParams() {
            localStorage.setItem('routerParams', JSON.stringify(this.routParams));
        },
        loadRoutParams() {
            const savedParams = localStorage.getItem('routerParams');
            if (savedParams) {
                this.routParams = JSON.parse(savedParams);
            }
        },
        addRoutParams(routName, params) {
            this.routParams[routName] = params;
            this.saveRouterParams();
        },
        getRoutParams(routName) {
            return hasProperty(this.routParams, routName) ? this.routParams[routName] : null;
        }
    }
});
