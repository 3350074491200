import { defineStore } from 'pinia';
import workflowApi from '../api/workflow';
import { useUserStore } from './userStore';

export const useWorkflowStore = defineStore('workflows', {
    state: () => ({
        workflows: null
    }),
    getters: {
        defaultWorkflow: state => {
            const userStore = useUserStore();
            const defaultWorkflowId = userStore.getEnvironmentByName('crm_default_workflow_id');
            if (defaultWorkflowId && state.workflows?.length > 0) {
                let res = state.workflows.find(el => el.id === defaultWorkflowId);
                return res ? res : null;
            } else return null;
        },
        newTaskStage() {
            if (this.defaultWorkflow) {
                return this.defaultWorkflow.stages.find(el => {
                    return el.status == 'active' && !el.deleted;
                });
            }
        },
        activeWorkflow() {
            if (this.workflows) {
                return this.workflows.filter(el => {
                    return el.bitrix_id === null;
                });
            }
        }
    },
    actions: {
        async initWorkflow() {
            const userStore = useUserStore();
            const defaultWorkflowId = userStore.getEnvironmentByName('crm_default_workflow_id');
            if (defaultWorkflowId) {
                this.loadWorkflow(defaultWorkflowId);
            }
        },
        async getWorkflowById(id) {
            if (this.workflows && this.workflows?.length > 1 && id) {
                let WF = this.workflows.find(el => el.id === id);
                if (WF) return WF;
                else return await this.loadWorkflow(id);
            } else {
                const loadedWF = await this.loadWorkflow(id);
                if (loadedWF) {
                    return loadedWF;
                }
            }
        },
        async loadWorkflow(workflowId) {
            let res;
            if (workflowId) {
                res = await workflowApi.getWorkflow(workflowId);
            }
            if (res) {
                if (Array.isArray(this.workflows)) {
                    this.workflows.push(res);
                } else {
                    this.workflows = [res];
                }
                return res;
            } else {
                console.warn('Cant load workflowId');
                return null;
            }
        },
        getComplatedStage(id) {
            return this.getWorkflowById(id).stages.find(el => {
                return el.status === 'completed' && el.deleted === false;
            });
        }
    }
});
