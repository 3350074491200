<template>
    <div class="duration-input text-sm font-normal leading-6 relative">
        <label
            class="time__label flex items-center text-gray5 border rounded-xl py-2 pr-3 pl-4"
            :class="[{ 'fill-danger border-danger': !valid }, { 'fill-gray2 border-gray3': valid }]">
            <BaseIcon class="time__icon w-6 h-6 shrink-0" :class="[{ 'fill-danger': !valid }, { 'fill-gray2': valid }]" name="time-quarter-pass"></BaseIcon>
            <input class="time__input ml-1 w-[60px]" type="text" :value="value" @change="setDuration" v-mask="'##:##'" placeholder="HH:mm" ref="timeInput" />
        </label>
        <span class="time__text text-xs ml-1 text-danger absolute bottom-[-32px] left-0" v-if="!valid && value">{{ $t('labels.theTimeIsIncorrect') }}</span>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon';

import { mask } from 'vue-the-mask';

export default {
    name: 'DurationInput',
    components: {
        BaseIcon
    },
    props: {
        value: {
            type: String
        },
        valid: {
            type: Boolean
        }
    },
    emits: ['setDuration'],
    methods: {
        setDuration(e) {
            this.$emit('setDuration', e.target.value);
        },
        focusOnInput() {
            this.$refs.timeInput.focus();
        }
    },
    directives: { mask },
    created() {}
};
</script>

<style lang="scss" scoped></style>
