<template>
    <div class="profile">
        <base-avatar
            class="profile__avatar"
            :widthPic="widthPic"
            :heightPic="heightPic"
            :rounded="rounded"
            :borderColor="borderColor"
            :user="user"
            :showInformation="false"
            @click="toggleProfileMenu"
            v-tooltip:bottom-end="$t('tooltip.yourProfile')"></base-avatar>
        <ContextMenu v-if="profileMenu.visible" :event="profileMenu.position" :items="profileMenuFilteread" @changedValue="handleChange" @closeContextMenu="handleClose" />
    </div>
</template>

<script>
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import BaseAvatar from '@/components/ui/BaseAvatar.vue';

import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { useBadgesStore } from '@/stores/badgesStore';

export default {
    name: 'profile-menu',
    components: {
        ContextMenu,
        BaseAvatar
    },
    props: {
        widthPic: {
            type: Number,
            default: 36
        },
        heightPic: {
            type: Number,
            default: 36
        },
        rounded: {
            type: Number,
            default: 999
        },
        borderColor: {
            type: String
        }
    },
    data() {
        return {
            profileMenu: {
                id: 'profile_menu',
                visible: false
            }
        };
    },
    computed: {
        ...mapState(useUserStore, ['user', 'isAdmin']),
        profileMenuFilteread() {
            if (this.isAdmin) {
                return this.profileMenuList;
            } else {
                return this.profileMenuList.filter(el => el.access === 'user');
            }
        },
        profileMenuList() {
            return [
                {
                    id: 'profile',
                    label: this.$t('buttons.settings'),
                    path: '/account',
                    access: 'user',
                    type: 'button',
                    icon: 'user-edit-01'
                },
                {
                    id: 'entitys',
                    label: this.$t('lables.entitys'),
                    path: '/manage/entitys',
                    access: 'admin',
                    type: 'button',
                    icon: 'workflow-circle-03'
                },
                // {
                //   id: "budgeInfo",
                //   label: "Badge debug",
                //   access: "user",
                //   type: "button",
                // },
                {
                    id: 'loyalty',
                    label: this.$t('menu.loyaltyProgram'),
                    path: '/loyalty/store',
                    access: 'user',
                    type: 'button',
                    icon: 'loyalty-card'
                },
                {
                    id: 'logout',
                    label: this.$t('buttons.logout'),
                    path: '/logout',
                    access: 'user',
                    borderTop: true,
                    type: 'button',
                    icon: 'logout-02'
                }
            ];
        }
    },
    methods: {
        ...mapActions(useUserStore, ['logout']),
        ...mapActions(useBadgesStore, ['printBadgeInfo']),
        toggleProfileMenu(event) {
            this.profileMenu.position = event;
            this.profileMenu.visible = !this.profileMenu.visible;
            if (this.profileMenu.visible) {
                this.$analytics.addEvent({
                    category: 'Global top menu',
                    action: 'click',
                    label: 'Open profile menu',
                    entity: '',
                    instance: 'profile_menu'
                });
            }
        },
        handleClose() {
            this.profileMenu.visible = false;
            this.profileMenu.position = null;
            console.log('handleClose');
        },
        handleChange(menuEl) {
            switch (menuEl.id) {
                case 'profile':
                    this.$router.push('/account');
                    break;
                case 'entitys':
                    this.$router.push('/manage/entitys');
                    break;
                case 'loyalty':
                    this.$router.push('/loyalty/store');
                    break;
                case 'logout':
                    this.logout();
                    break;
                case 'budgeInfo':
                    this.printBadgeInfo();
                    break;

                default:
                    break;
            }
            this.handleClose();
        }
    }
};
</script>

<style lang="scss" scoped>
.profile {
    position: relative;
}

.profile__menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    font-size: 14px;
    text-align: left;
    /* display: flex; */
    /* color: #ffffff; */
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 12px;
    z-index: 14;
}

.profile__menu::before,
.profile__menu::after {
    content: '';
    border: solid transparent;
    position: absolute;
    top: -20px;
    right: 2px;
    transform: translateX(-50%);
}

.profile__menu::before {
    right: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-width: 10px;
}

.profile__menu::after {
    top: -18px;
    border-bottom-color: #ffffff;
    border-width: 9px;
}
</style>
