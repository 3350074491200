<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="list-reactions__wrapper p-1 border border-gray3 rounded-3xl bg-[#ffffff]">
        <div class="list-reactions__container">
            <ul class="list flex items-center list-none gap-2.5">
                <li class="list__item cursor" v-for="item in reactions" :key="item.id" @click.stop="selectReaction(item)">
                    <span class="item__icon">
                        <!-- {{ item.icon }} -->
                        <svg width="24" height="24">
                            <use :href="getItemPath(item.icon)">
                                <title>{{ item.name }}</title>
                            </use>
                        </svg>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import svg from '@/assets/images/emojis.svg';

export default {
    name: 'ListReactions',
    components: {},
    props: {
        reactions: {
            type: Array,
            default: () => [
                {
                    id: 'like',
                    name: 'Like',
                    icon: '1f44d',
                    unicode: '1f44d',
                    char: '👍',
                    description: 'like'
                },
                {
                    id: 'applaud',
                    name: 'Applaud',
                    icon: '1f44f',
                    unicode: '1f44f',
                    char: '👏',
                    description: 'applaud'
                },
                {
                    id: 'smile',
                    name: 'Smile',
                    icon: '1f601',
                    unicode: '1f601',
                    char: '😁',
                    // unicode: "&#x1F609",
                    description: 'smile'
                },
                {
                    id: 'surprise',
                    name: 'Surprise',
                    icon: '1f62e',
                    unicode: '1f62e',
                    char: '😮',
                    description: 'surprise'
                },
                {
                    id: 'unhappy',
                    name: 'Unhappy',
                    icon: '1f622',
                    unicode: '1f622',
                    char: '😢',
                    description: 'unhappy'
                },
                {
                    id: 'evil',
                    name: 'Evil',
                    icon: '1f621',
                    unicode: '1f621',
                    char: '😡',
                    description: 'evil'
                },
                {
                    id: 'disappointed',
                    name: 'Disappointed',
                    icon: '1f44e',
                    unicode: '1f44e',
                    char: '👎',
                    description: 'disappointed'
                }
            ]
        }
    },
    emits: ['selectReaction'],
    methods: {
        getItemPath(code) {
            return `${svg}#${code}`;
        },
        selectReaction(reaction) {
            this.$emit('selectReaction', reaction);
        }
    }
};
</script>

<style lang="scss" scoped>
.list-reactions__container {
    box-shadow: 0px 10px 10px 2px rgba(104, 108, 111, 0.06);
}
.list {
}
.list__item {
    /* font-size: 24px; */
    transition: scale 250ms ease-in-out;
    transform-origin: bottom center;

    &:hover {
        scale: 1.1;
    }
}
</style>
