import { defineStore } from 'pinia';
// import { groupBy } from "@/helpers";
import badgesApi from '../api/badges';
import { useUserStore } from './userStore';
import { deepEqual } from '@/helpers';
export const useBadgesStore = defineStore('badges', {
    state: () => ({
        badges: null
    }),

    getters: {
        tasksBadges: state => {
            const userStore = useUserStore();
            let taskEntityId = userStore.getEnvironmentByName('crm_task_id');
            if (state?.badges) {
                return state.badges.filter(el => el.entity_id === taskEntityId);
            } else return null;
        },
        entityBadges: state => {
            return entityId => {
                if (state?.badges) {
                    return state.badges.filter(el => el.entity_id === entityId);
                } else return [];
            };
        }
    },
    actions: {
        async getBadges() {
            this.badges = await badgesApi.getBadges();
        },
        insertBadgeByWS(data) {
            console.log('insertBadgeByWS', data);
            if (!this.badges || !Array.isArray(this.badges)) {
                this.badges = [];
            }
            let existBadge = this.badges.find(el => {
                return deepEqual(el, data);
            });
            if (existBadge) {
                return;
            } else {
                this.badges.push(data);
            }
        },
        updateBadgeByWS(data) {
            console.warn('updateBadgeByWS', data);
        },
        deleteBadgeByWS(data) {
            if (!this.badges || this.badges?.length === 0) {
                return;
            }
            let existBadge = this.badges.filter(el => {
                return el.entity_id === data.entity_id && el.instance_id === data.instance_id && el.type === data.type;
            });
            if (existBadge?.length > 0) {
                existBadge.forEach(element => {
                    this.deleteBadge(element.entity_id, element.instance_id, element.type, false);
                });
            }
        },
        deleteBadge(entityId, instanceId, type = ['high', 'low'], sendReq = true) {
            let searchEl = {
                entity_id: entityId,
                instance_id: instanceId,
                type: type
            };
            let findBadge = (el, badgeObg) => {
                return el.entity_id === badgeObg.entity_id && el.instance_id === badgeObg.instance_id && el.type?.includes(badgeObg.type);
            };
            let badgeForDelete = this.badges?.filter(el => {
                return findBadge(searchEl, el, type);
            });
            if (this.badges && this.badges?.length > 0 && badgeForDelete?.length > 0) {
                this.badges = this.badges.filter(el => {
                    return !findBadge(searchEl, el, type);
                });
            }
            if (sendReq) {
                badgesApi.deleteBadge(entityId, instanceId, type);
            }
        },
        async clearBadgeForEntity(entityId, type) {
            let res = await badgesApi.clearBadgeForEntity(entityId, type);
            if (res) {
                let badgeForDell = this.badges.filter(el => el.entity_id === entityId && el.type === type);
                this.badges = this.badges.filter(function (badge) {
                    return (
                        badgeForDell.filter(function (badgeForDell_el) {
                            return badgeForDell_el.entity_id === badge.entity_id && badgeForDell_el.type === badge.type;
                        }).length === 0
                    );
                });
            }
        },
        printBadgeInfo() {
            console.log(this.badges);
        }
    }
});

// ca6aa766-843e-49d4-8983-28e6fc50d61d
//  high
