<template>
    <OptionCheck
        v-for="(item, index) in props.data.items"
        :key="item"
        :index="index"
        :data="{
            id: props.data.id,
            type: props.data.type,
            label: item.label,
            icon: item?.icon,
            value: props.data.value === item.id,
            readonly: props.data.readonly,
            reverseItemRow: props.data?.reverseItemRow
        }" />
</template>

<script setup>
import OptionCheck from './OptionCheck.vue';
const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    timeParentCreatedForSubMenu: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    isTop: Boolean
});
</script>
