export function isDefaultEntity(entityId) {
  const defaultEntityList = [
    'CRMContact',
    'CRMLead',
    'CRMAccount',
    'CRMDeal',
    'CRMActivities',
    'SmartUser',
  ]
  return defaultEntityList.includes(entityId);
}
