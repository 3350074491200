<template>
    <BaseButton v-if="!activeTrackingTask?.started_date" color="third" variant="text" size="sm" @click="showTrackingModal" v-tooltip:bottom="$t('tooltip.tasksTrackingList')">
        <template #icon>
            <BaseIcon name="Play" class="h-[24px] w-[24px]"></BaseIcon>
        </template>
    </BaseButton>
    <BaseButton v-else color="third" variant="text" size="sm" :rounded="false" @click="showTrackingModal" v-tooltip:bottom="$t('tooltip.tasksTrackingList')">
        <template #icon>
            <div class="flex items-center gap-1 px-1">
                <BaseIcon name="Pause" class="h-[24px] w-[24px]"></BaseIcon
                ><BaseTimer class="text-[#ffffff] font-medium" v-if="activeTrackingTask" :startTime="activeTrackingTask.started_date" :duration="activeTrackingTask.duration" />
            </div>
        </template>
    </BaseButton>

    <FlyoutWrapper v-if="showTrakingModal" :event="showTrakingModalEvent" @closeFlyoutWrapper="closeTrackingModal">
        <tracking-form
            :trackingList="trackingListExceptActive"
            :activeTrackingTask="activeTrackingTask"
            @startTrackTime="onstartTrackTime"
            @stopTrackTime="onStopTrackTime"
            @pauseTrackTime="onpauseTrackTime"
            @close-modal="closeTrackingModal"></tracking-form>
    </FlyoutWrapper>
    <FlyoutWrapper v-if="showFinishTrakingModal && activeEntity" :event="finishTrakingModalEvent" @closeFlyoutWrapper="closeFinishTrackingModal" showFullSlot>
        <finish-tracking-form :item="activeEntity" @close-modal="closeFinishTrackingModal" @submit="finishTaskTracking"></finish-tracking-form>
    </FlyoutWrapper>
</template>

<script>
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import BaseTimer from '@/components/ui/BaseTimer.vue';
import TrackingForm from './TrackingTopBarForm.vue';
import FinishTrackingForm from './TaskCardTrackingForm.vue';
import FlyoutWrapper from '@/components/ui/FlyoutWrapper.vue';

import { mapState, mapActions } from 'pinia';
import { useTrackingStore } from '@/stores/trackingStore';
import dayjs from 'dayjs';
import { addEvent } from '../../../../../analytics/AnalyticsService';
export default {
    name: 'TrackingTopBar',
    components: {
        BaseIcon,
        TrackingForm,
        FinishTrackingForm,
        BaseTimer,
        BaseButton,
        FlyoutWrapper
    },
    props: {},

    data() {
        return {
            showTrakingModal: false,
            showTrakingModalEvent: null,
            showFinishTrakingModal: false,
            finishTrakingModalEvent: null,
            activeEntity: null
        };
    },
    computed: {
        ...mapState(useTrackingStore, ['trackingList', 'activeTrackingTask']),
        trackingListExceptActive() {
            if (this.activeTrackingTask) {
                return this.trackingList.filter(item => item.id !== this.activeTrackingTask.id);
            } else return this.trackingList;
        }
    },
    methods: {
        ...mapActions(useTrackingStore, [
            'getTrackingTask',
            'startTrackTime',
            'pauseTrackTime',
            'stopTrackTime',
            'insertTrackInStore',
            'updateTrackInStore',
            'updateTrackTime',
            'deleteTrackFromStore'
        ]),
        showTrackingModal(event) {
            this.showTrakingModalEvent = event;
            this.showTrakingModal = true;
            addEvent({
                category: 'Navigation Panel',
                action: 'lmbc',
                label: 'Open Tracking card as modal',
                entity: 'task',
                instance: ''
            });
        },
        closeTrackingModal() {
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Close Tracking card modal',
                entity: 'task',
                instance: ''
            });
            this.showTrakingModalEvent = null;
            this.showTrakingModal = false;
        },
        onStopTrackTime(tracking, event) {
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Stop tracking in modal',
                entity: 'task',
                instance: tracking?.id
            });

            this.finishTrakingModalEvent = event;
            if (tracking?.started_date) {
                this.pauseTrackTime(tracking);
                tracking.duration = tracking.duration + (dayjs().unix() - tracking.started_date / 1000);
            }
            this.activeEntity = tracking;
            this.showFinishTrakingModal = true;
        },
        onstartTrackTime(tracking) {
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Start tracking in modal',
                entity: 'task',
                instance: ''
            });
            this.startTrackTime(tracking);
        },
        onpauseTrackTime(tracking) {
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Pause tracking in modal',
                entity: 'task',
                instance: ''
            });
            this.pauseTrackTime(tracking);
        },
        closeFinishTrackingModal() {
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Close finish tracking in modal',
                entity: 'task',
                instance: ''
            });
            this.finishTrakingModalEvent = null;
            this.showFinishTrakingModal = false;
        },
        finishTaskTracking(data) {
            this.closeFinishTrackingModal();
            this.stopTrackTime(this.activeEntity);

            let storeDate = { ...this.activeEntity };
            // storeDate.date = data.date;
            // storeDate.duration = data.duration;
            storeDate.comment = data.comment;
            // this.stopTrackTime(storeDate);
            this.updateTrackTime({
                id: this.activeEntity?.id,
                comment: data.comment
            });
            addEvent({
                category: 'Tracking card',
                action: 'lmbc',
                label: 'Finish tracking in modal',
                entity: 'task',
                instance: this.activeEntity?.id
            });
            this.activeEntity = null;
        }
    },
    sockets: {
        time_tracking(payload) {
            const { event, data } = payload;
            if (event === 'insert') {
                if (data?.finished) return;
                this.insertTrackInStore(data);
            } else if (event === 'update') {
                this.updateTrackInStore(data);
            } else if (event === 'delete') {
                this.deleteTrackFromStore(data);
            } else {
                console.log('doesn`t has valid event:', payload);
            }
        },
        task(payload) {
            const { event, data } = payload;
            if (event === 'update') {
                let existTraking = this.trackingList.find(t => data.id === t.instance_id);
                if (existTraking) {
                    this.updateTrackInStore({ ...existTraking, name: data.name });
                }
            }
        }
    },
    created() {
        this.getTrackingTask();
    }
};
</script>
<style lang="scss" scoped>
.task-time-tracker-wrap {
    .task-time-tracker-button {
        // width: 24px;
        height: 24px;
        border: 0px;
        // border: 1px solid #999;
        color: #999;
        background-color: transparent;
        // border-radius: 50%;
        .tracking-icon {
            height: 15px;
        }
        .tracking-icon__pause {
            fill: none;
            stroke: #999;
        }
        .timer-wrap {
            width: 50px;
        }
    }
}

.tracking-icon__start {
}
</style>
