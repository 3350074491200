<template>
    <div class="loader__wrapper" :class="wrapperClass">
        <div class="loader" :class="size">
            <div class="default" v-if="variant === 'default'">
                <svg viewBox="25 25 50 50">
                    <circle cx="50" cy="50" r="20"></circle>
                </svg>
            </div>
            <div class="secondary" v-if="variant === 'secondary'"></div>
            <div class="circle-dot" v-if="variant === 'circleDot'">
                <svg viewBox="0 0 50 50">
                    <circle class="ring" cx="25" cy="25" r="20"></circle>
                    <circle class="ball" cx="25" cy="5" r="3.5"></circle>
                </svg>
            </div>
            <div class="pendulum-dots" v-if="variant === 'pendulumDots'">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="dots" v-if="variant === 'dots'">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="jump-dots" v-if="variant === 'jumpDots'">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    variant?: string;
    size?: string;
    position?: string;
}>();

const { variant = 'default', size = 'medium', position = 'component' } = props;

const wrapperClass = computed(() => (position === 'global' ? 'fullpage' : ''));
</script>


<style scoped>
.loader {
    text-align: center;
    font-weight: 300;
}
.loader.ss {
    font-size: 3px;
}
.loader.xsmall {
    font-size: 6px;
}
.loader.small {
    font-size: 14px;
}
.loader.medium {
    font-size: 18px;
}
.loader.large {
    font-size: 24px;
}

.default svg {
    width: 3.75em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
}

.default circle {
    fill: none;
    stroke: var(--sp-primary, #1e4db7);
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}

/* --------- secondary -------- */
.secondary {
    border: 3px solid hsla(204, 67%, 63%, 0.2);
    border-top-color: var(--accent-color);
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* -------- circle-dot ---------- */
.circle-dot svg {
    width: 3.75em;
    animation: 1.5s spin ease infinite;
}

.circle-dot .ring {
    fill: none;
    stroke: hsla(204, 67%, 63%, 0.2);
    stroke-width: 2;
}

.circle-dot .ball {
    fill: var(--accent-color);
    stroke: none;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* ---------- ball pendulum ---------- */
.pendulum-dots {
    width: 4em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.pendulum-dots div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--accent-color);
}

.pendulum-dots div:nth-of-type(1) {
    transform: translateX(-100%);
    animation: left-swing 0.5s ease-in alternate infinite;
}

.pendulum-dots div:nth-of-type(3) {
    transform: translateX(-95%);
    animation: right-swing 0.5s ease-out alternate infinite;
}

@keyframes left-swing {
    50%,
    100% {
        transform: translateX(95%);
    }
}

@keyframes right-swing {
    50% {
        transform: translateX(-95%);
    }
    100% {
        transform: translateX(100%);
    }
}

/* -------- dots ---------- */
.dots {
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.dots div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--accent-color);
    animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
    animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
    animation-delay: -0.2s;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* ---------- jump dots ---------- */
.jump-dots {
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.jump-dots div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--accent-color);
    transform: translateY(-100%);
    animation: wave 0.8s ease-in-out alternate infinite;
}

.jump-dots div:nth-of-type(1) {
    animation-delay: -0.4s;
}

.jump-dots div:nth-of-type(2) {
    animation-delay: -0.2s;
}
.fullpage {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 90%; */
    z-index: 100;
    /* width: 100%; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@keyframes wave {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(100%);
    }
}
</style>
