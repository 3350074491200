// import menuItem from "./devdata/menu.json";
import { sendRequest } from '@/helpers';

export default {
    async getMenu(account_id = '') {
        let res = await sendRequest(`/api/user_menu/${account_id}`, 'get', '');
        return res;
    },
    async getItemById(itemId) {
        return await sendRequest(`/api/menu_item/${itemId}`, 'get', '');
    },
    async onAddMenuItem(item) {
        return await sendRequest('/api/menu_item/', 'post', item);
    },
    async onUpdateMenuItem(item) {
        let el = { ...item };
        delete el.children;
        return await sendRequest(`/api/menu_item/${item.id}`, 'put', el);
    },
    async onDeleteMenuItem(itemId) {
        return await sendRequest(`/api/menu_item/${itemId}`, 'delete', '');
    }
};
