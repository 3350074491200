import { defineStore } from 'pinia';
import { useUserStore } from './userStore';
import { useTasksStore } from './tasksStore';

import trackingApi from '../api/tracking';
import dayjs from 'dayjs';
import { uuid } from 'vue-uuid';
import { toRaw } from 'vue';
export const useTrackingStore = defineStore('tracking', {
    state: () => ({
        trackingList: []
        // activeTrackingTask:null
    }),

    getters: {
        activeTrackingTask: state => {
            if (Array.isArray(state.trackingList) && state.trackingList.length > 0) {
                return state.trackingList.find(item => item.started_date !== null);
            } else return null;
        }
    },
    actions: {
        async getTrackingTask() {
            this.trackingList = await trackingApi.getCurrentTrackingData();
        },

        async startTrackTime(tracking) {
            const taskStore = useTasksStore();

            const res = await trackingApi.startTrackTime(taskStore.getTaskEntityId, tracking.instance_id);

            if (res) {
                const el = this.trackingList.find(el => el.instance_id === tracking?.instance_id);
                if (el) {
                    Object.assign(el, res);
                } else this.insertTrackInStore(res);
            }
        },
        async pauseTrackTime(tracking) {
            const taskStore = useTasksStore();

            const res = await trackingApi.pauseTrackTime(taskStore.getTaskEntityId, tracking.instance_id);

            if (res) {
                this.updateTrackInStore(res);
            }
            return res;
        },
        async stopTrackTime(tracking) {
            const taskStore = useTasksStore();
            const res = await trackingApi.stopTrackTime(taskStore.getTaskEntityId, tracking.instance_id);

            if (res) {
                this.deleteTrackFromStore(res);
            }
            return res;
        },

        insertTrackInStore(track) {
            const el = this.trackingList.find(el => el.instance_id === track?.instance_id);
            if (el) {
                Object.assign(el, track);
            } else this.trackingList.push(track);
        },
        updateTrackInStore(track) {
            const el = this.trackingList.find(el => el.id === track.id);
            if (!el) return;
            Object.assign(el, track);
        },
        deleteTrackFromStore(track) {
            const elIdx = this.trackingList.findIndex(el => el.id === track.id);
            if (elIdx > -1) this.trackingList.splice(elIdx, 1);
        },

        async updateTrackTime(data) {
            let res = await trackingApi.updateTrackTime(data.id, data);
            if (res) {
                const el = this.trackingList.find(el => {
                    return el.id === data.id;
                });
                if (!el) return;
            }
        },

        async stopCurrentTrackingAndPlayNew(track) {
            const taskStore = useTasksStore();
            const newTracking = this.createNewTrackingFromOld(track);

            // if (track.started_date) {
            //   track.duration =
            //     track.duration + (dayjs().unix() - track.started_date / 1000);
            // }
            // track.started_date = null;
            // track.finished = true;
            // let res = await trackingApi.updateTrackTime(track.id, track);

            const res = await trackingApi.stopTrackTime(taskStore.getTaskEntityId, track.instance_id);

            if (res) {
                const trackIdx = this.trackingList.findIndex(({ id }) => id === track.id);
                if (trackIdx > -1) this.trackingList.splice(trackIdx, 1);

                this.addEntityToTrackList(newTracking);
            }
        },
        async addEntityToTrackList(track) {
            let existTracking = this.trackingList.find(el => el.instance_id === track.instance_id);
            if (existTracking) {
                this.startTrackTime(existTracking);
            } else {
                let res = await trackingApi.createTrackTime(track);
                if (res) {
                    this.trackingList.push(res);
                    this.startTrackTime(res);
                }
            }
        },

        async stopTrackingCurrentTask(instanceId) {
            const currentTracking = this.trackingList.find(({ instance_id, finished }) => instance_id === instanceId && !finished);

            if (!currentTracking) return;

            // if (currentTracking.started_date) {
            //   currentTracking.duration =
            //     currentTracking.duration +
            //     (dayjs().unix() - currentTracking.started_date / 1000);
            //   currentTracking.started_date = null;
            // }

            const res = this.stopTrackTime(currentTracking);
            currentTracking.finished = true;
            return res;
        },

        generateNewTracking() {
            const userStore = useUserStore();
            return {
                id: uuid.v4(),
                name: '',
                entity_id: '',
                instance_id: '',
                user_id: toRaw(userStore.user.id),
                user: toRaw(userStore.user),
                date: dayjs().unix() * 1000,
                started_date: null,
                duration: 0,
                comment: '',
                finished: false,
                added_manual: false,
                analytics: ''
            };
        },
        createNewTrackingFromOld(oldTracking) {
            const taskStore = useTasksStore();

            const newTrack = this.generateNewTracking();
            newTrack.name = oldTracking.name;
            newTrack.entity_id = toRaw(taskStore.getTaskEntityId);
            newTrack.instance_id = oldTracking.instance_id;
            newTrack.started_date = dayjs().unix() * 1000;

            return newTrack;
        }
    }
});
