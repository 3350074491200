import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatDuration, isDurationValid } from '@/components/ui/CustomCalendar/customCalendar.utils';
dayjs.extend(utc);
dayjs.extend(timezone);

export function convertTotalTimeIntoString(totalTime) {
    if (!totalTime) return '00:00';

    const hoursInNumbers = parseInt(totalTime / 3600);
    const minutesInNumbers = parseInt((totalTime - hoursInNumbers * 3600) / 60);

    const hours = hoursInNumbers.toString().padStart(2, '0');
    const minutes = minutesInNumbers.toString().padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function dateInIntToStr(int) {
    return dayjs(0).second(Number(int)).utc().format('HH:mm');
}

const workDateSettings = {
    options: {
        format: 'DD MMM',
        useFullDayLoad: false
    }
};

export function getDateTitle(start, end, duration, dateSettings = workDateSettings) {
    if (end && (!start || !duration)) {
        let endDate = dayjs(end).format('DD MMM');
        if (dateSettings) endDate = dayjs(end).format(dateSettings.options.format);
        return `${endDate}`;
    }

    if (start > end || !duration) {
        // console.log("start_date > end_date");
        return '';
    }

    if (end && start && duration) {
        let isValidDuration = isDurationValid(start, end, duration);

        if (!isValidDuration) return dayjs(end).format('DD MMM');

        const durationTitle = getDurationTitle(duration, dateSettings, start, end);
        let startDate = dayjs(start).format('DD MMM');
        let endDate = dayjs(end).format('DD MMM');
        if (dateSettings) {
            startDate = dayjs(start).format(dateSettings.options.format);
            endDate = dayjs(end).format(dateSettings.options.format);
        }

        return startDate === endDate ? `${startDate} (${durationTitle})` : `${startDate} - ${endDate} (${durationTitle})`;
    }
    return '';
}

export function getDurationTitle(duration, dateSettings, start, end) {
    if (!duration) {
        return;
    }

    let title = '';
    let workHours = 8 * 60 * 60;

    if (start && end && dayjs(start).isSame(dayjs(end), 'day')) {
        const hours = dayjs(end).diff(dayjs(start), 'hour');
        const diffInMinutes = dayjs(end).diff(dayjs(start), 'minute');
        const minutes = diffInMinutes - hours * 60;

        if (hours === workHours && !minutes) return '1d';

        if (hours) title += `${hours}h`;
        if (minutes) title += ` ${minutes}m`;

        return title;
    }

    if (dateSettings?.options?.useFullDayLoad) workHours = 24 * 60 * 60;

    // We take the duration in milliseconds
    // let seconds = duration / 1000;
    // We take the duration in seconds
    let seconds = duration;

    const fullDays = Math.floor(seconds / workHours);
    const fullHours = Math.floor((seconds - fullDays * workHours) / 60 / 60);
    const fullMinutes = Math.floor((seconds - fullHours * 60 * 60 - fullDays * workHours) / 60);

    return formatDuration({ days: fullDays, hours: fullHours, minutes: fullMinutes });
}
