import { defineStore } from 'pinia';
import BasedEntityApi from '@/api/basedEntity';
import entityApi from '../api/entity';
import FormApi from '../api/forms';
import { hasProperty } from '@/helpers';
import { useUserStore } from './userStore';
import merge from 'lodash/merge';

export const useEntitysStore = defineStore('entitys', {
    state: () => ({
        entitys: {},
        entityCards: {},
        availableFieldsTypes: null
    }),
    getters: {},
    actions: {
        initializeEntity(entityId) {
            if (!this.entitys[entityId]) {
                this.entitys[entityId] = {};
            }
        },
        async loadEntityProps(entityId) {
            let res = await entityApi.getEntityFields(entityId);
            if (res) {
                return res;
            } else {
                console.warn('Cant load entity props');
                return null;
            }
        },
        async loadEntityProp(fieldId) {
            let res = await entityApi.getEntityField(fieldId);
            if (res) {
                return res;
            } else {
                console.warn('Cant load field props');
                return null;
            }
        },
        async getEntityProps(entityId) {
            if (hasProperty(this.entitys, entityId) && hasProperty(this.entitys?.[entityId], 'props')) {
                return this.entitys[entityId].props;
            } else {
                const props = await this.loadEntityProps(entityId);
                if (props) {
                    this.initializeEntity(entityId);
                    this.entitys[entityId].props = props;
                    return this.entitys[entityId].props;
                } else return null;
            }
        },
        async reloadEntityProps(entityId) {
            let props = await this.loadEntityProps(entityId);
            if (props) {
                this.storeEntityProps(entityId, props);
            }
        },
        findProp(entityId, propsId) {
            if (!this.entitys?.[entityId]?.props) return null;
            return this.entitys[entityId].props?.find(el => el.id === propsId);
        },
        async getEntityProp(entityId, propsId, refetch = false) {
            if (refetch) {
                let refetchedProp = await this.loadEntityProp(propsId);
                if (refetchedProp) {
                    let oldProp = this.findProp(propsId);
                    if (oldProp) {
                        oldProp = { ...oldProp, ...refetchedProp };
                    }
                    console.warn('getEntityProp refetchedProp', refetchedProp);

                    return refetchedProp;
                } else return null;
            }
            const exixtProp = this.findProp(entityId, propsId);
            if (exixtProp) {
                return exixtProp;
            } else {
                const props = await this.loadEntityProps(entityId);
                if (this.entitys?.[entityId]) {
                    this.entitys[entityId].props = props;
                } else {
                    this.entitys[entityId] = {};
                    this.entitys[entityId].props = props;
                }
                const prop = this.findProp(entityId, propsId);
                return prop ? prop : null;
            }
        },
        async updateEntityProp(entityId, propsId, data) {
            let res = await entityApi.updateEntityFields(entityId, propsId, data);
            if (!res) return;
            if (!hasProperty(this.entitys, entityId) || !this.entitys[entityId]?.props) {
                console.warn('no entity props');
                return false;
            }
            let prop = this.entitys[entityId].props?.find(el => el.id === propsId);
            if (!prop) {
                console.warn('no prop in store');
                return false;
            }
            prop = merge(prop, data);
            return prop;
        },
        storeEntityProps(entityId, props) {
            if (!hasProperty(this.entitys, entityId) || !this.entitys[entityId]?.props) {
                this.entitys[entityId] = { props: props };
            }
        },
        storeEntityProp(entityId, propsObj) {
            if (!hasProperty(this.entitys, entityId) || !this.entitys[entityId]?.props) {
                console.warn('no entity props');
                return false;
            }
            this.entitys[entityId].props.push(propsObj);
        },
        removeEntityProp(entityId, propId) {
            if (hasProperty(this.entitys, entityId) || this.entitys[entityId]?.props) {
                const propsIndex = this.entitys[entityId].props?.findIndex(el => el.field_id === propId);
                if (propsIndex !== -1) {
                    this.entitys[entityId].props.splice(propsIndex, 1);
                }
            }
        },
        async loadAvailableFieldsTypes() {
            let res = await entityApi.getEntityAvailableFields();
            if (res) {
                return res;
            } else {
                console.warn('Cant load available fields types');
                return null;
            }
        },
        async getAvailableFieldsTypes() {
            if (this.availableFieldsTypes) {
                return this.availableFieldsTypes;
            } else {
                const types = await this.loadAvailableFieldsTypes();
                if (types) {
                    this.availableFieldsTypes = types;
                    return this.availableFieldsTypes;
                } else return null;
            }
        },
        async getEntityForms(entityId) {
            if (hasProperty(this.entitys, entityId) && hasProperty(this.entitys[entityId], 'forms')) {
                return this.entitys[entityId].forms;
            } else {
                const forms = await this.loadForms(entityId);
                if (forms) {
                    if (!this.entitys[entityId].forms) {
                        this.entitys[entityId] = { forms: {} };
                    }
                    this.entitys[entityId].forms = forms;
                    return this.entitys[entityId].forms;
                } else return null;
            }
        },
        async getEntityFormByParams(entityId, props, value) {
            // console.warn('getEntityFormByParams', entityId, props, value);
            if (hasProperty(this.entitys, entityId) && this.entitys[entityId]?.forms) {
                return this.findForm(this.entitys[entityId].forms, props, value);
            } else {
                const forms = await this.loadForms(entityId);
                if (forms) {
                    this.storeForms(entityId, forms);
                    return this.findForm(this.entitys[entityId].forms, props, value);
                } else return null;
            }
        },
        async getEntityFormById(formId) {
            let form = this.searchFormById(formId);
            if (form) return form;
            return await entityApi.getEntityFormById(formId);
        },
        async getFormById(formId) {
            if (!formId) return;
            const existForm = this.searchFormById(formId);
            if (existForm) return existForm;
            else {
                const customForm = await FormApi.getFormById(formId);
                if (customForm) {
                    this.storeForms('customForm', [customForm]);
                    return customForm;
                } else return null;
            }
        },
        searchFormById(formId) {
            for (let entity in this.entitys) {
                if (this.entitys[entity]?.forms) {
                    let form = this.entitys[entity].forms.find(el => el.id === formId);
                    if (form) return form;
                }
            }
            return null;
        },
        storeForms(entityId, formData) {
            if (hasProperty(this.entitys, entityId)) {
                this.entitys[entityId].forms = formData;
            } else {
                this.entitys[entityId] = {};
                this.entitys[entityId].forms = [...formData];
            }
        },
        storeEntityForm(entityId, formData) {
            if (hasProperty(this.entitys, entityId) && this.entitys[entityId].forms) {
                this.entitys[entityId].forms?.push(formData);
            } else {
                this.entitys[entityId] = {};
                this.entitys[entityId].forms = [{ ...formData }];
            }
        },
        updateEntityForm(entityId, formId, formData) {
            if (!hasProperty(this.entitys, entityId) || !this.entitys[entityId].forms) return;
            let formObj = this.entitys[entityId].forms?.find(el => el.id === formId);
            if (!formObj) return;
            formObj.form_data = formData.form_data;
            return formObj;
        },
        findForm(forms, props, value) {
            if (!Array.isArray(forms)) return;
            let form = forms.find(el => el[props] === value);
            if (!form && forms?.length > 0) {
                form = forms[0];
            }
            return form ? form : null;
        },
        removeEntityForm(entityId, formId) {
            if (!hasProperty(this.entitys, entityId) || !this.entitys[entityId].forms) return;
            let formIndex = this.entitys[entityId].forms?.findIndex(el => el.id === formId);
            if (formIndex === -1) return;
            this.entitys[entityId].forms.splice(formIndex, 1);
        },
        async loadForms(entityId) {
            let res = await entityApi.getEntityForms(entityId);
            return res;
        },
        loadEntityCardParams(entityId) {
            const userStore = useUserStore();
            const taskId = userStore.getEnvironmentByName('crm_task_id');
            const projectId = userStore.getEnvironmentByName('crm_project_group_id');
            const dictionary = {
                [taskId]: {
                    edit: 'TaskCard'
                },
                CRMDeal: {
                    edit: 'CRMDeal'
                },
                CRMLead: {
                    edit: 'CRMLead'
                },
                CRMContact: {
                    edit: 'CRMContact'
                },
                CRMAccount: {
                    edit: 'CRMAccount'
                },
                CRMActivities: {
                    edit: 'CRMActivities'
                },
                SmartUser: {
                    edit: 'UserInformation'
                },
                [projectId]: {
                    edit: 'ProjectCard'
                }
            };
            if (!hasProperty(dictionary, entityId)) return null;
            return dictionary[entityId];
        },
        getEntityCard(entityId, cardType = 'edit') {
            if (hasProperty(this.entityCards, entityId)) {
                return this.entityCards[entityId][cardType];
            } else {
                const res = this.loadEntityCardParams(entityId);
                if (res) {
                    this.entityCards[entityId] = res;
                    return this.entityCards[entityId][cardType];
                } else {
                    return null;
                }
            }
        },
        getTitleFieldFromEntityProps(entityId, fieldId) {
            if (!hasProperty(this.entitys, entityId)) return fieldId;
            // const entityProps = await this.getEntityProps(entityId);
            const entityProps = this.entitys?.[entityId]?.props;
            if (!entityProps) return fieldId;
            const searchableProps = entityProps.find(el => el.id === fieldId);
            if (!searchableProps) return fieldId;
            return searchableProps.title;
        },
        async getBasedEntities(entityId) {
            if (hasProperty(this.entitys, entityId) && hasProperty(this.entitys?.[entityId], 'basedEntities')) {
                return this.entitys[entityId].basedEntities;
            } else {
                this.initializeEntity(entityId);
                await this.updateBasedEntitiesData(entityId);
                return this.entitys[entityId].basedEntities;
            }
        },
        async updateBasedEntitiesData(entityId) {
            const basedEntities = await BasedEntityApi.getEntityBasedGenerations(entityId);
            if (basedEntities) {
                this.entitys[entityId].basedEntities = basedEntities;
            } else {
                this.entitys[entityId].basedEntities = [];
            }
        },
        async updateBasedEntities(entityId) {
            this.initializeEntity(entityId);
            await this.updateBasedEntitiesData(entityId);
        }
    }
});
