<template>
    <div class="activity-field">
        <div class="new__value select-text" v-if="field.activity_type === 'added' || field.activity_type === 'deleted'">
            <div class="value__fields" v-for="(value, idx) in field.value" :key="value?.id || idx">
                <BaseUserCard class="value__user" v-if="field.data_type === 'SmartUser'" :user="value" :showFullInformation="true"></BaseUserCard>
                <span v-else class="value__name">
                    {{ value.name }}
                </span>
            </div>
        </div>
        <div class="new__value" v-else-if="field.activity_type === 'update' || field.activity_type === 'new'">
            <base-icon v-if="field.data_type === 'SmartUser' && !field[keyField]" class="value__user-empty--icon" :name="'ava1'" viewBox="0 0 20 20"></base-icon>
            <BaseUserCard class="value__user" v-else-if="field.data_type === 'SmartUser' && field[keyField]" :user="field[keyField]" :showFullInformation="true"></BaseUserCard>
            <span v-else-if="field.field_name === 'duration'" class="value__duration">
                {{ getDuration(field[keyField]) }}
            </span>
            <div v-else-if="field.data_type === 'DateTime'" class="value__date flex gap-1 items-center">
                <BaseIcon name="calendar-03" class="h-[24px] fill-[#C0C2CD]" />
                {{ getCorrectDate(field[keyField]) }}
            </div>
            <div v-else-if="field.field_name === 'description'" class="value__description" v-html="field[keyField]"></div>

            <span
                v-else-if="field.field_name === 'stage' && !isNotifications"
                class="value__name"
                :style="{
                    'background-color': field?.[keyField]?.color,
                    color: getStatusColorText(field?.[keyField]?.color),
                    padding: '4px 8px'
                }">
                {{ isObject(field[keyField]) ? field[keyField]?.name : field[keyField] || 'None' }}
            </span>

            <div v-else-if="field.field_name === 'stage' && isNotifications" class="flex items-center gap-[10px]">
                <span
                    class="value__name h-[10px] w-[10px]"
                    :style="{
                        'background-color': field?.[keyField]?.color,
                        'outline-color': field?.[keyField]?.color + '33',
                        'outline-style': 'solid',
                        'outline-width': '3px',
                        color: getStatusColorText(field?.[keyField]?.color)
                    }">
                </span>

                <span>
                    {{ isObject(field[keyField]) ? field[keyField]?.name : field[keyField] || 'None' }}
                </span>
            </div>

            <span v-else class="value__name">
                {{ isObject(field[keyField]) ? field[keyField]?.name : field[keyField] || 'None' }}
            </span>
        </div>
        <div class="new__value" v-else>
            <span class="value__name">None</span>
        </div>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseUserCard from '@/components/user/BaseUserCard.vue';

import { isObject, getColorText, getDurationTitle } from '@/helpers';

import dayjs from 'dayjs';

export default {
    name: 'ActivityField',
    components: {
        BaseIcon,
        BaseUserCard
    },
    props: {
        field: {
            type: Object
        },
        keyField: {
            type: String,
            default: 'new'
        },
        isNotifications: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        isObject(obj) {
            return isObject(obj);
        },
        getStatusColorText(backgroundColor) {
            if (!backgroundColor) return `var( --sp-text-primary, #2a3547)`;
            return getColorText(backgroundColor);
        },
        getDuration(data) {
            if (!data) return 'None';
            return getDurationTitle(data);
        },
        getCorrectDate(date) {
            if (!date) return 'None';
            return dayjs(date).format('DD.MM.YYYY');
        }
    }
};
</script>

<style lang="scss" scoped>
.value__user-empty--icon {
    width: 35px;
    height: 35px;
}
.value__user {
}
.value__description {
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: pre-line;
}
/* .value__description * {
  max-width: 300px;
  overflow-wrap: break-word;
  white-space: pre-line;
} */
.value__name {
    border-radius: var(--sp-border-radius, 7px);
}
</style>
