// import workflows from "./devdata/workflows.json";
import { sendRequest } from '@/helpers';
import { useUserStore } from '@/stores/userStore';

export default {
    async getWorkflows(params) {
        // return workflows;
        const userStore = useUserStore();
        const crm_workflow_id = userStore.getEnvironmentByName('crm_workflow_id');
        return await sendRequest(`/api/instances/search/${crm_workflow_id}`, 'post', params);
    },
    async getWorkflow(id) {
        // return workflows;
        const userStore = useUserStore();
        const crm_workflow_id = userStore.getEnvironmentByName('crm_workflow_id');

        return await sendRequest(`/api/instance/${crm_workflow_id}/${id}`, 'get');
    },
    async createWorkflow(data) {
        const userStore = useUserStore();
        const crm_workflow_id = userStore.getEnvironmentByName('crm_workflow_id');
        return await sendRequest(`/api/instance/${crm_workflow_id}/`, 'post', data);
    },
    async updateWorkflow(id, newData) {
        const userStore = useUserStore();
        const crm_workflow_id = userStore.getEnvironmentByName('crm_workflow_id');
        let res = await sendRequest(`/api/instance/${crm_workflow_id}/${id}`, 'put', newData);
        return res;
    },
    async deleteWorkflow(id) {
        const userStore = useUserStore();
        const crm_workflow_id = userStore.getEnvironmentByName('crm_workflow_id');
        let res = await sendRequest(`/api/instance/${crm_workflow_id}/${id}`, 'delete', '');
        return res;
    },
    async createStage(data) {
        const userStore = useUserStore();
        const crm_stage_id = userStore.getEnvironmentByName('crm_stage_id');
        return await sendRequest(`/api/instance/${crm_stage_id}/`, 'post', data);
    },
    async updateStage(id, newData) {
        const userStore = useUserStore();
        const crm_stage_id = userStore.getEnvironmentByName('crm_stage_id');
        let res = await sendRequest(`/api/instance/${crm_stage_id}/${id}`, 'put', newData);
        return res;
    },
    async deleteStage(id) {
        const userStore = useUserStore();
        const crm_stage_id = userStore.getEnvironmentByName('crm_stage_id');
        let res = await sendRequest(`/api/instance/${crm_stage_id}/${id}`, 'delete', '');
        return res;
    }
};
