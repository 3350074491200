import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vModelDynamic as _vModelDynamic, withKeys as _withKeys, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ea770b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-[#FF7C73]"
}
const _hoisted_2 = {
  key: 0,
  class: "text-[#FF7C73]"
}
const _hoisted_3 = {
  key: 0,
  class: "select-none font-medium"
}
const _hoisted_4 = { class: "relative flex items-center w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_base_loader = _resolveComponent("base-loader")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sp-input relative group/input", {
            'sp-input_has-value': _ctx.internalValue,
            'has-icon': _ctx.iconName,
            focused: _ctx.focused,
            'in-line': _ctx.inLine,
            'space-between': _ctx.spaceBetween,
            'has-label': _ctx.labelName,
            'no-border': _ctx.noBorder
        }])
  }, [
    _createElementVNode("label", {
      class: "sp-input__label relative flex w-full h-full",
      style: _normalizeStyle({
                color: _ctx.labelFontColor,
                fontSize: _ctx.labelFontSize,
                fontWeight: _ctx.labelFontWeight
            })
    }, [
      (_ctx.noBorder)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([{ 'sp-input__label-noborder': _ctx.noBorder }])
          }, [
            _createTextVNode(_toDisplayString(_ctx.labelName), 1),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([{ invisible: _ctx.noBorder }])
      }, [
        _createTextVNode(_toDisplayString(_ctx.labelName), 1),
        (_ctx.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["sp-input__wrapper relative w-full h-full text-[14px] flex items-center gap-1 transition-[border-color] ease-out text-[#131416]", [
                    {
                        'px-4 bg-[#FFFFFF] border': !_ctx.noBorder
                    },
                    {
                        'border-y-2 border-t-transparent bg-transparent': _ctx.noBorder
                    },
                    { 'border-[#FF7C73]': !_ctx.valid || _ctx.isInvalidInteger },

                    {
                        'border-[#519df5]': _ctx.focused && _ctx.valid
                    },
                    {
                        'hover:border-[#979BB2]': !_ctx.focused && _ctx.valid
                    },
                    {
                        'border-[#F9F9F9]': !_ctx.focused && _ctx.valid && _ctx.noBorder
                    },
                    {
                        'border-[#C0C2CD]': !_ctx.focused && _ctx.valid && !_ctx.noBorder
                    },

                    {
                        'cursor-not-allowed': _ctx.disabled
                    }
                ]]),
        style: _normalizeStyle({ borderTopColor: _ctx.noBorder ? 'transparent' : '', borderRadius: !_ctx.noBorder ? _ctx.getStyleInput.borderRadius : ''})
      }, [
        (_ctx.$slots.prefix)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.useTrim)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 0,
                ref: "baseInput",
                class: "w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none",
                style: {
                            fontSize: _ctx.labelFontSize
                        }
              }, _ctx.customProps, {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
                onFocus: _cache[2] || (_cache[2] = 
                            () => {
                                _ctx.$emit('focus');
                                _ctx.focused = true;
                            }
                        ),
                onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
                onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.checkInputNumber && _ctx.checkInputNumber(...args))),
                onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
              }), null, 16)), [
                [
                  _vModelDynamic,
                  _ctx.internalValue,
                  void 0,
                  { trim: true }
                ]
              ])
            : _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 1,
                ref: "baseInput",
                class: "w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none"
              }, _ctx.customProps, {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.internalValue) = $event)),
                onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
                onFocus: _cache[8] || (_cache[8] = 
                            () => {
                                _ctx.$emit('focus');
                                _ctx.focused = true;
                            }
                        ),
                onBlur: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
                onKeypress: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.checkInputNumber && _ctx.checkInputNumber(...args))),
                onInput: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
              }), null, 16)), [
                [_vModelDynamic, _ctx.internalValue]
              ]),
          _createElementVNode("div", {
            class: _normalizeClass(["sp-input__status-icons flex items-center", (_ctx.showResetButton && _ctx.internalValue?.length) || _ctx.type === 'password' ? 'right-3' : 'right-1']),
            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.clickIcon && _ctx.clickIcon(...args)))
          }, [
            (_ctx.showTypingSvg && _ctx.showEditingIcon)
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  class: "h-4 fill-[var(--sp-text-panel)]",
                  name: "buttonPencil"
                }))
              : _createCommentVNode("", true),
            (_ctx.showLoadingSpinner)
              ? (_openBlock(), _createBlock(_component_base_loader, {
                  key: 1,
                  size: "xsmall",
                  class: "opacity-50"
                }))
              : _createCommentVNode("", true),
            (_ctx.type === 'password')
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 2,
                  class: "fill-[var(--sp-primary-hover)]",
                  name: [_ctx.typeInput === 'password' ? 'eyeVisible' : 'eyeNotVisible'],
                  viewBox: "0 0 24 24",
                  style: {"height":"20px"}
                }, null, 8, ["name"]))
              : _createCommentVNode("", true)
          ], 2),
          ((_ctx.placeholder && !_ctx.labelName && (!_ctx.placeholderHideNotEmpty || !_ctx.internalValue?.length)) || (_ctx.placeholder && !_ctx.internalValue?.length))
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass(["sp-input__placeholder text-sm left-0", [
                            { active: _ctx.internalValue || _ctx.focused }
                            // , noBorder ? 'left-0' : 'left-4'
                        ]])
              }, [
                _renderSlot(_ctx.$slots, "placeholderIcon", {}, undefined, true),
                _createTextVNode(" " + _toDisplayString(_ctx.placeholder || _ctx.$t('inputs.search')), 1)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.iconName)
            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 3,
                class: _normalizeClass(["sp-input__icon absolute top-[50%] translate-y-[-50%] h-[24px] w-[24px] fill-[#C0C2CD]", [_ctx.noBorder ? 'left-0' : 'left-3']]),
                name: _ctx.iconName,
                style: _normalizeStyle(_ctx.positionIcon)
              }, null, 8, ["class", "name", "style"]))
            : _createCommentVNode("", true),
          (_ctx.showResetButton && _ctx.internalValue?.length)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 4,
                variant: "text",
                color: "third",
                onClick: _ctx.resetInput,
                size: "xs"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "Clouse",
                    class: "h-[24px]"
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 6)
    ], 4)
  ], 2))
}