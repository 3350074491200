<template>
    <Teleport to="#modal-container">
        <div
            id="myModal"
            @mousedown="handleMouseDown"
            @mouseup="handleMouseUp"
            :style="{ zIndex: props.zindex }"
            class="modal fixed flex left-0 top-0 w-full h-full m-0 overflow-hidden"
            :class="[
                { 'justify-start': props.horizontalAlign === 'left' },
                { 'justify-center': props.horizontalAlign === 'center' },
                { 'justify-end': props.horizontalAlign === 'right' },
                { 'items-start': props.verticalAlign === 'top' },
                { 'items-center': props.verticalAlign === 'center' },
                { 'items-end': props.verticalAlign === 'bottom' },
                {
                    'bg-[black]/50': !$attrs.class?.includes('bg') && !props.acceptClickUnderModal
                },
                {
                    'backdrop-blur-sm': !props.acceptClickUnderModal && props.blurBackgrnd
                },
                props.acceptClickUnderModal ? 'pointer-events-none bg-transparent	' : 'pointer-events-auto',
                $attrs.class
            ]">
            <div
                class="modal-content pointer-events-auto max-h-full max-w-full bg-white"
                :class="[
                    props.fullHeight ? 'h-full' : 'h-fit',
                    props.fullWidth ? 'w-full' : 'w-fit',
                    {
                        'scroll-wrap': $attrs.class?.includes('scroll-wrap')
                    },
                    {
                        'overflow-hidden': $attrs.class?.includes('overflow-hidden')
                    },
                    {
                        shadow: props.acceptClickUnderModal
                    },
                    {
                        flex: $attrs.class?.includes('flex')
                    }
                ]"
                :style="[
                    { boxShadow: props.boxShadow },
                    { borderTopRightRadius: props.borderRadius && borderRTR ? props.borderRadius : 'none' },
                    { borderTopLeftRadius: props.borderRadius && borderRTL ? props.borderRadius : 'none' },
                    { borderBottomRightRadius: props.borderRadius && borderRBR ? props.borderRadius : 'none' },
                    { borderBottomLeftRadius: props.borderRadius && borderRBL ? props.borderRadius : 'none' }
                ]">
                <!--   -->
                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';

// const { proxy } = getCurrentInstance();
// const isMobile = proxy.$isMobile.value;
// import { useUserStore } from '@/stores/userStore';
// const { isMobile } = useUserStore();

const emit = defineEmits(['closeModalWindow', 'close']);
const props = defineProps({
    horizontalAlign: {
        type: String,
        default: 'center' //left, right, center
    },
    verticalAlign: {
        type: String,
        default: 'center' //top, bottom, centet
    },
    fullHeight: {
        type: Boolean,
        default: false
    },
    fullWidth: {
        type: Boolean,
        default: false
    },
    closeOnClickOutside: {
        type: Boolean,
        default: true
    },
    blurBackgrnd: {
        type: Boolean,
        default: false
    },
    zindex: {
        type: Number,
        default: 100
    },
    acceptClickUnderModal: {
        type: Boolean,
        default: false
    },
    borderRadius: {
        type: String,
        default: 'var(--sp-border-radius)'
    },
    boxShadow: {
        type: String,
        default: ''
    },
    borderRTL: {
        type: Boolean,
        default: true
    },
    borderRTR: {
        type: Boolean,
        default: true
    },
    borderRBL: {
        type: Boolean,
        default: true
    },
    borderRBR: {
        type: Boolean,
        default: true
    }
});

const isMouseDownInsideForm = ref(false);
let isDown = false;

function handleMouseDown(event) {
    isDown = true;
    if (event.target === event.currentTarget) {
        isMouseDownInsideForm.value = false;
    } else {
        isMouseDownInsideForm.value = true;
    }
}

const handleMouseUp = event => {
    if (event.target === event.currentTarget && !isMouseDownInsideForm.value && props.closeOnClickOutside && isDown) {
        emit('closeModalWindow');
        emit('close');
        isDown = false;
    }
};

const handleKeyPress = event => {
    if (event.key === 'Escape' && props.closeOnClickOutside) {
        // event.stopPropagation();
        emit('closeModalWindow');
        emit('close');
    }
};

onMounted(() => {
    document.addEventListener('keydown', handleKeyPress, true);
});

onBeforeUnmount(() => {
    document.removeEventListener('keydown', handleKeyPress, true);
});
</script>
