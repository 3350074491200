<template>
    <Teleport :to="props.teleportTo">
        <div
            class="smart-modal"
            :class="[{ 'smart-modal_animate': animationEnabled }]"
            :style="{
                transform: `translate(${resultModalDimensions?.left}px, ${resultModalDimensions?.top}px)`,
                maxHeight: `${maxHeight}px`,
                maxWidth: `${maxWidth}px`,
                zIndex: props.zindex
            }"
            ref="modal"
            :data-modal-created-timestamp="modalCreatedTimestamp">
            <slot></slot>
        </div>
    </Teleport>
</template>

<script setup>
import { onMounted, computed, onBeforeUnmount, ref } from 'vue';

const props = defineProps({
    teleportTo: {
        type: String,
        default: '#modal-container'
    },
    target: {
        type: [Object, null],
        default: null
    },
    windowPaddings: {
        type: [String, Number],
        default: 4
    },
    position: {
        type: String,
        default: 'target',
        validator: value => ['target', 'mouse', 'static'].includes(value)
    },
    zindex: {
        type: Number,
        default: 1000
    },
    closeOnTargetHide: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['close', 'resize']);

const modalCreatedTimestamp = performance.now();

const targetNode = computed(() => props.target);
let previousTargetDimensions = null;
let currentTargetDimensions = null;

const modal = ref(null);
const resultModalDimensions = ref(null);
let previousModalDimensions = null;
let currenntModalDimensions = null;

const viewportWidth = ref(0);
const viewportHeight = ref(0);

const updateViewportSize = () => {
    if (viewportHeight.value !== window.innerHeight) {
        viewportHeight.value = window.innerHeight;
    }
    if (viewportWidth.value !== window.innerWidth) {
        viewportWidth.value = window.innerWidth;
    }
};

const maxWidth = computed(() => viewportWidth.value - 2 * parseInt(props.windowPaddings));
const maxHeight = computed(() => viewportHeight.value - 2 * parseInt(props.windowPaddings));

let animationFrameId = null;

function setPosition() {
    if (targetNode.value) {
        currentTargetDimensions = targetNode.value?.getBoundingClientRect();
    }
    if (modal.value) {
        currenntModalDimensions = modal.value?.getBoundingClientRect();
    }

    if (
        modal.value &&
        currentTargetDimensions &&
        (currentTargetDimensions?.bottom !== previousTargetDimensions?.bottom ||
            currentTargetDimensions?.left !== previousTargetDimensions?.left ||
            viewportHeight.value !== window.innerHeight ||
            viewportWidth.value !== window.innerWidth ||
            currenntModalDimensions?.height !== previousModalDimensions?.height ||
            currenntModalDimensions?.width !== previousModalDimensions?.width)
    ) {
        if (currentTargetDimensions?.bottom !== previousTargetDimensions?.bottom || currentTargetDimensions?.left !== previousTargetDimensions?.left) {
            animationEnabled.value = false;
        } else {
            animationEnabled.value = true;
        }
        updateViewportSize();
        if (props.closeOnTargetHide && !isNodeVivible(targetNode.value)) {
            emit('close');
        }
        resultModalDimensions.value = calculateModalPosition(targetNode.value);

        if (
            previousModalDimensions &&
            (Math.round(currenntModalDimensions?.height) !== Math.round(previousModalDimensions?.height) ||
                Math.round(currenntModalDimensions?.width) !== Math.round(previousModalDimensions?.width))
        ) {
            emit('resize');
        }

        previousTargetDimensions = {
            left: currentTargetDimensions?.left,
            bottom: currentTargetDimensions?.bottom
        };
        previousModalDimensions = {
            height: currenntModalDimensions.height,
            width: currenntModalDimensions.width
        };
    }
    animationFrameId = requestAnimationFrame(setPosition);
}
function calculateModalPosition(node) {
    if (!modal.value) {
        return;
    }
    const targetDimensions = node?.getBoundingClientRect();
    const realModalHeight = modal.value.getBoundingClientRect().height;
    const realModalWidth = modal.value.getBoundingClientRect().width;

    let visibleTargetPositionBottom = null;
    let parent = node.parentElement;
    while (parent) {
        if (['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowY)) {
            const parentDimensions = parent.getBoundingClientRect();
            if (targetDimensions.bottom > parentDimensions.bottom) {
                visibleTargetPositionBottom = parentDimensions.bottom;
            }
            if (visibleTargetPositionBottom) {
                break;
            }
        }
        parent = parent.parentElement;
    }

    if (!visibleTargetPositionBottom) {
        visibleTargetPositionBottom = targetDimensions.bottom;
    }
    const modalTop = Math.max(
        props.windowPaddings,
        visibleTargetPositionBottom + realModalHeight + props.windowPaddings < viewportHeight.value
            ? visibleTargetPositionBottom + props.windowPaddings
            : viewportHeight.value - realModalHeight - props.windowPaddings
    );
    const modalLeft = Math.max(
        props.windowPaddings,
        targetDimensions.left + realModalWidth + props.windowPaddings < viewportWidth.value ? targetDimensions.left : viewportWidth.value - realModalWidth - props.windowPaddings
    );
    return { top: modalTop, left: modalLeft };
}

const isMouseDownInside = ref(false);

function isClickInside(event) {
    let isClickInside = false;
    if (modal.value?.contains(event.target)) {
        isClickInside = true;
    } else {
        let currentElement = event.target;
        while (currentElement) {
            const elementTimestamp = parseFloat(currentElement.dataset.modalCreatedTimestamp);
            if (elementTimestamp && elementTimestamp > modalCreatedTimestamp) {
                isClickInside = true;
                break;
            }
            currentElement = currentElement.parentElement;
        }
    }
    return isClickInside;
}

function handleMouseDown(event) {
    isMouseDownInside.value = isClickInside(event);
}

function handleMouseUp(event) {
    if (!isClickInside(event) && !isMouseDownInside.value) {
        emit('close');
    }
}

function isNodeVivible(node) {
    const nodeDimensions = node.getBoundingClientRect();
    if (nodeDimensions && (nodeDimensions.bottom <= 0 || nodeDimensions.top >= window.innerHeight || nodeDimensions.right <= 0 || nodeDimensions.left >= window.innerWidth)) {
        return false;
    }

    let parent = node.parentElement;
    while (parent) {
        if (['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowY) || ['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowX)) {
            const parentDimensions = parent.getBoundingClientRect();
            if (
                nodeDimensions.bottom <= parentDimensions.top ||
                nodeDimensions.top >= parentDimensions.bottom ||
                nodeDimensions.right <= parentDimensions.left ||
                nodeDimensions.left >= parentDimensions.right
            ) {
                return false;
            }
        }
        parent = parent.parentElement;
    }

    return true;
}

const animationEnabled = ref(false);

onMounted(() => {
    animationFrameId = requestAnimationFrame(setPosition);
    setTimeout(() => {
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
    }, 0);
});

onBeforeUnmount(() => {
    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
    }
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('mouseup', handleMouseUp);
});
</script>

<style lang="scss" scoped>
.smart-modal {
    display: flex;
    position: fixed;
    border-radius: 12px;
    border: 1px solid #eaebee;
    background: #fff;
    pointer-events: auto;
    box-shadow: var(--sp-shadow);
}
.smart-modal_animate {
    transition: transform 0.1s ease-in-out;
}
</style>
