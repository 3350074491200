<template>
    <div class="user-card">
        <div class="user-card__container">
            <base-avatar class="user-card__avatar" :user="user" :showInformation="showFullInformation" :widthPic="widthPic" :heightPic="heightPic" :rounded="rounded"></base-avatar>
            <div class="user-card__data">
                <p class="data__name" :class="classForName">{{ name }}</p>
                <p class="data__field" v-if="user?.work_position && workPosition">
                    {{ user?.work_position }}
                </p>
                <p class="data__field" v-if="description">
                    {{ description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAvatar from '@/components/ui/BaseAvatar.vue';

import { getFullName } from '@/helpers';
export default {
    name: 'BaseUserCard',
    components: {
        BaseAvatar
    },
    props: {
        user: {
            type: Object,
            default: null
        },
        widthPic: {
            type: Number,
            default: 36
        },
        heightPic: {
            type: Number,
            default: 36
        },
        rounded: {
            type: Number,
            default: 18
        },
        shortName: {
            type: Boolean,
            default: false
        },
        workPosition: {
            type: Boolean,
            default: true
        },
        showFullInformation: {
            type: Boolean,
            default: false
        },
        birthday: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        classForName: {
            type: String,
            default: ''
        }
    },
    computed: {
        fullName() {
            return getFullName(this.user);
        },
        name() {
            return this.shortName ? `${this.user?.firstName} ${this.user?.lastName[0]}.` : this.fullName || 'No data';
        }
    }
};
</script>

<style lang="scss" scoped>
.user-card__container {
    display: flex;
    align-items: center;
}
.user-card__data {
    /* color: var(--black); */
    margin-left: 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.data__name {
}
.data__field {
    color: var(--sp-text-panel, #939da7);
    font-size: 0.625rem;
    font-weight: 500;
}
.data__name,
.data__field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
