<template>
    <div class="field-list" :class="{ inline: showChangeInline }">
        <div class="main__description" v-for="field in visibleFields" :key="field.field_name">
            <slot name="field" :field="field"></slot>
        </div>
        <div class="comments__button" v-if="correctFields?.length > counterVisibleFields && correctFields?.length !== counterVisibleFields + 1">
            <BaseButton variant="text" size="s" @click.stop="onShowMoreClick"> {{ $t('buttons.showMoreFields') }} ({{ correctFields?.length - counterVisibleFields }}) </BaseButton>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/ui/BaseButton.vue';

import { convertDataToArray } from '@/helpers';

export default {
    name: 'FieldList',
    components: {
        BaseButton
    },
    props: {
        fields: {
            type: [Array, Object],
            default: () => []
        },
        numberVisibleFields: {
            type: Number
        },
        showChangeInline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showAllFields: false
        };
    },
    computed: {
        correctFields() {
            return convertDataToArray(this.fields);
        },
        visibleFields() {
            const fields = this.correctFields;
            if (fields?.length <= this.counterVisibleFields || !this.numberVisibleFields || fields?.length === this.counterVisibleFields + 1) return fields;
            return fields.slice(0, this.counterVisibleFields);
        },
        counterVisibleFields() {
            if (this.showAllFields) return this.correctFields.length;
            return this.numberVisibleFields;
        }
    },
    methods: {
        onShowMoreClick() {
            this.showAllFields = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.inline {
    .main__description {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
.main__description {
    font-size: 14px;

    & + & {
        margin-top: 4px;
    }
}

.comments__button {
    display: flex;
    margin-left: 1rem;
    margin-top: 0.25rem;
}
</style>
