import { hasProperty, arrayСontainsAllValues, mergeDeep } from './common';

function formatFieldToRequestFormat(field) {
    let params = {};
    if (field.type === 'relation') {
        switch (field.relation?.relation_target_entity_id) {
            case 'SmartUser':
                params = {
                    id: {},
                    name: {},
                    photo_url: {}
                };
                break;
            case 'Tags':
                params = {
                    id: {},
                    name: {},
                    color: {}
                };
                break;
            case 'CRMCurrencies':
                params = {
                    id: {},
                    name: {},
                    format_string: {}
                };
                break;

            default:
                params = {
                    id: {},
                    name: {},
                    [field?.relation?.label_prop]: {}
                };
                break;
        }
    }
    if (field.id === 'stage') params = { ...params, color: {}, workflow_id: {}, status: {} };
    if (field.id === 'task_type') params = { ...params, icon: {}, rate: {} };
    return params;
}
function formatCollToRequestFormat(collObj, res) {
    function columnFields(columnObj) {
        if (columnObj?.dataType === 'relation') {
            switch (columnObj?.cellEditorParams?.relation_target_entity_id) {
                case 'SmartUser':
                    return {
                        id: {},
                        name: {},
                        photo_url: {}
                    };
                default:
                    return {
                        id: {},
                        name: {}
                    };
            }
        } else return {};
    }

    function createNestedObject(str, obg) {
        const fields = str.split('.');
        let result = {};
        let current = result;

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (i === fields.length - 1) {
                current[field] = columnFields(obg);
            } else {
                current[field] = {};
                current = current[field];
            }
        }
        return result;
    }

    if (collObj.field.includes('.')) {
        const nestedObject = createNestedObject(collObj.field, collObj);
        res = mergeDeep(res, nestedObject);
    } else {
        res[collObj.field] = {};
    }
}

export function formatEntityFieldsToRequestFormat(viewFields, entityFields) {
    if (!viewFields || !entityFields) return;
    let fields = entityFields.filter(el => {
        return viewFields.find(viewField => viewField.id === el.id);
    });
    let res = {};
    if (!fields || !Array.isArray(fields)) return;
    fields.forEach(el => {
        res[el.id] = formatFieldToRequestFormat(el);
        if (el.id === 'amount') {
            res.currency = { id: {}, name: {}, format_string: {} };
        }
        if (el.id === 'next_activity') {
            res.next_activity = {
                id: {},
                subject: {},
                end_date: {},
                activity_type: {},
                sub_instance_id: {}
            };
        }
    });
    const virtualFields = viewFields.filter(el => el.virtual);
    if (virtualFields?.length > 0) {
        virtualFields.map(el => formatCollToRequestFormat(el, res));
    }
    const reqFields = ['sort_index', 'version'];
    if (!arrayСontainsAllValues(Object.keys(res), reqFields)) {
        reqFields.forEach(el => {
            res[el] = {};
        });
    }
    return res;
}
export function formatEntityFieldToTableColumn(entityField) {
    let column = {
        field: entityField.id,
        headerName: entityField.title,
        cellRendererParams: {}
    };
    if (hasProperty(entityField, 'initWidth')) {
        column.minWidth = entityField.initWidth;
    }
    if (hasProperty(entityField, 'sortable')) {
        column.sortable = entityField.sortable;
    }
    if (hasProperty(entityField, 'filter')) {
        column.filter = entityField.filter;
    }
    if (hasProperty(entityField, 'hiden')) {
        column.hide = entityField.hiden;
    }
    if (hasProperty(entityField, 'multiple')) {
        column.cellRendererParams.multiple = entityField.multiple;
    }
    if (entityField.type === 'relation') {
        switch (entityField?.relation?.relation_target_entity_id) {
            case 'SmartUser':
                column.cellRenderer = 'userRenderer';
                column.valueGetter = `data.${entityField.id}?.name`;
                break;
            case 'Tags':
                column.cellRenderer = 'tagsRenderer';

                break;
            default:
                column.cellRenderer = 'objectRenderer';
                column.cellRendererParams.template = '{name}';
                break;
        }
    }
    if (entityField.type === 'DateTime') {
        column.cellRenderer = 'dateRenderer';
    }
    switch (entityField.id) {
        case 'phones':
            column.cellRenderer = 'objectRenderer';
            column.cellRendererParams.template = '{value}';
            column.cellRendererParams.multiple = true;
            break;
        case 'emails':
            column.cellRenderer = 'objectRenderer';
            column.cellRendererParams.template = '{value}';
            column.cellRendererParams.multiple = true;
            break;
        default:
            break;
    }
    return column;
}

export function initializeFieldsFromCardObj(cardObj, defaultCreateCardObj) {
    if (cardObj) {
        for (let key in defaultCreateCardObj) {
            if (key !== 'id' && key !== 'responsible' && key !== 'name' && Object.prototype.hasOwnProperty.call(cardObj, key)) {
                defaultCreateCardObj[key] = cardObj[key];
            }
        }
    }
}
// function formatFieldsToExportRequest(fields, entityFields) {

// }
function formatFieldToExport(fild, entityFields) {
    const fildType = entityFields.find(el => el.id === fild.id);
    switch (fildType.type) {
        case 'relation':
            return {
                name: {}
            };
        default:
            return {};
    }
}

export function getRequestFields(viewFilds, entityFields) {
    if (!viewFilds) return null;
    let res = {};
    viewFilds.forEach(element => {
        res[element.id] = formatFieldToExport(element, entityFields);
    });
    return res;
}
