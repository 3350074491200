<template>
    <button
        type="button"
        class="platform-button"
        :disabled="disabled"
        :class="[sizeButton, variant, color, { 'full-width': fullWidth }, { 'sp-btn-icon': icon || name }, { rounded: rounded }]"
        data-element="button"
        ref="baseButton">
        <!-- @click="createRipple($event)" -->
        <span class="btn-container" data-element="button" @click="handleMainButtonClick($event)">
            <BaseIcon class="btn__icon pointer-events-none" v-if="name" :name="name" :viewBox="viewBox"></BaseIcon>
            <slot v-else>Button</slot>{{ submitOptionsFormated?.length && submitOptionActive && !name ? ': ' + submitOptionActive.label : '' }}
        </span>
        <div v-if="submitOptionsFormated?.length && !disabled && !name" @click="handleContextMenuOpen" class="btn-options h-full flex items-center" data-element="button">
            <div class="btn__divider h-3/4 border-l"></div>
            <BaseIcon
                class="btn__append-icon p-1.5 shrink-0"
                :class="[{ 'btn__append-icon-dropdown-active': contextMenuVisible }]"
                :style="{ height: '24px' }"
                name="chevronDown"></BaseIcon>
        </div>
        <ContextMenu
            v-if="contextMenuVisible && submitOptionsFormated"
            :event="coordinateEvent"
            @closeContextMenu="handleContextMenuClose"
            :showFullSlot="true"
            :items="submitOptionsFormated"
            @changedValue="handleContextMenuChange"></ContextMenu>
    </button>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';

export default {
    name: 'BaseButton',
    components: {
        BaseIcon,
        ContextMenu
    },
    props: {
        type: {
            type: String,
            default: 'contained'
            //text, contained, outlined
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'medium'
            // "ss", "xs", "s", "m(default)", "l", "xl",
        },
        variant: {
            type: String,
            default: ''
            // "", "secondary", "third", "fourth", "empty", "pure", "text"
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        color: {
            type: String
            //"primary", "secondary", "error", "warning", "success", "gray"
        },
        icon: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        viewBox: {
            type: String,
            default: '0 0 24 24'
        },
        rounded: {
            type: Boolean,
            default: false
        },
        submitOptions: {
            type: Array,
            default: null
        }
    },
    emits: ['mounted', 'options-change', 'click'],
    inject: [],
    data() {
        return {
            contextMenuVisible: false,
            coordinateEvent: null,
            submitOptionsFormated: null,
            activeSubminOption: null
        };
    },
    computed: {
        sizeButton() {
            let size = 'medium';
            switch (this.size) {
                case 'ss':
                    size = 'super-small';
                    break;
                case 'xs':
                    size = 'extra-small';
                    break;
                case 's':
                    size = 'small';
                    break;
                case 'l':
                    size = 'large';
                    break;
                case 'xl':
                    size = 'extra-large';
                    break;
                default:
                    size = 'medium';
                    break;
            }
            return size;
        },
        submitOptionActive() {
            return this.submitOptionsFormated?.length ? this.submitOptionsFormated.find(el => el.active) : null;
        }
    },
    methods: {
        // Listen for click event
        createRipple(e) {
            const button = e.target.parentNode;

            // we determine the geometric dimensions
            const rect = button.getBoundingClientRect();
            const diameter = Math.max(button.clientWidth, button.clientHeight);
            const radius = diameter / 2;

            // Get position of X
            let x = e.clientX - rect.left - radius;

            // Get position of Y
            let y = e.clientY - rect.top - radius;

            // Create span element
            let ripple = document.createElement('span');

            // Add ripple class to span
            ripple.classList.add('ripple');

            // Add ripple class to span
            ripple.dataset.element = 'button';

            // Add style
            ripple.style.width = `${diameter}px`;
            ripple.style.height = `${diameter}px`;

            // Position the span element
            ripple.style.left = `${x}px`;
            ripple.style.top = `${y}px`;

            // Add span to the button
            e.target.appendChild(ripple);

            // Remove span after 0.3s
            setTimeout(() => {
                ripple.remove();
            }, 300);
        },
        focus() {
            this.$refs.baseButton?.focus();
        },
        blur() {
            this.$refs.baseButton?.blur();
        },

        handleMainButtonClick(e) {
            if (!this.disabled) {
                this.createRipple(e);
                this.$emit('click', e, this.submitOptionActive?.id);
            }
        },

        handleContextMenuOpen(e) {
            this.coordinateEvent = e;
            this.contextMenuVisible = !this.contextMenuVisible;
        },
        handleContextMenuClose() {
            this.contextMenuVisible = false;
            this.coordinateEvent = null;
        },
        formatSubmitOptions() {
            if (Array.isArray(this.submitOptions)) {
                this.submitOptionsFormated = this.submitOptions.map(({ id, title, active }) => ({
                    id: id,
                    label: title,
                    active: active,
                    type: 'button'
                }));
            }
        },
        handleContextMenuChange(contextMenuValue) {
            if (!this.disabled) {
                this.submitOptionsFormated = this.submitOptionsFormated.map(el => ({
                    ...el,
                    active: el.id === contextMenuValue.id ? true : false
                }));
                this.$emit('options-change', contextMenuValue.id);
                this.$emit('click', null, contextMenuValue.id);
            }
        }
    },
    created() {
        this.formatSubmitOptions();
    },
    mounted() {
        this.$emit('mounted');
    }
};
</script>

<style lang="scss" scoped>
.platform-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    cursor: pointer;
    overflow: hidden;

    position: relative;
    border: none;
    outline: none;
    font: inherit;
    color: #ffffff;
    fill: inherit;
    // fill: #ffffff;
    background: var(--sp-primary, #2693ff);

    font-size: 0.875rem;
    min-width: 64px;
    max-width: 100%;
    // padding: 0 16px;

    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-decoration: none;
    text-indent: 0;
    /* text-transform: capitalize; */

    transition-property: box-shadow, transform, opacity, background-color, background;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;
    vertical-align: middle;

    border-color: var(--sp-border-color);
    border-style: solid;
    border-width: 0;
    border-radius: 4px;

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: inherit;
        fill: inherit;
        /* pointer-events: none; */
    }
    .btn__divider {
        border-color: #ffffff;
        opacity: 0.5;
    }
    .btn__append-icon {
        fill: #ffffff;
    }

    &:hover,
    &:focus {
        background-color: var(--sp-primary-hover, #1e7fdf);
    }
}

.platform-button:disabled {
    // opacity: 0.4;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
}

/* variants */
.platform-button.secondary {
    color: var(--sp-primary);
    fill: var(--sp-primary);
    background-color: #bedfff;
    .btn__divider {
        border-color: var(--sp-primary);
    }
    .btn__append-icon {
        fill: var(--sp-primary);
    }
    &:hover,
    &:focus {
        background-color: #9dcfff;
    }
}
.platform-button.third {
    /* color: var(--sp-text-panel, #939da7); */
    color: #5f6368;
    fill: #5f6368;
    background-color: var(--sp-bg-item, #f8f9fb);

    .btn__divider {
        border-color: #5f6368;
    }
    .btn__append-icon {
        fill: #5f6368;
    }

    &:hover,
    &:focus {
        background-color: var(--sp-third-hover, #e0e0e0);
    }
}
.platform-button.fourth {
    color: var(--sp-primary);
    fill: var(--sp-primary);
    background-color: rgba(190, 223, 255, 0.2);
    .btn__divider {
        border-color: var(--sp-primary);
    }
    .btn__append-icon {
        fill: var(--sp-primary);
    }

    &:hover,
    &:focus {
        background-color: #9dcfff;
    }
}
.platform-button.empty {
    color: var(--sp-text-primary, #191919);
    /* background-color: var(--sp-bg-components, #ffffff); */
    background-color: transparent;
    .btn__divider {
        border-color: var(--sp-text-primary, #191919);
    }
    .btn__append-icon {
        fill: var(--sp-text-primary, #191919);
    }

    &:hover,
    &:focus {
        color: var(--sp-primary, #2693ff);
        fill: var(--sp-primary, #2693ff);
        background-color: transparent;
        .btn__divider {
            border-color: #2693ff;
        }
        .btn__append-icon {
            fill: #2693ff;
        }
    }
}
.platform-button.pure {
    color: var(--sp-text-panel, #939da7);
    fill: var(--sp-text-panel, #939da7);
    background-color: var(--sp-bg-components, #ffffff);
    .btn__divider {
        border-color: var(--sp-text-panel, #939da7);
    }
    .btn__append-icon {
        fill: var(--sp-text-panel, #939da7);
    }

    &:hover,
    &:focus {
        color: var(--sp-primary, #2693ff);
        fill: var(--sp-primary, #2693ff);
        .btn__divider {
            border-color: var(--sp-primary, #2693ff);
        }
        .btn__append-icon {
            fill: var(--sp-primary, #2693ff);
        }
    }
}
.platform-button.accent {
    color: var(--sp-primary, #2693ff);
    background-color: transparent;
    .btn__divider {
        border-color: var(--sp-primary, #2693ff);
    }
    .btn__append-icon {
        fill: var(--sp-primary, #2693ff);
    }
    &:hover,
    &:focus {
        background-color: var(--sp-third-hover, #e0e0e0);
    }
}
.platform-button.text {
    color: var(--sp-primary);
    fill: var(--sp-primary);
    background-color: transparent;
    .btn__divider {
        border-color: var(--sp-primary, #2693ff);
    }
    .btn__append-icon {
        fill: var(--sp-primary, #2693ff);
    }
    &:hover,
    &:focus {
        background-color: rgba(190, 223, 255, 0.2);
    }
}

/* color */
.platform-button.gray {
    color: #c0c2cd;

    fill: #c0c2cd;
    background-color: transparent;
    .btn__divider {
        border-color: #c0c2cd;
    }
    .btn__append-icon {
        fill: #c0c2cd;
    }
    &:hover,
    &:focus {
        background: rgba(81, 157, 245, 0.1);
        fill: var(--color-modern-primary);
    }
}
.platform-button.success {
    color: #000;
    background-color: #28f10d49;
    .btn__divider {
        border-color: #000;
    }
    .btn__append-icon {
        fill: #000;
    }
    &:hover,
    &:focus {
        background-color: #28f10dd7;
    }
}
.platform-button.warning {
    background-color: var(--sp-warning, #fbc12e);
    &:hover,
    &:focus {
        background-color: #be9324;
    }
}
.platform-button.error {
    color: #000;
    background-color: #f5405e4d;
    .btn__divider {
        border-color: #000;
    }
    .btn__append-icon {
        fill: #000;
    }
    &:hover,
    &:focus {
        background-color: #f5405ed7;
    }
}

/* size */
.platform-button.super-small {
    height: 16px;
    font-size: 0.5rem;
    min-width: 36px;
    & .btn-container {
        padding: 0 4px;
    }

    &.sp-btn-icon {
        width: 15px;
        height: 15px;
        // padding: 2px;
        .btn__icon {
            padding: 1px;
        }
    }
}
.platform-button.extra-small {
    height: 22px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
    min-width: 22px;

    & .btn-container {
        padding: 4px 8px;
    }

    &.sp-btn-icon {
        width: 22px;
        height: 22px;
        // padding: 3px;
        .btn__icon {
            padding: 3px;
        }
    }
}
.platform-button.small {
    height: 28px;
    font-size: 0.875rem;
    min-width: 50px;

    & .btn-container {
        padding: 0 12px;
    }

    &.sp-btn-icon {
        width: 28px;
        height: 28px;
        // padding: 4px;
        .btn__icon {
            padding: 5px;
        }
    }
}
.platform-button.medium {
    /* height: 36px; */
    font-size: 14px;
    font-weight: 500;
    min-width: 64px;

    & .btn-container {
        padding: 10px 10px;
    }

    &.sp-btn-icon {
        width: 34px;
        height: 34px;

        .btn__icon {
            padding: 8px;
        }
    }
}
.platform-button.large {
    height: 44px;
    font-size: 1rem;
    min-width: 78px;

    & .btn-container {
        padding: 0 20px;
    }

    &.sp-btn-icon {
        width: 40px;
        height: 40px;
        .btn__icon {
            padding: 9px;
        }
    }
}
.platform-button.extra-large {
    height: 52px;
    font-size: 1.125rem;
    min-width: 92px;

    & .btn-container {
        padding: 0 24px;
    }

    &.sp-btn-icon {
        width: 52px;
        height: 52px;
        .btn__icon {
            padding: 10px;
        }
    }
}

/* icon */
.platform-button.sp-btn-icon {
    overflow: hidden;
    border-radius: 50%;
    min-width: 0;
    // padding: 4px;
    & .btn-container {
        padding: 0;
    }
    /* color: var(--sp-primary);
  fill: var(--sp-primary); */
    /* background-color: #bedfff; */

    &:hover,
    &:focus {
        /* background-color: #9dcfff; */
    }
}

/* full width */
.platform-button.full-width {
    width: 100%;
}

/* rounded */
.platform-button.rounded {
    border-radius: 9999px;
}
</style>

<style>
.platform-button span.ripple {
    position: absolute;
    border-radius: 50%;
    /* To make it round */
    background-color: rgba(0, 0, 0, 0.3);
    /* background-color: red; */

    width: 100px;
    height: 100px;
    /* margin-top: -110px; */
    /* for positioning */
    /* margin-left: 103px; */

    animation: ripple 1s;
    opacity: 0;
}

/* Add animation */
@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(10);
    }
}
.btn__append-icon {
    transition: transform 0.1s;
}
.btn__append-icon-dropdown-active {
    transform: rotate(180deg);
}
</style>
