import { RRule } from 'rrule';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
const localizedWeekdays = require('dayjs/plugin/localizedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const weekday = require('dayjs/plugin/weekday');

dayjs.extend(weekday);
dayjs.extend(localizedWeekdays);
dayjs.extend(customParseFormat);

function translate(value) {
    return useI18n().t(value);
}

const freq = ['yearly', 'monthly', 'weekly', 'daily', 'hourly', 'minutely', 'secondly'];
const intervalFreq = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
const onceFreq = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
const workDays = [0, 1, 2, 3, 4];
// const byWeekDay = ["mo", "tu", "we", "th", "fr", "sa", "su"];
// const wkst= ["mo", "tu", "we", "th", "fr", "sa", "su"];

export function parseRRule(rruleString) {
    return RRule.fromString(rruleString);
}

export function getRRuleOption(rruleString, day) {
    // console.log(
    //   "getRruleOption:",
    //   rruleString,
    //   day,
    //   parseRRule(rruleString).origOptions
    // );
    const title = getRuleTitle(rruleString, day);
    return {
        id: `${rruleString}+${day}`,
        label: title,
        rrule: rruleString,
        dayStart: day
    };
}

function getRuleTitle(rruleString, day) {
    const rruleObj = parseRRule(rruleString).origOptions;
    let title = getFrequency(rruleObj, day);
    if (rruleObj?.freq === 1 && !rruleObj?.byweekday?.length) title += getDayOfMonth(day);
    if (rruleObj?.byweekday?.length) {
        title += getDays(rruleObj);
    }
    if (rruleObj?.freq === 0) {
        title += ` ${dayjs(day).format('MMMM DD')}`;
    }
    return title;
}
function getFrequency(rruleObj) {
    if (rruleObj?.interval > 1) {
        return `${translate(`calendar.every`)} ${rruleObj?.interval} ${translate(`calendar.${intervalFreq[rruleObj.freq]}`)}`;
    } else {
        return translate(`calendar.${freq[rruleObj.freq]}`);
    }
}

function getAndSortWeekDays(arrWeekDays) {
    return arrWeekDays.map(item => item.weekday).sort((a, b) => a - b);
}
function sortWeekDaysObj(arrWeekDays) {
    return arrWeekDays.sort((a, b) => a.weekday - b.weekday);
}
function getDays(rruleObj) {
    const weekDaysObj = sortWeekDaysObj(rruleObj?.byweekday);

    const weekDays = getAndSortWeekDays(rruleObj?.byweekday);
    const isIncludesAllWorkDays = workDays.every(v => weekDays.includes(v));
    if (isIncludesAllWorkDays) return ` ${translate(`calendar.workingDays`)}`;

    let days = ' - ';

    weekDaysObj.map((item, idx) => {
        if (idx === 0) days += getWeekDay(item.weekday);
        else days += ` ,${getWeekDay(item.weekday)}`;

        const value = translate(`calendar.${onceFreq[rruleObj.freq + 1]}`);
        if (item?.n) {
            days += ` ${translate(`calendar.each`)} ${item.n} ${value}`;
        }
    });
    return days;
}
function getWeekDay(idx) {
    return dayjs()
        .day(idx + 1)
        .format('dddd');
}
function getDayOfMonth(day) {
    if (!day) return '';
    const monthDay = dayjs(day).date();
    return ` ${monthDay}${translate(`calendar.th`)}`;
}

export function createRRuleOptions(day) {
    const weeKDay = dayjs(day).locale('en').format('dd').toUpperCase();
    const weekOfMonth = Math.ceil(dayjs(day).date() / 7);

    const dailyRule = createRRule(getRRuleFreq('daily'), day);

    const byWeekDayWeekly = RRule[weeKDay];
    const weeklyRule = createRRule(getRRuleFreq('weekly'), day, byWeekDayWeekly);

    const byWeekDayMonthly = RRule[weeKDay].nth(weekOfMonth);
    const monthlyRule = createRRule(getRRuleFreq('monthly'), day, byWeekDayMonthly);

    const yearlyRule = createRRule(getRRuleFreq('yearly'), day);

    const byWeekDayWorkingDays = createWeekdayRRuleForWorkingDays();
    const wkStart = RRule[dayjs().weekday(6).locale('en').format('dd').toUpperCase()];
    const weeklyWorkingDaysRule = createRRule(getRRuleFreq('weekly'), day, byWeekDayWorkingDays, wkStart);

    const rruleOptions = [
        {
            id: `${dailyRule}+${day}`,
            label: getRuleTitle(dailyRule.toString(), day),
            rrule: dailyRule.toString(),
            dayStart: day
        },
        {
            id: `${weeklyRule}+${day}`,
            label: getRuleTitle(weeklyRule.toString(), day),
            rrule: weeklyRule.toString(),
            dayStart: day
        },
        {
            id: `${monthlyRule}+${day}`,
            label: getRuleTitle(monthlyRule.toString(), day),
            rrule: monthlyRule.toString(),
            dayStart: day
        },
        {
            id: `${yearlyRule}+${day}`,
            label: getRuleTitle(yearlyRule.toString(), day),
            rrule: yearlyRule.toString(),
            dayStart: day
        },
        {
            id: `${weeklyWorkingDaysRule}+${day}`,
            label: getRuleTitle(weeklyWorkingDaysRule.toString(), day),
            rrule: weeklyWorkingDaysRule.toString(),
            dayStart: day
        }
    ];
    // console.log("createRRuleOptions rruleOptions:", rruleOptions);
    return rruleOptions;
}

function getRRuleFreq(value) {
    return freq.findIndex(item => item === value);
}
function createWeekdayRRuleForWorkingDays() {
    let weekdays = [];
    workDays.map(el => {
        const weeKDay = dayjs().weekday(el).locale('en').format('dd').toUpperCase();
        weekdays.push(RRule[weeKDay]);
    });
    return weekdays;
}

function createRRule(frequency, timestamp, byWeekDay, wkStart) {
    // console.log("createRRule", frequency, timestamp, byWeekDay, wkStart);
    const rule = new RRule({
        freq: frequency,
        byweekday: byWeekDay
    });
    if (timestamp)
        rule.dtstart = new Date(
            dayjs(timestamp).year(),
            dayjs(timestamp).month(),
            dayjs(timestamp).date(),
            dayjs(timestamp).hour(),
            dayjs(timestamp).minute(),
            dayjs(timestamp).second()
        );
    if (byWeekDay) rule.byweekday = byWeekDay;
    if (wkStart) rule.wkst = wkStart;
    return rule;
}
