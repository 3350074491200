<template>
    <div
        @mouseenter="toggleActiveIndex(index)"
        :class="['multiselect__dropdown-element', { 'multiselect__dropdown-element_active': isActive }]"
        @mousedown.stop
        @mouseup.stop
        @click.stop="
            () => {
                toggleSelection(props.element);
                toggleActiveIndex(index);
            }
        ">
        <div class="multiselect__dropdown-element-body">
            <div v-if="props.multiple && Array.isArray(props.value)" class="multiselect__dropdown-element-checkbox pointer-events-none">
                <BaseCheckbox :value="checked" />
            </div>
            <div v-if="$slots.prefix" class="multiselect__dropdown-element-prefix">
                <slot name="prefix" :item="props.element"></slot>
            </div>
            <div class="multiselect__dropdown-element-title-container">
                <div class="multiselect__dropdown-element-title">
                    {{ props.object ? element[props.labelProp] : element }}
                </div>
                <slot name="content" :item="props.element"></slot>
            </div>
        </div>
        <div v-if="$slots.suffix" class="multiselect__dropdown-element-suffix">
            <!-- <div v-if="$slots.suffix || props.element?.[props.groupLabel]" class="multiselect__dropdown-element-suffix"> -->
            <!-- <span class="multiselect__dropdown-element-group-title" v-if="props.element?.[props.groupLabel]">{{ props.element?.[props.groupLabel] }}</span> -->
            <slot name="suffix" :item="props.element"></slot>
        </div>
        <div v-if="!props.multiple && !Array.isArray(props.value) && checked" class="multiselect__dropdown-element-check">
            <BaseIcon class="fill-[#519DF5] h-[24px] w-[24px]" name="tick-02" />
        </div>
    </div>
</template>

<script setup>
import { inject, computed } from 'vue';
import { BaseCheckbox } from 'uikit';
import BaseIcon from '@/components/ui/BaseIcon.vue';

import { InjectionKeyToggleSelection, InjectionKeyToggleActiveIndex } from '../keys.js';
const toggleSelection = inject(InjectionKeyToggleSelection);
const toggleActiveIndex = inject(InjectionKeyToggleActiveIndex);
const props = defineProps({
    multiple: {
        type: Boolean,
        default: false
    },
    element: {
        type: [Object, Array, String, Number, null],
        default: () => []
    },
    value: {
        type: [Object, Array, String, Number, null],
        default: () => []
    },
    isActive: {
        type: Boolean,
        default: false
    },
    index: {
        type: Number,
        default: 1
    },
    labelProp: {
        type: String,
        default: 'label'
    },
    valueProp: {
        type: String,
        default: 'id'
    },
    object: {
        type: Boolean,
        default: false
    },
    valueTypeObject: {
        type: Boolean,
        default: false
    },
    groupLabel: {
        type: String,
        default: null
    }
});
const checked = computed(() => {
    if (props.multiple && Array.isArray(props.value)) {
        return props.object && props.valueTypeObject
            ? props.value.some(el => el[props.valueProp] === props.element[props.valueProp])
            : props.value.includes(props.object ? props.element[props.valueProp] : props.element);
    } else if (!props.multiple && !Array.isArray(props.value)) {
        return props.object && props.valueTypeObject
            ? props.value?.[props.valueProp] === props.element[props.valueProp]
            : props.value === (props.object ? props.element[props.valueProp] : props.element);
    } else return false;
});
</script>

<style scoped>
.multiselect__dropdown-element {
    min-height: 32px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    /* align-items: center; */
    padding: 4px 0;
    padding-left: 14px;
    padding-right: 16px;
    border-left: 2px solid transparent;
}

.multiselect__dropdown-element_active {
    background-color: var(--sp-bg-text-field-blue-active);
    border-left: 2px solid #519df5;
}
.multiselect__dropdown-element-body {
    display: flex;
    /* align-items: center; */
}
.multiselect__dropdown-element-checkbox {
    margin-right: 8px;
    max-height: 24px;
    display: flex;
    align-items: center;
}
.multiselect__dropdown-element-title-container {
    display: flex;
    flex-direction: column;
}
.multiselect__dropdown-element-title {
    display: flex;
    align-items: center;
    color: #131416;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    word-break: break-word;
}
.multiselect__dropdown-element-group-title {
    font-size: 14px;
    line-height: 24px;
    color: #979bb2;
}
.multiselect__dropdown-element-prefix {
    margin-right: 10px;
}
.multiselect__dropdown-element-suffix {
    margin-left: 8px;
}
.multiselect__dropdown-element-check {
    margin-left: 8px;
}
</style>
