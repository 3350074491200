<template>
    <div class="relative">
        <BaseButton color="third" variant="text" size="sm" @click="openNotificationBlock($event)" v-tooltip:bottom="$t('tooltip.notification')">
            <template #icon>
                <BaseIcon name="notification-01" class="h-[24px] w-[24px]"></BaseIcon>
            </template>
        </BaseButton>
        <RoundBadge v-if="notReadNotificationsCount" class="absolute top-0 translate-y-[-20%] right-0 translate-x-[20%] pointer-events-none" color="danger" size="sm">
            {{ notReadNotificationsCount }}
        </RoundBadge>
    </div>
    <base-modal
        v-if="showNotificationBlock"
        name="contex-menu"
        v-model="showNotificationBlock"
        :input-event="notificationBlockEvent"
        zindex="100"
        :content-width="width + 'px'"
        content-height="85dvh"
        content-type="context-menu"
        :position="isMobile ? 'center' : 'left'">
        <div :class="`notification__list w-[${width} px] h-[85dvh]`">
            <div class="list__header flex items-center" :class="[isMobile ? 'px-1' : 'px-4']">
                <div class="header__block flex items-center w-full">
                    <div class="flex" :class="[isMobile ? 'gap-[5px]' : 'gap-[15px]']">
                        <button
                            v-for="notifications in notificationsButtons"
                            :key="notifications.id"
                            @click="changeNotifications(notifications)"
                            class="header__btn flex gap-[7.5px] view text-sm px-[2px] pb-2 pt-4 self-end hover:text-primary"
                            :class="{ active: notifications.id === currentNotificationType }">
                            {{ notifications?.label }} <span class="text-gray5">{{ notifications?.length }}</span>
                        </button>
                    </div>

                    <div class="header__menu-btn ml-auto"></div>
                    <BaseButton v-if="sortedNotifications?.length" color="third" variant="text" size="sm" @click="openSubmenu($event)">
                        <template #icon>
                            <BaseIcon name="more-horizontal" class="h-[24px] w-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                    <BaseButton color="third" variant="text" size="sm" @click="closeNotificationBlock">
                        <template #icon>
                            <BaseIcon name="Clouse" class="h-[24px] w-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                </div>
            </div>

            <div class="row-list" id="taskList" v-if="currentNotification?.length">
                <platform-list :data="sortedNotifications">
                    <template v-slot:item="{ item }">
                        <notifications-item
                            :key="item.id"
                            :item="item"
                            @delete-notification="deleteNotification"
                            @mark-as-read="readNotification"
                            @mark-as-unread="markAsUnreadNotification"
                            @open-notification-instance="openNotificationInstance"
                            @close-notification-block="closeNotificationBlock"></notifications-item>
                    </template>
                </platform-list>
            </div>

            <div v-if="!currentNotification?.length && notifications?.length" class="empty--list">
                {{ $t('notifications.youveReadAllYourNotifications') }}
            </div>
            <div v-if="!notifications?.length" class="empty--list text-center p-3">
                {{ $t('notifications.youHaveNoNotifications') }}
            </div>
        </div>
    </base-modal>
    <base-dialog v-model="showClearDialog" contentWidth="500px" :showOwerlay="true" @modal-close="closeClearDialog">
        <h3 class="text-base font-semibold">{{ $t('modal.clearAllNotification') }}?</h3>
        <span>{{ $t('modal.itWillNotBePossibleToCancelThisOperation') }}!</span>
        <div class="flex w-full justify-end mt-5">
            <BaseButton class="dialog__btn" variant="base" color="success" size="sm" @click="clearCurrentNotifications" :label="$t('buttons.clear')" />

            <BaseButton class="dialog__btn ml-2" variant="text" size="sm" @click="closeClearDialog" :label="$t('buttons.cancel')" />
        </div>
    </base-dialog>

    <ContextMenu v-if="showSubmenu" :event="submenuEvent" :items="submenuItems" @changedValue="onSubmenuBtnClick" @closeContextMenu="closeSubmenu"> </ContextMenu>
</template>

<script>
import BaseModal from '@/components/ui/BaseModal.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import PlatformList from '@/components/ui/platformList/PlatformList.vue';
import NotificationsItem from '@/components/platformNotifications/NotificationsItem.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import RoundBadge from '@/components/ui/RoundBadge.vue';

import { mapState, mapActions } from 'pinia';
import { useNotificationsStore } from '@/stores/notificationsStore';
import { useTasksStore } from '@/stores/tasksStore';
import { useUserStore } from '@/stores/userStore';
import { useEntitysStore } from '@/stores/entityStore';
import { addEvent } from '../../../analytics/AnalyticsService';

export default {
    name: 'TopbarNotifications',
    components: {
        PlatformList,
        NotificationsItem,
        BaseButton,
        BaseIcon,
        BaseDialog,
        ContextMenu,
        BaseModal,
        RoundBadge
    },
    props: {},

    data() {
        return {
            showNotificationBlock: false,
            notificationBlockEvent: null,
            onlyShowUnread: true,
            showClearDialog: false,
            showInstanceModal: false,
            entityId: null,
            instanceId: null,
            currentNotificationType: 'unread',

            showSubmenu: false,
            submenuEvent: null,
            width: 530,

            readMenuItems: [
                {
                    id: 'markAllAsUnread',
                    label: this.$t('buttons.markAllAsUnread'),
                    type: 'button',
                    icon: 'mail-02'
                },
                {
                    id: 'deleteAllNotifications',
                    label: this.$t('buttons.deleteAllNotifications'),
                    type: 'button',
                    icon: 'delete-02'
                }
            ],
            unreadMenuItems: [
                {
                    id: 'markAllAsRead',
                    label: this.$t('buttons.markAllAsRead'),
                    type: 'button',
                    icon: 'tick-02'
                },
                {
                    id: 'deleteAllUnreadNotifications',
                    label: this.$t('buttons.deleteAllUnreadNotifications'),
                    type: 'button',
                    icon: 'delete-02'
                }
            ]
        };
    },
    watch: {
        onlyShowUnread(newVal) {
            if (!newVal) {
                this.getNotifications();
            }
        }
    },
    computed: {
        ...mapState(useNotificationsStore, ['notifications', 'initialNotifications']),
        ...mapState(useUserStore, ['isMobile']),
        notReadNotificationsCount() {
            if (this.notifications?.length) {
                let notRead = this.notifications.filter(el => !el.read);
                if (notRead?.length > 0) {
                    return notRead?.length;
                } else if (notRead?.length > 100) {
                    return '99+';
                }
                return null;
            } else return null;
        },
        unreadNotifications() {
            let notRead = [];
            if (this.notifications?.length) {
                notRead = this.notifications.filter(el => !el.read);
            }
            return notRead;
        },
        currentNotification() {
            if (this.onlyShowUnread) return this.unreadNotifications;
            return this.notifications;
        },
        sortedNotifications() {
            function compare(a, b) {
                let atrA = a.created_time;
                let atrB = b.created_time;
                // let atrA = a.datetime;
                // let atrB = b.datetime;
                if (atrA > atrB) return -1;
                if (atrA == atrB) return 0;
                if (atrA < atrB) return 1;
            }

            if (this.currentNotification && this.currentNotification.length > 0) {
                let notification = [...this.currentNotification];
                return notification.sort(compare);
            }
            return [];
        },
        submenuItems() {
            if (this.onlyShowUnread) return this.unreadMenuItems;
            return this.readMenuItems;
        },
        notificationsButtons() {
            return [
                {
                    id: 'all',
                    label: this.$t('buttons.allNotifications'),
                    length: this.initialNotifications?.length,
                    open: () => this.openAllNotifications()
                },
                {
                    id: 'unread',
                    label: this.$t('buttons.unread'),
                    length: this.unreadNotifications?.length,
                    open: () => this.openUnread()
                }
            ];
        }
    },
    methods: {
        ...mapActions(useNotificationsStore, [
            'getNewNotifications',
            'getNotifications',
            'addNotifications',
            'updateNotifications',
            'deleteNotifications',
            'markNotificationsAs',
            'insertNotificationsByWS',
            'updateNotificationsByWS',
            'deleteNotificationsByWS'
        ]),
        ...mapActions(useTasksStore, ['loadTasksByPin', 'updateTask']),
        ...mapActions(useUserStore, ['getEnvironmentByName']),
        ...mapActions(useEntitysStore, ['getEntityCard']),
        deleteNotification(id) {
            this.deleteNotifications([id]);
        },
        changeNotifications(notifications) {
            this.currentNotificationType = notifications.id;
            notifications?.open();
        },
        readNotification(id) {
            this.markNotificationsAs('read', [id]);
        },
        markAsUnreadNotification(id) {
            this.markNotificationsAs('unread', [id]);
        },
        markAllAs() {
            const allNotificationId = this.getCurrentNotificationId();
            if (this.onlyShowUnread) {
                this.markNotificationsAs('read', allNotificationId);
            } else {
                this.markNotificationsAs('unread', allNotificationId);
            }
        },

        openNotificationBlock(event) {
            this.updateWidth();
            this.notificationBlockEvent = event;
            this.showNotificationBlock = true;
            addEvent({
                category: 'Navigation Panel',
                action: 'lmbc',
                label: 'Open notifications card',
                entity: 'notification',
                instance: ''
            });
        },
        openUnread() {
            this.onlyShowUnread = true;
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Clicked to show Unreaded notifications',
                entity: 'notification',
                instance: ''
            });
        },
        openAllNotifications() {
            this.onlyShowUnread = false;
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Clicked All notifications button',
                entity: 'notification',
                instance: ''
            });
        },

        openNotificationInstance(notifications) {
            const cardName = this.getEntityCard(notifications.entity_id);
            console.log('openNotificationInstance', notifications);
            const param = {
                name: cardName,
                id: notifications.instance_id,
                entityId: notifications.entity_id,
                system: notifications.entity_id === 'CRMActivities' ? true : false,
                props: {
                    id: notifications.instance_id,
                    entityId: notifications.entity_id,
                    scrollToActivity: notifications?.activity_data?.activity_id
                },
                events: {}
            };
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Click to open notification',
                entity: 'notification',
                instance: notifications.instance_id
            });
            this.$modal.open(param);
            this.closeNotificationBlock();
        },
        closeNotificationBlock() {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Close Notification card',
                entity: 'notification',
                instance: ''
            });
            this.notificationBlockEvent = null;
            this.showNotificationBlock = false;
        },
        openClearDialog() {
            this.showClearDialog = true;
        },
        closeClearDialog() {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Close clear notifications dialogue',
                entity: 'notification',
                instance: ''
            });
            this.showClearDialog = false;
        },

        openSubmenu(e) {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Open submenu',
                entity: 'notification',
                instance: ''
            });
            this.submenuEvent = e;
            this.showSubmenu = true;
        },
        closeSubmenu() {
            this.showSubmenu = false;
            this.submenuEvent = null;
        },
        onSubmenuBtnClick(obgValue) {
            if (obgValue && obgValue.id) {
                addEvent({
                    category: 'Notification card',
                    action: 'lmbc',
                    label: 'Clicked submenu button ' + obgValue.id,
                    entity: 'notification',
                    instance: ''
                });
            }
            switch (obgValue?.id) {
                case 'markAllAsRead':
                    this.markAllAs();
                    break;
                case 'markAllAsUnread':
                    this.markAllAs();
                    break;
                case 'deleteAllNotifications':
                    this.openClearDialog();
                    break;
                case 'deleteAllUnreadNotifications':
                    this.clearCurrentNotifications();
                    break;

                default:
                    break;
            }
        },

        async clearCurrentNotifications() {
            const allNotificationId = this.getCurrentNotificationId();
            this.deleteNotifications(allNotificationId);
        },
        getCurrentNotificationId() {
            const allNotificationId = this.sortedNotifications.reduce((allId, { id }) => {
                allId.push(id);
                return allId;
            }, []);
            return allNotificationId;
        },
        updateWidth() {
            if (this.isMobile) {
                this.width = Math.floor(0.9 * window.innerWidth);
            }
        }
    },
    async created() {
        this.getNewNotifications();
    },
    mounted() {
        this.updateWidth();
        this.getNotifications();
    },
    sockets: {
        notice(payload) {
            if (payload.event) {
                switch (payload.event) {
                    case 'insert':
                        this.insertNotificationsByWS(payload.data);
                        break;
                    case 'update':
                        console.log('Update notice by WS', payload.data);
                        this.updateNotificationsByWS(payload.data);
                        break;
                    case 'delete':
                        this.deleteNotificationsByWS(payload.data);
                        break;
                    default:
                        break;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.close-icon {
    width: 15px;
    margin-top: 7px;
    margin-right: 10px;
}

.list__header {
    box-shadow: 0px 2px 15px 0px #0000001a;

    .header__block {
        .header__btn {
            position: relative;

            &.active {
                color: var(--primary);

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary);
                }
            }
        }
    }
}

.checkbox {
    margin-right: auto;
}

.button {
    margin-left: 8px;
}

.button.close {
    position: absolute;
    top: 4px;
    right: 4px;
}

.row-list {
    height: calc(100% - 48px);
    overflow-y: auto;
    position: relative;
}

.empty--list {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 25vh;
    max-height: 80vh;
}

.dialog__btn {
    & + & {
        margin-left: 0.5rem;
    }
}
</style>
