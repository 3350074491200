import { sendRequest } from '@/helpers';
let requestFields = {
    id: {},
    name: {},
    responsible: {
        id: {},
        name: {},
        photo_url: {},
        work_position: {}
        // first_name:{},
        // last_name:{}
    },
    created_by: {
        id: {},
        name: {},
        photo_url: {}
    },
    stage: {
        id: {},
        name: {},
        workflow_id: {},
        color: {}
    },
    groups: {
        id: {},
        name: {}
    },
    status: {},
    has_children: {},
    start_date: {},
    created_time: {},
    end_date: {},
    duration: {},
    parent_id: {},
    thumbnail_url: {},
    children_count: {},
    version: {},
    priority: {}
};
export default {
    //   async getEntityTodo(entityId){
    //   return await sendRequest(`/api/todo/list/${entityId}`, "get", '', null, true);
    // },
    async getEntityTodo(entityId) {
        let url = `/api/instances/search/${entityId}`;
        let requestParams = {
            filter: {
                operator: 'and',
                type: 'conjunction',
                conditions: [{ field: '#todo', comparator: '=', value: 'true', type: 'condition' }]
            },
            fields: requestFields
        };
        return await sendRequest(url, 'post', requestParams, null, true, entityId);
    },
    async addToTodo(todoInstance) {
        return await sendRequest(`/api/todo/`, 'post', todoInstance);
    },
    async checkIfInstanseInTodoList(entityId, instanceId) {
        return await sendRequest(`/api/todo/is/${entityId}/${instanceId}`, 'get', '');
    },
    async removeFromTodo(todoId) {
        return await sendRequest(`/api/todo/${todoId}`, 'delete', '');
    }
};
