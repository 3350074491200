import { sendRequest } from '@/helpers';

export default {
    async getEntityComments(entity_id, instance_id) {
        return await sendRequest(`/api/comments/${entity_id}/${instance_id}`, 'get', '');
    },

    async getCommentById(id) {
        return await sendRequest(`/api/comment/${id}`, 'get', '');
    },
    async saveEntityComments(data) {
        return await sendRequest(`/api/comment/`, 'post', data);
    },
    async deleteEntityComments(id) {
        return await sendRequest(`/api/comment/${id}`, 'delete', '');
    },
    async updateEntityComments(id, data) {
        return await sendRequest(`/api/comment/${id}`, 'put', data);
    },

    async getReaction(id, userId) {
        return await sendRequest(`/api/comment/like/${id}/${userId}`, 'get', '');
    },
    async createReaction(data) {
        return await sendRequest('/api/comment/like/', 'post', data);
    },
    async deleteReaction(id, userId) {
        return await sendRequest(`/api/comment/like/${id}/${userId}`, 'delete', '');
    },
    async updReaction(id, userId, data) {
        return await sendRequest(`/api/comment/like/${id}/${userId}`, 'put', data);
    }
};
