import { sendRequest } from '@/helpers';
// import { useUserStore } from "@/stores/userStore";

export default {
    async getMyPlanColumns(showLoader = true) {
        // const userStore = useUserStore();
        // const userId = userStore.user?.id;
        return await sendRequest(`/api/kanban/my_plan/list`, 'get', '', null, showLoader);
    },
    async getKanbanHeaders(entity_id, requestParams, showLoader = true) {
        return await sendRequest(`/api/kanban/head/${entity_id}`, 'post', requestParams, null, showLoader);
    },
    async getKanbanHeadersByAlias(entity_id, requestParams, showLoader = true) {
        return await sendRequest(`/api/instances/alias/kanban/head/${entity_id}`, 'post', requestParams, null, showLoader);
    },
    async getKanbanColumnItems(entity_id, requestParams = {}, showLoader = true) {
        return await sendRequest(`/api/kanban/instances/${entity_id}`, 'post', requestParams, null, showLoader);
    },
    async MyPlanItemMove(requestParams, showLoader = true) {
        return await sendRequest(`/api/kanban/my_plan/move`, 'put', requestParams, null, showLoader);
    },
    async createColumn(newColumn, workflow_id, showLoader = true) {
        return await sendRequest(`/api/kanban/my_plan/stage/add/${workflow_id}`, 'post', newColumn, null, showLoader);
    },
    async updateColumn(stage_id, newData, showLoader = true) {
        return await sendRequest(`/api/kanban/my_plan/stage/update/${stage_id}`, 'put', newData, null, showLoader);
    },
    async deleteColumn(workflow_id, stage_id, showLoader = true) {
        return await sendRequest(`/api/kanban/my_plan/stage/${workflow_id}/${stage_id}`, 'delete', '', null, showLoader);
    }
};
