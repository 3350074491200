import { sendRequest } from '@/helpers';

export default {
    async getEntities() {
        return await sendRequest(`/api/form/entities`, 'get', '');
    },
    async createNewForm(data) {
        return await sendRequest(`/api/form`, 'post', data);
    },
    async getFormById(formId) {
        return await sendRequest(`/api/form_view/${formId}`, 'get', '');
    }
};
