import { createApp, markRaw, ref, reactive } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import './helpers/registerGlobalFunctions.js';
import router from './router';
import './assets/styles/app.scss';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { createI18n } from 'vue-i18n';
import { getUserLocale, loadLocaleMessages } from './i18n';
import AlertComponent from './components/platformAlert';
// import FloatingVue from 'floating-vue';
import VueApexCharts from 'vue3-apexcharts';
import VueCookies from 'vue-cookies';
import 'floating-vue/dist/style.css';
import 'uikit/dist/style.css';
import { registerGlobalComponents } from '@/globalComponents/registerGlobalComponent';
// @ts-ignore
import { AnalyticsService } from '/analytics/AnalyticsService.js';
// FloatingVue.options.themes.tooltip.disabled = window.innerWidth <= 768;
const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
});
const userLocale = getUserLocale();
const baseURL = process.env.VUE_APP_API_URL;
export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: userLocale,
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
});
import { io } from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import directives from './directives/index';

import {
    // Directives
    // vTooltip,
    vClosePopper,
    // Components
    Dropdown,
    Tooltip,
    Menu
} from 'floating-vue';

import vTooltip from '@/directives/MyTooltip/index.js';

export const app = createApp(App);
app.use(VueApexCharts).use(pinia).use(i18n).use(VueAxios, axios).use(router).use(AlertComponent).use(VueCookies, { expires: '7d' });
directives(app);

app.directive('tooltip', vTooltip);
app.directive('close-popper', vClosePopper);

app.component('VDropdown', Dropdown);
app.component('VTooltip', Tooltip);
app.component('VMenu', Menu);

import { useUserStore } from '@/stores/userStore';
import { EngineModalComponent } from '@/globalComponents/engineModalComponent';
import { CursorLoader } from '@/globalComponents/cursorLoader';

const userStore = useUserStore();

function connectToWS() {
    if (userStore?.WStoken) {
        const options = {
            auth: {
                token: userStore.WStoken
            }
        };
        const socket = io(baseURL as string, options);
        socket.on('connect', () => {
            analytics.addEvent({
                category: 'Web Socket',
                action: 'network',
                label: 'WS connected',
                entity: '',
                instance: socket.id
            });
            userStore.setSocketID(socket.id);
        });
        socket.on('disconnect', () => {
            console.error('WS disconnect', socket.id); // undefined
            analytics.addEvent({
                category: 'Web Socket',
                action: 'network',
                label: 'WS disconnect',
                entity: '',
                instance: socket.id
            });
        });
        app.use(VueSocketIOExt, socket);
        app.config.globalProperties.$soketio = socket;
    }
}

if (userStore?.WStoken) {
    connectToWS();
} else {
    app.config.globalProperties.$soketio = connectToWS;
}
// app.config.unwrapInjectedRef = true;
const show = ref(false);
app.config.globalProperties.$loaderSpiner = reactive({ show });
app.provide('axios', app.config.globalProperties.axios);

app.config.globalProperties.$isMobile = { value: false };

app.config.globalProperties.$modal = new EngineModalComponent();
app.config.globalProperties.$cursorLoader = new CursorLoader();
const analytics = new AnalyticsService(
    process.env.VUE_APP_ANALYTICS_SERVICE_TOKEN,
    process.env.VUE_APP_ANALYTICS_DB_NAME,
    process.env.VUE_APP_ANALYTICS_SERVICE_URL,
    process.env.VUE_APP_ANALYTICS_SERVICE_COUNT_EVENTS || 50,
    window.location.hostname,
    userStore
);
app.config.globalProperties.$analytics = analytics;

if (process.env.VUE_APP_ANALYTICS_LOG_ERRORS && window.location.hostname !== 'localhost') {
    app.config.errorHandler = analytics.sendErrorLog.bind(analytics);
}

registerGlobalComponents(app);

window.addEventListener('error', event => {
    if (event.message.includes('Loading chunk')) {
        console.error('Chunk load error detected:', event.message);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
});

window.addEventListener('unhandledrejection', event => {
    if (event.reason && event.reason.message && event.reason.message.includes('Loading chunk')) {
        console.error('Chunk load error detected:', event.reason.message);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration('/serviceUpdateWorker.js').then(registration => {
        if (registration) {
            registration.unregister().then(successful => {
                if (successful) {
                    console.log('Service Worker unregistered successfully:', registration.scope);
                }
            });
        }
    });
}

// app.component("CustomElement", CustomElement);
// app.component("BuilderCustomElement", BuilderCustomElement);
// app.component("fieldGroup", fieldGroup);
// app.component("fieldBuilderGroup", fieldBuilderGroup);

app.mount('#app');
console.warn('app version', app.version);
userStore.checkTimezone();
