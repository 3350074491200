import { useUserStore } from '@/stores/userStore';
import { sendRequest } from '@/helpers';
import commentsApi from './comments';
import devCustomFileld from './devdata/tasks/customFields.json';
import { hasProperty } from '../helpers/common';
let requestFields = {
    id: {},
    name: {},
    responsible: {
        id: {},
        name: {},
        photo_url: {},
        work_position: {},
        is_active: {}
    },
    created_by: {
        id: {},
        name: {},
        photo_url: {}
    },
    stage: {
        id: {},
        name: {},
        workflow_id: {},
        color: {},
        status: {}
    },
    groups: {
        id: {},
        name: {}
    },
    status: {},
    has_children: {},
    start_date: {},
    created_time: {},
    end_date: {},
    duration: {},
    parent_id: {},
    thumbnail_url: {},
    children_count: {},
    comments_count: {},
    files_count: {},
    version: {},
    priority: {},
    approve_id: {},
    approved: {},
    match_work_time: {},
    task_type: { id: {}, name: {}, icon: {}, rate: {} },
    main_parent: {},
    elapsed_time_from_subtasks: {},
    project_matrix: {},
    main_parent_id: {},
    deadline: {},
    limit_time_for_subtasks: {}
};

let requestResourcesFields = {
    id: {},
    responsible: {
        id: {},
        name: {},
        photo_url: {},
        work_position: {}
    },
    start_date: {},
    end_date: {},
    duration: {},
    match_work_time: {},
    elapsed_time_from_subtasks: {},
    name: {}
};

export default {
    // async getTasks() {
    //   let res = await sendRequest(`/api/instances/${crm_task_id}?exclude_json_fields=children`, "get", "");
    //   return res;
    // },

    async getResources(filter, showLoader = true, with_groups = true, evaluated) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getTasks');
        let requestParams = {};
        requestParams.filter = JSON.parse(JSON.stringify(filter));
        requestParams.fields = requestResourcesFields;
        requestParams.with_groups = with_groups;
        requestParams.filter.conditions.push(
            {
                comparator: '=',
                field: 'elapsed_time_from_subtasks',
                type: 'condition',
                value: false
            },
            {
                comparator: '>',
                disabled: false,
                field: 'duration',
                id: '88a8c729-2ee2-411b-a3e0-0280e1693862',
                instance_value: null,
                type: 'condition',
                value: 0
            },
            {
                comparator: 'not empty',
                disabled: false,
                field: 'responsible',
                id: 'd978ef66-9754-48a7-81c1-0cb5247c9e3e',
                instance_value: null,
                type: 'condition',
                value: null
            },
            {
                comparator: '=',
                field: 'limit_time_for_subtasks',
                type: 'condition',
                value: false
            }
        );
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;
        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },

    async getResponsible(filter, evaluated) {
        let requestParams = {};
        requestParams.additional_field = 'responsible';
        requestParams.kanban_type = 'custom';
        requestParams.filter = filter;
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;

        return await sendRequest(`/api/instances/alias/kanban/head/task`, 'post', requestParams, null, true);
    },

    async getChildrenForResponsible(filter, responsibleId, evaluated, sort, limit, offset) {
        const requestParams = {};
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        requestParams.filter = filter;
        requestParams.additional_field = 'responsible';
        requestParams.kanban_type = 'custom';
        requestParams.fields = requestFields;
        requestParams.order = sort;
        requestParams.column_id = responsibleId;
        requestParams.limit = limit;
        requestParams.offset = offset;
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;
        return await sendRequest(`/api/kanban/instances/${crm_task_id}`, 'post', requestParams, null, false);
    },

    async getChildren(instanceIds, showLoader = true, sort, evaluated, filter, limit, offset) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getChildren');
        let requestParams = {};
        requestParams.fields = requestFields;
        requestParams.with_groups = true;
        requestParams.order = sort;
        requestParams.filter = JSON.parse(JSON.stringify(filter));
        requestParams.limit = limit;
        requestParams.offset = offset;
        requestParams.filter.conditions = [
            {
                comparator: 'in list',
                field: 'parent',
                type: 'condition',
                value: instanceIds
            }
        ];
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;

        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },

    async getAllChildren(instanceIds, showLoader = true, sort, evaluated, filter) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getChildren');
        let requestParams = {};
        requestParams.fields = requestFields;
        requestParams.with_groups = true;
        requestParams.order = sort;
        requestParams.filter = JSON.parse(JSON.stringify(filter));
        requestParams.filter.conditions = [
            {
                comparator: 'in hierarchy',
                field: 'parent',
                type: 'condition',
                value: instanceIds
            }
        ];
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;

        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },

    async getChildrenCount(instanceIds, showLoader = true, sort, evaluated, filter) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getChildren');
        let requestParams = {};
        requestParams.fields = requestFields;
        requestParams.with_groups = true;
        requestParams.order = sort;
        requestParams.filter = JSON.parse(JSON.stringify(filter));
        requestParams.filter.conditions = [
            {
                comparator: 'in list',
                field: 'parent',
                type: 'condition',
                value: instanceIds
            }
        ];
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;

        return await sendRequest(`/api/instances/count/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },

    async searchTasks(filter, showLoader = true, with_groups = true, limit, offset, sort, evaluated) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getTasks');
        let requestParams = {};
        requestParams.filter = JSON.parse(JSON.stringify(filter));
        requestParams.fields = requestFields;
        requestParams.with_groups = with_groups;
        requestParams.limit = limit;
        requestParams.offset = offset;
        requestParams.order = sort;
        requestParams.filter.conditions.push({
            comparator: 'empty',
            field: 'parent_id',
            type: 'condition'
        });
        if (typeof evaluated === 'boolean') requestParams.evaluated = evaluated;
        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },

    async moveTask(id, position, anchorId, parent) {
        if (!id || !position || !anchorId) return;

        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        await sendRequest(
            `/api/instance/${crm_task_id || parent}/${id}/sort`,
            'put',
            {
                position,
                anchor_instance_id: anchorId,
                parent_id: parent || null
            },
            null,
            false
        );
    },

    async getTasks(filter, showLoader = true) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        console.warn('getTasks');
        let requestParams = {};
        requestParams.filter = filter;
        requestParams.fields = requestFields;
        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },
    async getTasksByParameters(requestParams, showLoader = true) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestParams, null, showLoader);
    },
    async createTask(task) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        let res = await sendRequest(`/api/instance/${crm_task_id}/`, 'post', task);
        return res;
    },
    async updateTasks(taskId, data, onError = () => {}) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        let res = await sendRequest(`/api/instance/${crm_task_id}/${taskId}`, 'put', data, null, false, false, onError);
        return res;
    },
    async deleteTask(taskId) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        let res = await sendRequest(`/api/instance/${crm_task_id}/${taskId}`, 'delete', '');
        return res;
    },
    async copyTask(params) {
        let res = await sendRequest(`/api/instances/copy`, 'post', params);
        return res;
    },
    async getTasksById(id, showLoader = true) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        let res = await sendRequest(`/api/instance/${crm_task_id}/${id}`, 'get', '', null, showLoader);
        return res;
    },
    async getTaskProgects() {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        return await sendRequest(`/api/instances/${crm_project_group_id}`, 'get', '');
    },
    async getTaskTypeByProjectId(project_id) {
        return await sendRequest(`/api/instances/task_type/${project_id}`, 'get', '');
    },
    async getTaskProgectsVsParams(params) {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        return await sendRequest(`/api/instances/search/${crm_project_group_id}`, 'post', params);
    },
    async getTaskComment(taskId) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        return await commentsApi.getEntityComments(crm_task_id, taskId);
    },
    async getProjectComment(taskId) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_project_group_id');
        return await commentsApi.getEntityComments(crm_task_id, taskId);
    },
    async getSubtasks(taskId, hierarchy = false, payload = null) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        let requestData = {};
        requestData.fields = {
            approve_id: {},
            approved: {},
            children_count: {},
            created_by: {
                id: {},
                is_active: {},
                name: {},
                photo_url: {},
                work_position: {}
            },
            description: {},
            duration: {},
            end_date: {},
            match_work_time: {},
            has_children: {},
            id: {},
            name: {},
            parent_id: {},
            main_parent_id: {},
            priority: {},
            responsible: {
                id: {},
                is_active: {},
                name: {},
                photo_url: {},
                work_position: {}
            },
            sort_index: {},
            stage: {
                automation: {},
                color: {},
                default: {},
                deleted: {},
                description: {},
                id: {},
                name: {},
                next_stages: {},
                order: {},
                status: {},
                version: {},
                workflow_id: {}
            },
            start_date: {},
            status: {},
            task_type: {},
            tags: {},
            thumbnail_url: {},
            version: {}
        };
        requestData.filter = {
            condition: {
                field: 'parent_id',
                comparator: '=',
                value: taskId
            }
        };
        if (payload) {
            if (payload.offset != undefined && payload.limit) {
                requestData.offset = payload.offset;
                requestData.limit = payload.limit;
            }
            if (payload.sort) {
                requestData.order = [payload.sort];
            }
        }
        if (hierarchy) {
            requestData.filter = {
                condition: {
                    field: 'parent',
                    comparator: 'in hierarchy',
                    value: [taskId]
                }
            };
        }
        requestData.order = ['created_time asc'];
        return await sendRequest(`/api/instances/search/${crm_task_id}`, 'post', requestData);
    },
    async filterTask(params, showLoader = true) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        // let requestParams = {};
        // requestParams.filter = filter;
        // if(fields){
        //   requestParams.fields = fields;
        // }
        // else {
        //   requestParams.fields = requestFields;
        // }
        let url = `/api/instances/search/${crm_task_id}`;
        if (hasProperty(params, 'queryParams')) {
            url = url + `?${params.queryParams}`;
            delete params.queryParams;
        }
        // return await sendRequest(url, "post", requestParams, null, showLoader, crm_task_id);
        return await sendRequest(url, 'post', params, null, showLoader, crm_task_id);
    },
    async getFiltreadTasksLength(params, showLoader = false) {
        const userStore = useUserStore();
        const crm_task_id = userStore.getEnvironmentByName('crm_task_id');
        return await sendRequest(`api/instances/count/search/${crm_task_id}`, 'post', params, null, showLoader, crm_task_id);
    },
    async createProject(project) {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        return await sendRequest(`/api/instance/${crm_project_group_id}/`, 'post', project);
    },
    async getProjectById(id) {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        let res = await sendRequest(`/api/instance/${crm_project_group_id}/${id}`, 'get', '');
        return res;
    },
    async updateProject(id, data) {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        return await sendRequest(`/api/instance/${crm_project_group_id}/${id}`, 'put', data);
    },
    async deleteProject(id) {
        const userStore = useUserStore();
        const crm_project_group_id = userStore.getEnvironmentByName('crm_project_group_id');
        let res = await sendRequest(`/api/instance/${crm_project_group_id}/${id}`, 'delete', '');
        return res;
    },

    getCustomFields() {
        return devCustomFileld;
    },

    async getCountDoneTasks(entity_id, taskid, showLoader = true) {
        let payload = {
            filter: {
                conditions: [
                    {
                        comparator: 'in list',
                        field: 'status',
                        type: 'condition',
                        value: ['cancelled', 'completed']
                    },
                    {
                        comparator: '=',
                        field: 'parent_id',
                        type: 'condition',
                        value: taskid
                    }
                ],
                operator: 'and',
                type: 'conjunction'
            }
        };
        return await sendRequest(`/api/instances/count/search/${entity_id}`, 'post', payload, null, showLoader);
    }
};
