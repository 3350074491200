import { defineStore } from 'pinia';
import KanbanApi from '@/api/kanban';

export const useTasksKanbanStore = defineStore('tasksKanban', {
    state: () => ({
        allPlans: [],
    }),
    getters: {
        myPlanStagesList: state => state.allPlans?.[0]?.stages
            .reduce((availableStages, stage) => {
                if (!stage.default) {
                    availableStages.push(stage);
                }
                return availableStages;
            }, []) || [],
        myPlanId: state => state.allPlans?.[0]?.id,
        myPlanDefaultColumnId: state => state.allPlans?.[0]?.stages?.find(item => item.default === true)?.id,
    },
    actions: {
        async getPlans() {
            Object.assign(this.allPlans, await KanbanApi.getMyPlanColumns());
        },
        resetPlans() {
            this.allPlans.splice(0);
        },
    }
});
