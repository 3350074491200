<template>
    <div class="top-nav" v-if="isAuthorize">
        <div id="breadcrumb-target"></div>
        <div id="topbar-left-block"></div>
        <!-- <div class="order-1 md:order-3 mb-1 flex mt-[3px] gap-2">
      <platform-search></platform-search>
      <tracking-top-bar></tracking-top-bar>
      <topbar-notifications></topbar-notifications>
      <profile-menu
        :rounded="5"
        :borderColor="'var( --sp-primary, #2693ff)'"
      ></profile-menu>
    </div> -->
        <topbar-alert></topbar-alert>
    </div>
    <!-- /top-nav -->
</template>

<script>
// import TopbarNotifications from "@/components/platformNotifications/TopbarNotifications.vue";
// import TrackingTopBar from "@/components/tasks/taskCard/timeTracking/TrackingTopBar.vue";
// import PlatformSearch from "@/components/platformSearch/PlatformSearch.vue";
// import ProfileMenu from "@/components/account/ProfileMenu.vue";
import TopbarAlert from '@/components/platformAlert/TopbarAlert.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';

export default {
    name: 'TopBar',
    components: {
        // ProfileMenu,
        // TopbarNotifications,
        // TrackingTopBar,
        // PlatformSearch,
        TopbarAlert
    },
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapState(useUserStore, ['isAuthorize'])
    },
    methods: {}
};
</script>
<style lang="scss">
/*collapsedTab-btn */
.collapsedTab-btn {
    @apply p-2.5 rounded-md bg-gray-100 mr-1 mb-2 text-xs md:text-base
      before:mr-2 before:w-[18px] before:h-[18px] before:bg-cover
      after:static after:inline-flex after:ml-1 after:opacity-100 after:translate-y-0;
}
.topbar {
    background: #fff;
}
</style>
