<template>
    <div class="flex gap-4 h-full select-none">
        <div
            v-for="item in visibleElements"
            :key="item.id"
            class="flex gap-1 relative cursor-pointer group/entities-group"
            @click="
                () => {
                    if (!item.active) {
                        onEntitiesGroupClick(item.id);
                    }
                }
            ">
            <span class="group-hover/entities-group:text-[#519DF5] whitespace-nowrap" :class="[item?.active ? 'text-[#519DF5]' : 'text-[#252525]']">{{ item?.label }} </span
            ><span v-if="item?.count >= 0" class="text-[#979BB2]">{{ item?.count }}</span>
            <div v-if="item?.active" class="absolute w-full bottom-0 left-0 h-0 border-t-2 border-t-[#519DF5] pointer-events-none"></div>
        </div>
        <div v-if="maxVisibleElementsArrayLength < props.items.length" class="flex relative cursor-pointer group/entities-group" @click="onMoreClick">
            <span class="group-hover/entities-group:text-[#519DF5]">{{ $t('buttons.more') }}</span>
            <div v-if="contextMenuItems.some(el => el.active)" class="absolute w-full bottom-0 left-0 h-0 border-t-2 border-t-[#519DF5] pointer-events-none"></div>
        </div>
        <ContextMenu
            :items="contextMenuItems"
            v-if="contextVisible"
            :event="coordinateEvent"
            @changedValue="handleChange"
            @closeContextMenu="handleClose"
            @componentApi="
                (methods, id) => {
                    emit('componentApi', methods, id);
                }
            " />
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { getTextWidth, getBodyFontFamily } from '@/helpers/common';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    modalWidth: {
        type: Number,
        default: 619
    }
});

const emit = defineEmits(['change', 'componentApi']);
const onEntitiesGroupClick = id => {
    emit('change', id);
};

const maxVisibleElementsArrayLength = ref(0);
const elementFont = `400 14px ${getBodyFontFamily()}`;
const gapElements = 16;
const gapLabelNumber = 4;

const resizeBarrier = 2;

const widthMore = getTextWidth(t('buttons.more'), elementFont);

const getElementWidth = element => getTextWidth(element.label, elementFont) + (element.count >= 0 ? gapLabelNumber + getTextWidth(element.count, elementFont) : 0);

const getVisibleElementsArrayLength = () => {
    let tempMaxVisibleLength = 0;
    let tempWidth = 0;
    while (
        tempMaxVisibleLength < props.items?.length &&
        tempWidth + getElementWidth(props.items?.[tempMaxVisibleLength]) + (tempMaxVisibleLength > 0 ? gapElements : 0) + resizeBarrier <= props.modalWidth
    ) {
        tempWidth += getElementWidth(props.items?.[tempMaxVisibleLength]) + (tempMaxVisibleLength > 0 ? gapElements : 0);
        tempMaxVisibleLength += 1;
    }
    if (tempMaxVisibleLength < props.items?.length) {
        while (tempWidth + gapElements + widthMore + resizeBarrier > props.modalWidth && tempMaxVisibleLength > 0) {
            tempWidth -= getElementWidth(props.items?.[tempMaxVisibleLength - 1]) - gapElements - resizeBarrier;
            tempMaxVisibleLength -= 1;
        }
    }

    if (maxVisibleElementsArrayLength.value !== tempMaxVisibleLength) {
        maxVisibleElementsArrayLength.value = tempMaxVisibleLength;
    }
};

const visibleElements = computed(() => props.items.slice(0, maxVisibleElementsArrayLength.value));

const contextVisible = ref(false);
const coordinateEvent = ref(null);

const onMoreClick = async event => {
    coordinateEvent.value = event;
    contextVisible.value = !contextVisible.value;
};
const handleClose = () => {
    contextVisible.value = false;
    coordinateEvent.value = null;
};

const contextMenuItems = computed(() =>
    props.items.slice(maxVisibleElementsArrayLength.value).map(el => ({
        id: el.id,
        label: el.label + (el.count ? ' ' + el.count : ''),
        type: 'button',
        active: el.active
    }))
);

watch(
    [() => props.items, () => props.modalWidth],
    () => {
        getVisibleElementsArrayLength();
    },
    { immediate: true }
);

const handleChange = value => {
    onEntitiesGroupClick(value.id);
};
</script>
